import { createSelector, createEntityAdapter } from '@reduxjs/toolkit';

import { apiSlice } from './apiSlice';

const eventsAdapter = createEntityAdapter({
    selectId: (event) => event.id
});
const initialState = eventsAdapter.getInitialState();

export const eventsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getEvents: builder.query({
            query: ({ pageSize, years, page }) => ({
                url: '/tapahtumat/paginated',
                params: { pageSize, years, page },
            }),
            providesTags: ['Events'],
            keepUnusedDataFor: 0 // Don't cache any of the event data. If we have 10k events, it could significantly slow the app down.
        }),
        getEvent: builder.query({
            query: ({ id }) => ({
                url: `/tapahtumat/${id}`,
            }),
            keepUnusedDataFor: 0
        }),
        getAllLaskutettavat: builder.query({
            query: () => ({
                url: '/tapahtumat/laskutettavat'
            }),
            keepUnusedDataFor: 0
        }),
        getAllLaskutetetut: builder.query({
            query: () => ({
                url: '/tapahtumat/laskutetut'
            }),
            keepUnusedDataFor: 0
        }),
        getAllUserEvents: builder.query({
            query: () => ({
                url: '/tapahtumat/user/all'
            }),
            keepUnusedDataFor: 0
        }),
        addNewEvent: builder.mutation({
            query: newEvent => {
                return({
                    url: '/tapahtumat',
                    method: 'POST',
                    body: newEvent
                });}
        }),
        editEvent: builder.mutation({
            query: editedEvent => ({
                url: '/tapahtumat',
                method: 'PUT',
                body: editedEvent
            })
        }),
        deleteEvent: builder.mutation({
            query: ({ id }) => ({
                url: '/tapahtumat',
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: ['Events']
        })
    })
});

export const {
    useGetEventsQuery,
    useGetEventQuery,
    useGetAllUserEventsQuery,
    useAddNewEventMutation,
    useEditEventMutation,
    useDeleteEventMutation,
    useGetAllLaskutettavatQuery,
    useGetAllLaskutetetutQuery
} = eventsApiSlice;

// Create Selectors for Events.

// Return the result of the query.
export const selectEventsResult = eventsApiSlice.endpoints.getEvents.select('eventsList');

// Create a memoized selector.
const selectEventsData = createSelector(
    selectEventsResult,
    eventsResult => eventsResult.data // Normalized state object with ids.
);

// getSelector creates these automatically, we give them aliases according to the slice's purpose.

export const {
    selectAll: selectAllEvents,
    selectById: selectEventById,
    selectIds: selectEventIds
} = eventsAdapter.getSelectors(state => selectEventsData(state) ?? initialState);

export const selectEventsByUser = createSelector(
    selectAllEvents,
    (state, userId) => userId,
    (events, userId) => events.filter((event => event.user === userId ))
);

export const selectPublishedEventsByUser = createSelector(
    selectAllEvents,
    (state, userId, ) => userId,
    (events, userId) => events.filter((event =>
        event.user === userId && event.status.toLowerCase() === 'julkaistu'
    ))
);

export const selectNonPublishedEventsByUser = createSelector(
    selectAllEvents,
    (state, userId, ) => userId,
    (events, userId) => events.filter((event =>
        event.user === userId && event.status.toLowerCase() === 'luonnos'
    ))
);