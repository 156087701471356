import { useState } from 'react';
import { Grid, Typography, Box, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormTextField from '../../components/FormFields/FormTextField';
import { useAddNewKustannuspaikkaMutation  } from '../../api/kustannuspaikatApiSlice';
import UpdateButton from './adminUtils/updateButton';
import SearchBar from './adminUtils/SearchBar';
import PaginationInfo from './PaginationInfo';

function KustannuspaikkaList({ kustannuspaikat, handleDeleteKustannuspaikka, handleUpdateKustannuspaikka, fetchData, onSuccess, onError }) {
    const [selectedKustannuspaikka, setSelectedKustannuspaikka] = useState(null);
    const [newKustannuspaikkaData, setNewKustannuspaikkaData] = useState({ kustannuspaikka: '' });
    const [addKustannuspaikkaData, setAddKustannuspaikkaData] = useState({ kustannuspaikka: '' });
    const [showAddForm, setShowAddForm] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [addNewKustannuspaikka, { isLoading }] = useAddNewKustannuspaikkaMutation();



    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
        setCurrentPage(1); // Reset to the first page when searching
    };

    // Apply the search filter
    const filteredKustannuspaikka = Array.isArray(kustannuspaikat)
        ? kustannuspaikat.filter((Kustannuspaikka) =>
            Kustannuspaikka.kustannuspaikka.toLowerCase().includes(searchTerm.toLowerCase())
        )
        : [];

    const totalPages = Math.ceil(filteredKustannuspaikka.length / itemsPerPage);
    // Get the slice of Kustannuspaikka for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedKustannuspaikka = filteredKustannuspaikka.slice(startIndex, endIndex);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleUserClick = (Kustannuspaikka) => {
        if(selectedKustannuspaikka?.id === Kustannuspaikka.id) {
            setSelectedKustannuspaikka(null);

            setNewKustannuspaikkaData({ kustannuspaikka: '' });

            return;
        }
        setSelectedKustannuspaikka(Kustannuspaikka);
        setNewKustannuspaikkaData(Kustannuspaikka);
    };

    const handleAddClick = () => {
        setShowAddForm(!showAddForm);
    };

    const handleLisääKustannuspaikka = async(Kustannuspaikka) => {
        try {
            const result = await addNewKustannuspaikka(Kustannuspaikka);
            if(result.error) onError('Kustannuspaikan luominen epäonnistui');
            if(!result.error) onSuccess('Kustannuspaikka added successfully.', 'Kustannuspaikka', result.data.id);
            setAddKustannuspaikkaData({ kustannuspaikka: '' });
            setShowAddForm(false);
            fetchData();
        } catch (error) {
            console.error(error); // handle error
        }};


    return (
        <Grid container spacing={2}>
            <Box mt={3} ml={2}> {/* Add spacing to separate SearchBar */}
                <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
            </Box>
            {displayedKustannuspaikka.map((Kustannuspaikka) => (
                <Grid key={Kustannuspaikka.id} item xs={12} >
                    <Box key={Kustannuspaikka.id} onClick={() => handleUserClick(Kustannuspaikka)} sx={{
                        backgroundColor: 'taphaWhite.main',
                        borderRadius: 5,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginTop: 0.6,
                        gap: 5,
                        '&:hover': {
                            border: '1px solid',
                            borderLeft: '30px solid',
                            transition: '0.4s',
                            borderColor: 'taphaGreen.main',
                            cursor: 'pointer'
                        }
                    }}>
                        <Typography variant="h6">
                            {Kustannuspaikka.kustannuspaikka}
                        </Typography>
                        <IconButton onClick={(event) => {
                            handleDeleteKustannuspaikka(event, Kustannuspaikka);
                            if (selectedKustannuspaikka && selectedKustannuspaikka.id === Kustannuspaikka.id) {
                                setSelectedKustannuspaikka(null);
                                setNewKustannuspaikkaData({ kustannuspaikka: '' });
                            }
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                    {selectedKustannuspaikka && selectedKustannuspaikka.id === Kustannuspaikka.id && (
                        <Grid container>

                            <Grid item xs={8} sx={{ pt: 2 }}>
                                <FormTextField

                                    fieldName='kustannuspaikka'
                                    label='kustannuspaikka'
                                    multiline={true}
                                    fullWidth={true}

                                    value={newKustannuspaikkaData.kustannuspaikka}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setNewKustannuspaikkaData((prevState) => ({ ...prevState, [name]: value }));
                                    }}

                                />
                            </Grid>


                            <UpdateButton handleUpdate={handleUpdateKustannuspaikka} data={newKustannuspaikkaData}/>
                        </Grid>
                    )}
                </Grid>
            ))}
            {showAddForm && (
                <Box sx={{
                    flexGrow: 1, // Add flexGrow to make the box grow
                }}>
                    <Typography variant="h6" sx={{ pt: 2 }}>Kustannuspaikan lisääminen</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <FormTextField
                                key="addKustannuspaikkaKustannuspaikka"
                                fieldName='kustannuspaikka'
                                label='kustannuspaikka'
                                multiline={true}
                                fullWidth={true}

                                value={addKustannuspaikkaData.kustannuspaikka}
                                onChange={(event) => setAddKustannuspaikkaData({ ...addKustannuspaikkaData, kustannuspaikka: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                        </Grid>

                        <Box display="flex" justifyContent="flex-end" width="100%" pt={1}>

                            <Button
                                onClick={() => handleLisääKustannuspaikka(addKustannuspaikkaData)}
                                size="small"
                                variant="contained"
                                startIcon={<AddIcon />}
                                sx={{ backgroundColor: 'taphaGreen.main' }}
                            >
          Lisää kustannuspaikka
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            )}
            {showAddForm ? (
                <Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={handleAddClick}
                        size='small'
                        variant='contained'
                        startIcon={<CloseIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Sulje
                    </Button>
                </Box>
            ) :
                (<Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={handleAddClick}
                        size='small'
                        variant='contained'
                        startIcon={<AddIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Lisää
                    </Button>
                </Box>)}
            {/* Pagination arrows */}
            <Box mt={2} ml={2} display="flex" flexDirection="column" alignItems="center">
                <PaginationInfo
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    filteredArray={filteredKustannuspaikka}
                    array={kustannuspaikat}
                />
                <Box display="flex" justifyContent="center" width="100%">
                    <Box width="740px" display="flex" justifyContent="center"> {/* Centered box with a specified width */}
                        <IconButton
                            size="small"
                            onClick={handlePreviousPage}
                            disabled={currentPage <= 1}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={handleNextPage}
                            disabled={currentPage >= totalPages}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
}

export default KustannuspaikkaList;