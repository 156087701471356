import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { memo } from 'react';
import { Alert, List, Button } from '@mui/material';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
dayjs.extend(minMax);

import ScheduleDate from './ScheduleDate';
import useConfirmDialog from '../../../../../hooks/useConfirmationDialog';

// TODO: Error handleri siihen tilanteeseen, jos lopetus > aloitus

// Helper functions at the bottom of the file.

const EventSchedule = ({ fieldName, kansanopisto, setHours }) => {

    const {
        control,
        setValue
    } = useFormContext();

    const { fields } = useFieldArray({
        name: fieldName
    });

    const aloitus = useWatch({
        name: 'aloitus',
        defaultValue: { pvm: '' }
    });
    const lopetus = useWatch({
        name: 'lopetus',
        defaultValue: { pvm: '' }
    });
    const currentSchedule = useWatch({
        name: fieldName,
        defaultValue: []
    });

    const [ConfirmationDialog, confirmDateChange] = useConfirmDialog({
        title: 'Valituille päivämäärille on jo lisätty ohjelmaa',
        message: 'Haluatko varmasti muuttaa tapahtuman aikaa? Näille päiville on jo lisätty tilaisuuksia.'
    });

    const confirmChange = async (datesToRemove) => {
        const containsInput = datesToRemove.length && datesToRemove.some(date => date.tilaisuudet.length);
        if (containsInput) {
            const confirmed = await confirmDateChange();
            if (confirmed) {
                // Update the total hours when dates are removed
                const removedHours = datesToRemove.reduce((totalHours, date) => {
                    return totalHours + date.tilaisuudet.reduce((dateHours, tilaisuus) => {
                        return dateHours + (tilaisuus.kansanopistotunnit[0]?.kansanopistotunnit || 0);
                    }, 0);
                }, 0);
                setHours((prevTotal) => prevTotal - removedHours);
            }
            return confirmed;
        }
        return true;
    };

    const sortTilaisuudetByStartTime = () => {
        const sortedSchedule = currentSchedule.map(day => ({
            ...day,
            tilaisuudet: day.tilaisuudet.sort((a, b) => new Date(a.aloitus) - new Date(b.aloitus))
        }));
        setValue(fieldName, sortedSchedule);
    };



    const datesAreSelected = !!(aloitus.pvm && lopetus.pvm); // The selected start and end dates for the event exist.
    const hasSchedule = !!currentSchedule?.length; // Does the event currently have schedule.

    // A function used to confirm changes to the schedule, that would result in
    // loss of the schedule's dates. User is only asked for confirmation if the
    // schedule already contains any input. If not, the confirm will always
    // result in true.


    useEffect(() => {
        // If no dates are selected, then schedule can't be added.
        // If dates are selected, produce an inclusive date array between the
        // selected dates.

        if (!datesAreSelected)
            return;

        const selectedDates = getDatesBetween(aloitus.pvm, lopetus.pvm, 'DD.MM.YYYY');

        // If we don't have any schedule data in the form state, and the starting and
        // ending dates are selected, create the initial state for the schedule.
        // Add the empty tilaisuudet-array, as this will be registered as a subarray
        // to the form state. This will contain all of the schedule for the
        // given date.

        if (!hasSchedule) {
            const scheduleDates = selectedDates?.map(date => ({
                pvm: date,
                tilaisuudet: []
            }));
            setValue(fieldName, scheduleDates);
            return;
        }
        // Check if the range from the new selected dates contain every date
        // previously in the schedule.
        // If they do, update the schedule accordingly as no dates are lost.

        const newDatesContainCurrentDates = currentSchedule.every(scheduleDate =>
            selectedDates.some(newDate => newDate === scheduleDate.pvm)
        );
        if (newDatesContainCurrentDates) {

            const newSchedule = updateSchedule(selectedDates, currentSchedule);
            setValue(fieldName, newSchedule);
            return;
        }

        // If we didn't exit before, it means that the range from the new
        // selected dates doesn't contain all of the dates previously in the schedule.
        // Check which dates will be removed. If they already have schedule,
        // ask the user to confirm the schedule update.

        const confirmSchedule = async () => {
            const datesToRemove = currentSchedule.filter(scheduleDate =>
                !selectedDates.includes(scheduleDate.pvm)
            );

            // If the result of the confirm is true, the schedule will be updated.
            // Else, if result of the confirm is false, roll the selected dates
            // back based on the dates in the currentSchedule. The previous
            // dates can always be derived from the schedule.

            const scheduleUpdateConfirmed = await confirmChange(datesToRemove);

            if (scheduleUpdateConfirmed) {
                const newSchedule = currentSchedule.filter(scheduleDate =>
                    !datesToRemove.some(date => date.pvm === scheduleDate.pvm)
                );
                setValue(fieldName, newSchedule);
            } else {
                const dates = currentSchedule.map(date => dayjs(date.pvm, 'DD.MM.YYYY'));
                setValue('aloitus.pvm', dayjs.min(dates));
                setValue('lopetus.pvm', dayjs.max(dates));
            }
        };

        // Only confirm schedule date update, if the dates in the form are valid.
        // This way if the user clears the dateSelection nothing will be done
        // before a valid date is set again.

        if (dayjs(aloitus.pvm).isValid() && dayjs(lopetus.pvm).isValid())
            confirmSchedule();

    }, [aloitus.pvm, lopetus.pvm]);


    // The dates should be selected before the user tries to add a schedule
    // for the event. If the dates are not selected, display an appropriate
    // message telling the user to do so.

    if (!datesAreSelected && !currentSchedule?.length)
        return (
            <Alert severity='info' sx={{ borderRadius: 3 }}>
                Valitse tapahtumalle päivämäärä muokataksesi sen ohjelmaa
            </Alert>
        );



    return (
        <>
            <List sx={{ borderRadius: 3 }} id='event-schedule'>
                {currentSchedule?.map((field, index) =>
                    <ScheduleDate
                        setHours={setHours}
                        kansanopisto={kansanopisto}
                        key={field.id}
                        date={field.pvm}
                        nestedIndex={index}
                        control={control}
                        fieldName={fieldName}
                        subfieldName='tilaisuudet'
                    />
                )}
                {ConfirmationDialog()}
            </List>
            <Button variant="contained" onClick={sortTilaisuudetByStartTime} sx={{ backgroundColor: 'taphaBlue.main', whiteSpace: 'nowrap', px: 3, mt:1 }}>Järjestä tilaisuudet</Button>
        </>
    );
};

EventSchedule.propTypes = {
    fieldName: PropTypes.string
};

export default memo(EventSchedule);

// A function for producing an inclusive list of dates between two
// given dayjs-objects. The function doesn't take time of day into account.

function getDatesBetween (startDate, endDate, dateFormat) {

    if (startDate && endDate) {

        // The start and end dates will have hours attached to them, as the
        // timepicker and datepicker will operate on the same object when reading
        // data in to the form. Fix this by setting both of their hours to 0,
        // and clone them before, incase we're dealing with shallow copies. If we
        // don't do this, extra dates might be added to the schedule as dayjs.diff
        // doesn't return an int.

        const start = startDate.clone().set('hour', 0);
        const end = endDate.clone().set('hour', 0);

        // Get the number of days between the given dates and store it in dateDiff.
        // Prepare the list by appending the given startDate.
        const dateDiff = end.diff(start, 'day', true);
        const dateList = [startDate];

        // Loop through the days, always adding 1 day to the previous
        // date in the list.
        for (let i = 0; i < dateDiff; i++)
            dateList.push(dateList[i].add(1, 'day'));

        return dateList.map(date => date.format(dateFormat));
    }
}
// A function used to update the current schedule with the given date range.

function updateSchedule (selectedDates, currentSchedule) {
    // Check which dates should be added to the schedule and map them to objects

    const newDates = selectedDates
        .filter(newDate => !currentSchedule.some(scheduleDate => scheduleDate.pvm === newDate))
        .map(newDate => ({
            pvm: newDate,
            tilaisuudet: []
        }));
    // Concat the new dates in to the schedule, and sort them in ascending order.

    return newDates.concat(currentSchedule)
        .sort((a, b) => {
            const dateA = dayjs(a.pvm, 'DD.MM.YYYY');
            const dateB = dayjs(b.pvm, 'DD.MM.YYYY');
            return dateA.isBefore(dateB) ? -1 : 1;
        });
}