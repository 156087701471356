import {
    Box,
    Collapse,
    Modal,
    IconButton,
    ListItem
} from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';


import SchedulePreview from './SchedulePreview';
import ScheduleCard from './ScheduleCard';
import ScheduleInputs from './ScheduleInputs';

const ScheduleListItem = ({ fieldPath, handleRemove, kansanopisto, setHours }) => {

    const { getFieldState, getValues } = useFormContext();

    const [collapseOpen, setCollapseOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);


    const toggleModal = (e) => {
        e.stopPropagation();
        setModalOpen(prevModal => !prevModal);
    };

    const toggleCollapse = (e) => {
        e.stopPropagation();
        setCollapseOpen(prevCollapse => !prevCollapse);
    };

    const fieldState = getFieldState(fieldPath);
    const hasErrors = !!fieldState.error;

    const listItemStyle = {
        border: '1px solid',
        borderRadius: 2,
        borderColor: !hasErrors ? 'taphaWhite.main' : 'taphaErrorRed.dark',
        backgroundColor: !hasErrors ? 'taphaWhite.main' : 'taphaErrorRed.main',
        mt: 1,
        gap: 1,
        display: 'flex',
        flexDirection: 'column',
        '&:hover': !collapseOpen && !hasErrors && {
            border: '1px solid',
            borderColor: 'taphaGreen.main',
            cursor: 'pointer'
        }
    };

    const modalStyle = {
        borderRadius: 5,
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '50vw',
        minHeight: '50vh',
        bgcolor: 'whitesmoke',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    return (
        <>
            <ListItem
                onClick={toggleCollapse}
                disableGutters
                disablePadding
                sx={listItemStyle}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    width: '100%'
                }}>
                    <IconButton disableRipple sx={{ ml: 1 }}>
                        {collapseOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    <SchedulePreview name={fieldPath} hasErrors={hasErrors} />
                    <IconButton disableRipple onClick={toggleModal}>
                        <EditIcon sx={{ color: 'taphaBlue.dark' }}/>
                    </IconButton>
                    <IconButton disableRipple onClick={handleRemove}>
                        <ClearIcon sx={{ color: 'taphaBlue.dark' }} />
                    </IconButton>
                </Box>
                <Collapse
                    sx={{ width: '100%' }}
                    unmountOnExit
                    in={collapseOpen}
                    timeout='auto'>
                    <ScheduleCard fieldName={fieldPath} hasErrors={hasErrors} />
                </Collapse>
            </ListItem>
            <Modal open={modalOpen} onClose={toggleModal} keepMounted>
                <Box sx={modalStyle}>
                    <ScheduleInputs kansanopisto={kansanopisto} fieldName={fieldPath} setHours={setHours} onClose={() => setModalOpen(false)} />
                </Box>
            </Modal>
        </>
    );
};

ScheduleListItem.propTypes = {
    fieldPath: PropTypes.string,
    handleRemove: PropTypes.func,
    handleOpen: PropTypes.func,
    isOpen: PropTypes.bool
};

export default ScheduleListItem;