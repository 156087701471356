/*
    API slice used to fetch the event types (luonteet) from the backend.
    It's not inside any feature's directory, as it will likely be used in at
    least two different features (AdminPanel, Events).
*/

import { createSelector } from '@reduxjs/toolkit';
import { createEntityAdapter } from '@reduxjs/toolkit';

import { apiSlice } from './apiSlice';

const kustannuspaikatAdapter = createEntityAdapter({
    selectId: (eventType) => eventType.id
});
const initialState = kustannuspaikatAdapter.getInitialState();

export const kustannuspaikatApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getKustannuspaikat: builder.query({
            query: () => ({
                url: '/kustannuspaikat',
                validateStatus: (response, result) =>
                    response.status === 200 && !result.isError
            }),
            transformResponse: responseData =>
                kustannuspaikatAdapter.setAll(initialState, responseData),
            providesTags: ['Kustannuspaikat']
        }),
        addNewKustannuspaikka: builder.mutation({
            query: newKustannuspaikka => ({
                url: '/kustannuspaikat',
                method: 'POST',
                body: { ...newKustannuspaikka }
            }),
            invalidatesTags: ['Kustannuspaikat']
        }),
        deleteKustannuspaikka: builder.mutation({
            query: ({ id }) => ({
                url: '/kustannuspaikat',
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: ['Kustannuspaikat']
        })
    })
});

export const {
    useGetKustannuspaikatQuery,
    useAddNewKustannuspaikkaMutation,
    useDeleteKustannuspaikkaMutation
} = kustannuspaikatApiSlice;

export const selectKustannuspaikatResult = kustannuspaikatApiSlice.endpoints.getKustannuspaikat.select('KustannuspaikatList');

const selectKustannuspaikatData = createSelector(
    selectKustannuspaikatResult,
    kustannuspaikatResult => kustannuspaikatResult.data
);

export const {
    selectIds: selectKustannuspaikkaIds,
    selectEntities: selectKustannuspaikkaEntities
} = kustannuspaikatAdapter.getSelectors(state => selectKustannuspaikatData(state) ?? initialState);

// Selector for only the event types themselves without ids.
export const selectAllKustannuspaikat = createSelector(
    selectKustannuspaikkaIds,
    selectKustannuspaikkaEntities,
    (ids, kustannuspaikat) => ids.map(id => kustannuspaikat[id].kustannuspaikka)
);