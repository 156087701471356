import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    CircularProgress,
    Container,
    Grid,
    Paper,
    Typography,
} from '@mui/material';

import { selectCurrentUser } from '../../../authentication/authSlice';
import { useAddNewReportMutation, useUpdateReportMutation } from '../../../../api/reportsApiSlice';
import {
    validationSchema,
    defaultValues,
    convertDataToFormSchema
} from './reportFormSchemas';

import reportingOptions from  './reportingOptions.json';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import ListFieldsWithTitles from './ListFieldsWithTitles/ListFieldsWithTitles';
import RadioGroupArray from './RadioGroupArray';
import BillsArray from './BillsArray/BillsArray';
import MessageSnackbar from '../../../../components/MessageSnackbar';
import { useAddNewLokiMutation } from '../../../../api/lokiSlice';
import FormTextField from '../../../../components/FormFields/FormTextField';
import RichTextEditor from '../../../../components/RichTextEditor.js';

const ReportingForm = ({ report, event, documentTitle, isEditing }) => {


    useDocumentTitle(documentTitle);

    const [initialFormValues, setInitialFormValues] = useState(defaultValues);
    const [{
        submitting,
        submitted,
        error,
        message
    }, setFormStatus] = useState({
        submitting: false,
        submitted: false,
        error: false,
        message: ''
    });

    const user = useSelector(selectCurrentUser);
    const name = user.name;
    const [ sendLog ] = useAddNewLokiMutation();

    const [mutationToUse] = isEditing
        ? useUpdateReportMutation()
        : useAddNewReportMutation();

    const navigate = useNavigate();

    useEffect(() => {
        // If we have a report, update its fields with the latest data from the event.
        if (report && event) {
            const updatedReport = { ...report };

            // Merge 'yhteistyotahot' arrays from both report and event
            const mergedYhteistyotahot = [];
            const existingYhteistyotahotNames = new Set();

            if (updatedReport.yhteistyotahot) {
                updatedReport.yhteistyotahot.forEach(yhteistyotaho => {
                    mergedYhteistyotahot.push(yhteistyotaho); // Add existing yhteistyotaho from report
                    existingYhteistyotahotNames.add(yhteistyotaho.nimi); // Add existing names to set
                });
            }

            event.yhteistyotahot.forEach(name => {
                if (!existingYhteistyotahotNames.has(name)) {
                    mergedYhteistyotahot.push({ nimi: name, suhtautuminen: 0 }); // Create new yhteistyotaho object with default 'suhtautuminen'
                    existingYhteistyotahotNames.add(name); // Add name to set to avoid duplicates
                }
            });

            updatedReport.yhteistyotahot = mergedYhteistyotahot;

            // Iterate through the fields in the event.
            Object.keys(event).forEach(field => {
                // Skip 'yhteistyotahot' field as we've handled it separately.
                if (field === 'yhteistyotahot') return;

                // If the field exists in the report, update it with the latest data from the event.
                if (updatedReport[field] !== undefined) {
                    updatedReport[field] = event[field];
                }
            });


            setInitialFormValues(updatedReport);
        }
        // If there's no report or the event has been edited, prepare the form default values using the event's data.
        else if (event && user && !report) {
            const initialData = convertDataToFormSchema(event, user);
            setInitialFormValues(prev => ({
                ...prev,
                ...initialData
            }));
        }
    }, [event]);

    // EventForm makes use of the entire formState, but this form only needs to
    // know the errors in the context. Destructure only errors from the FormState.

    const { formState: { errors }, ...methods } = useForm({
        resolver:  yupResolver(validationSchema),
        mode: 'onBlur', // Validate on input state change.
        reValidateMode: 'onChange', // After a failed submit validate onChange.
        defaultValues: defaultValues,
        values: initialFormValues,
        resetOptions: { keepDefaultValues: true }
    });

    const onSubmit = async (data) => {


        data.yhteistyotahot = data.yhteistyotahot.map(yt => ({
            ...yt,
            suhtautuminen: parseInt(yt.suhtautuminen) // Radio parses the int into a string, so reverse it.
        }));

        try {
            const res = await mutationToUse(data).unwrap();


            setFormStatus(prev => ({
                ...prev,
                submitting: false,
                submitted: true,
                message: isEditing ? 'Muutokset tallennettu' : 'Raportti luotu'
            }));
            /*await sendLog({
                name: name,
                action: isEditing ? 'muokkasi raporttia' : 'raportoi',
                document: {
                    type: 'raportit',
                    documentId: res.id
                }

            });*/

            setTimeout(() => {
                navigate(`/tapahtumat/${res.tapahtumaId}`);
            }, 1000);

        } catch (err) {
            console.log(err);

            setFormStatus(prev => ({
                ...prev,
                submitting: false,
                error: true,
                message: (
                    `Virhe: raportin ${ isEditing ? 'muokkaaminen' : 'tallentaminen'} epäonnistui.
                     Jos virhe toistuu, ota yhteyttä IT-tukeen.${err?.status && ` [status: ${err.status}]`}`
                )
            }));
        }
    };







    const typographyStyles = {
        variant: 'h5',
        align: 'center',
        fontFamily: '"Bree Serif", serif',
        sx: { textDecoration: 'underline', pb: 1 }
    };

    return (
        <Container
            maxWidth='md'
            display='flex'
            sx={{ justifyContent: 'center', mt: 2 }}
        >
            <FormProvider
                { ...methods }
                errors={errors} // Pass only the errors in to the context to force optimal rerenders.
                defaultValues={defaultValues}
            >
                <Paper
                    id='reporting-form'
                    component='form'
                    onSubmit={methods.handleSubmit(onSubmit)}
                    onKeyDown={(e) => { // Prevent default enter submit.
                        if (e.key === 'Enter')
                            e.preventDefault();
                    }}
                    elevation={3}
                    sx={{
                        borderRadius: 8,
                        my: 3,
                        px: 8,
                        py: 4
                    }}
                >
                    <Grid rowSpacing={5} container width='100%' >

                        <Grid item xs={12} sx={{ pb: 3 }} >
                            <Typography variant='h4' align='center'>
                                Raportti: {event.nimi}
                            </Typography>
                        </Grid>


                        <Grid item xs={12}>
                            <Typography { ...typographyStyles }>
                                Tapahtuman väkimäärä
                            </Typography>
                        </Grid>
                        <Grid item xs={12}  display='flex' justifyContent='center'>
                            <FormTextField fieldName='vakimaara' label='Väkimäärä'/>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography { ...typographyStyles }>
                                Yhteistyötahot & suhtautuminen
                            </Typography>
                            <Grid item xs={12}>
                                <RadioGroupArray
                                    fieldName='yhteistyotahot'
                                    subfieldName='suhtautuminen'
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography { ...typographyStyles }>
                                Raportin muistiinpanot
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <RichTextEditor
                                fieldName='muistiinpano'
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography { ...typographyStyles }>
                                Tapahtuman laskut
                            </Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <BillsArray fieldName='laskut' />
                        </Grid>

                        {/* Submit-button */}
                        <Grid item container xs={12} pt={3} justifyContent='center'>
                            <Button
                                type='submit'
                                form='reporting-form'
                                variant='contained'
                                sx={{
                                    width: '50%',
                                    backgroundColor: 'taphaBlue.dark',
                                }}
                            >
                                { !submitting
                                    ? 'Tallenna raportti'
                                    : (
                                        <CircularProgress
                                            thickness={5}
                                            size={25}
                                            sx={{ color: 'white' }}
                                            value={90}
                                        />
                                    )
                                }
                            </Button>
                        </Grid>
                        <MessageSnackbar
                            showSnackbar={ submitted || error }
                            severity={ submitted ? 'success' : 'error' }
                            msg={ message }
                        />
                    </Grid>
                </Paper>
            </FormProvider>
        </Container>
    );
};

ReportingForm.propTypes = {
    event: PropTypes.object,
    documentTitle: PropTypes.string.isRequired,
    report: PropTypes.object
};

export default ReportingForm;