import { MenuItem, TextField } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import React from 'react';

const FormTextField = ({ fieldName, children, ...otherProps }) => {
    const { control, getFieldState } = useFormContext();
    const error = getFieldState(fieldName)?.error;
    const hasChildren = React.Children.count(children) > 0;

    // Determine if the TextField should be multiline based on the 'multiline' prop
    const isMultiline = otherProps.multiline || false;
    const rows = isMultiline ? otherProps.rows || 3 : undefined;

    const handleKeyDown = (event) => {
        // Check if Enter key is pressed and TextField is in multiline mode
        if (event.key === 'Enter' && isMultiline) {
            event.preventDefault(); // Prevents the default behavior (new line)
            const { selectionStart, selectionEnd } = event.target;
            const value = event.target.value;

            // Insert a newline character at the cursor position
            const newValue =
                value.substring(0, selectionStart) +
                '\n' +
                value.substring(selectionEnd);

            // Update the field value and cursor position
            event.target.value = newValue;
            event.target.setSelectionRange(selectionStart + 1, selectionStart + 1);
        }
    };

    return (
        <Controller
            name={fieldName}
            control={control}
            render={({ field }) => (
                <TextField
                    {...field}
                    {...otherProps}
                    autoComplete='off'
                    error={!!error}
                    helperText={error ? error.message : ' '}
                    FormHelperTextProps={{ variant: 'standard' }}
                    multiline={isMultiline}
                    rows={rows}
                    onKeyDown={handleKeyDown} // Handle Enter key press
                >
                    {children}
                    {hasChildren && (
                        <MenuItem value=''>
                            -
                        </MenuItem>
                    )}
                </TextField>
            )}
        />
    );
};

FormTextField.propTypes = {
    fieldName: PropTypes.string,
    otherProps: PropTypes.object,
};

export default FormTextField;