import React from 'react';
import ReactQuill, { Quill } from 'react-quill'; // Import Quill from react-quill
import 'react-quill/dist/quill.snow.css';
import { useFormContext, useWatch } from 'react-hook-form';
import { Controller } from 'react-hook-form';

//Fixes spacing but renders divs instead of paragraphs
/*const Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true); // Register the customized block blot with Quill*/

const RichTextEditor = ({ fieldName }) => {
    const { control } = useFormContext();
    const defaultValue = useWatch({ control, name: fieldName }) || '';

    const generateToolbar = () => {
        const toolbar = [
            ['bold', 'italic', 'underline'],
            ['link'],
        ];
        return toolbar;
    };

    const modules = {
        toolbar: generateToolbar(),
    };

    return (
        <Controller
            name={fieldName}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => (
                <ReactQuill
                    {...field}
                    modules={modules}
                />
            )}
        />
    );
};

export default RichTextEditor;