import { useState, useEffect } from 'react';
import { Grid, Typography, Box, IconButton, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import UpdateIcon from '@mui/icons-material/Update';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormTextField from '../../components/FormFields/FormTextField';
import { selectCurrentAccessToken, selectCurrentUser } from '../../features/authentication/authSlice';
import fetchData from './adminUtils/fetchData';
import handleDelete from './adminUtils/handleDelete';
import handleUpdate from './adminUtils/handleUpdate';
import { roles } from '../../config/roles';
import UpdateButton from './adminUtils/updateButton';
import SearchBar from './adminUtils/SearchBar';
import PaginationInfo from './PaginationInfo';
import { useAddNewLokiMutation } from '../../api/lokiSlice';

function UserList({ fetchPeopleData, onSuccess, onError, setSuccessMessage, setErrorMessage }) {
    const [selectedUser, setSelectedUser] = useState(null);
    const [users, setUsers] = useState([]);
    const [newUserData, setNewUserData] = useState({});
    const [showAddForm, setShowAddForm] = useState(false);
    const accessToken = useSelector(selectCurrentAccessToken);
    const [addUserData, setAddUserData] = useState({ username: '', name: '', titteli: '', role: '', password: '' });
    const [createPersonToo, setCreatePersonToo] = useState(false);
    const [selectedRole, setSelectedRole] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [ sendLog ] = useAddNewLokiMutation();

    const userRoles = Object.values(roles);
    const { name } = useSelector(selectCurrentUser);




    // Get the slice of luonteet for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
        setCurrentPage(1); // Reset to the first page when searching
    };

    // Apply the search filter
    const filteredUsers = users?.filter((User) =>
        User.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (selectedRole === '' || User.role === selectedRole)
    );


    const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
    const displayedUsers = filteredUsers?.slice(startIndex, endIndex);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };


    const handleUserClick = (user) => {

        if (selectedUser?.id === user.id) {
            setSelectedUser(null);
            setNewUserData({});
            return;
        }

        setSelectedUser(user);
        setNewUserData(user);
    };

    const handleAddClick = () => {
        setShowAddForm(!showAddForm);
    };



    const fetchUsers = () => {
        fetchData('users', accessToken, setUsers);
    };

    useEffect(() => {


        fetchUsers();
    }, []);

    const deleteUserFilter = (user) => setUsers(users.filter(u => u.id !== user.id));



    const handleLisääUser = (user) => {
        fetch(`${process.env.REACT_APP_API_URL}/users`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                username: user.username,
                password: user.password,
                name: user.name,
                role: user.role,
                titteli: user.titteli,
                addHenkilo: createPersonToo
            }
            ),
        })
            .then(response => {
                if (!response.ok) {
                    onError('Käyttäjän luominen epäonnistui');
                    throw new Error(response.message);
                }
                return response.json(); // Return the response.json() promise
            })
            .then((res) => {
                onSuccess('Käyttäjä added successfully.', 'users', res.id);
                setAddUserData({ username: '', name: '', titteli: '', role: '', password: '' });
                setShowAddForm(false);
                fetchUsers();
                fetchPeopleData();
            })
            .catch(err => console.log(err));
    };

    const handleDeleteUser = (event, user) => {
        handleDelete(event, 'users', accessToken, user, () => deleteUserFilter(user), setSuccessMessage, setErrorMessage, undefined, undefined, undefined,  sendLog, name);
    };

    const updateUsersFilter = (updatedUser, responseData) => {
        setUsers((prevUsers) =>
            prevUsers.map((user) => (user.id === updatedUser.id ? responseData : user))
        );
    };

    const handleUpdateUser = (user) => {
        handleUpdate('users', accessToken, user, updateUsersFilter, setSuccessMessage, setErrorMessage, sendLog, name);
    };









    return (
        <Grid container spacing={2}>
            <Box mt={3} ml={2}> {/* Add spacing to separate SearchBar */}
                <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
            </Box>
            <Box mt={3} ml={2}> {/* Add spacing to separate Select */}
                <Select
                    labelId="roleLabel"
                    id="roleSelect"
                    value={selectedRole}
                    onChange={(event) => setSelectedRole(event.target.value)}
                >
                    {userRoles.map((role) => (
                        <MenuItem key={role} value={role}>{role}</MenuItem>
                    ))}
                </Select>
            </Box>
            {displayedUsers.map((user, index) => (
                <Grid key={user.id} item xs={12} >
                    <Box key={index} onClick={() => handleUserClick(user)}
                        sx={{
                            backgroundColor: 'taphaWhite.main',
                            borderRadius: 5,

                            display: 'flex',
                            justifyContent: 'flex-start',
                            marginTop: 0.6,
                            gap: 5,
                            '&:hover': {
                                border: '1px solid',
                                borderLeft: '30px solid',
                                transition: '0.4s',
                                borderColor: 'taphaGreen.main',
                                cursor: 'pointer',
                            },
                        }}
                    >
                        <Typography variant="h6">{user.name}</Typography>
                        <IconButton onClick={(event) => {
                            handleDeleteUser(event, user);
                            if (selectedUser && selectedUser.id === user.id) {
                                setSelectedUser(null);
                                setNewUserData({});
                            }
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                    {selectedUser && selectedUser.id === user.id && (
                        <Grid container>
                            <Grid item xs={8} sx={{ pt: 2 }}>
                                <FormTextField

                                    fieldName="username"
                                    label="username"
                                    multiline={true}
                                    fullWidth={true}

                                    value={newUserData.username}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setNewUserData((prevState) => ({ ...prevState, [name]: value }));
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <FormTextField

                                    fieldName="name"
                                    label="name"
                                    multiline={true}
                                    fullWidth={true}

                                    value={newUserData.name}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setNewUserData((prevState) => ({ ...prevState, [name]: value }));
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8} sx={{ pt: 2, pb: 2 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="rooliNew-label">rooli</InputLabel>
                                    <Select
                                        labelId="rooliNewId"
                                        id="rooliNew-select"
                                        value={newUserData.role}
                                        label="rooli"
                                        onChange={(event) => {
                                            const { value } = event.target;


                                            setNewUserData((prevState) => ({ ...prevState, 'role': value }));

                                        }}
                                    >
                                        {userRoles.map((rooli) => (
                                            <MenuItem key={rooli} value={rooli}>
                                                {rooli}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={8}>
                                <FormTextField

                                    fieldName="titteli"
                                    label="titteli"
                                    multiline={true}
                                    fullWidth={true}

                                    value={newUserData.titteli}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setNewUserData((prevState) => ({ ...prevState, [name]: value }));
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <FormTextField

                                    fieldName="password"
                                    label="password"
                                    multiline={true}
                                    fullWidth={true}

                                    value={newUserData.password}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setNewUserData((prevState) => ({ ...prevState, [name]: value }));
                                    }}
                                />
                            </Grid>
                            <UpdateButton handleUpdate={handleUpdateUser} data={newUserData}/>
                        </Grid>
                    )}
                </Grid>
            ))}
            {showAddForm && (
                <Box sx={{
                    flexGrow: 1, // Add flexGrow to make the box grow
                }}>
                    <Typography variant="h6" sx={{ pt: 2 }}>Käyttäjän lisääminen</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={8} sx={{ pt: 2, pb: 2 }}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="rooliAdd-label">rooli</InputLabel>
                                <Select
                                    labelId="rooliAddId"
                                    id="rooliAdd-select"
                                    value={addUserData.role}
                                    label="rooli"
                                    onChange={(event) => {
                                        const { value } = event.target;


                                        setAddUserData((prevState) => ({ ...prevState, 'role': value }));

                                    }}
                                >
                                    {userRoles.map((rooli) => (
                                        <MenuItem key={rooli} value={rooli}>
                                            {rooli}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={8} >
                            <FormTextField
                                error={false}
                                key="addUserUsername"
                                fieldName='username'
                                label='username'
                                multiline={true}
                                fullWidth={true}

                                value={addUserData.username}
                                onChange={(event) => setAddUserData({ ...addUserData, username: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormTextField
                                error={false}
                                key="addUserName"
                                fieldName='name'
                                label='nimi'
                                multiline={true}
                                fullWidth={true}

                                value={addUserData.name}
                                onChange={(event) => setAddUserData({ ...addUserData, name: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormTextField
                                error={false}
                                key="addUserTitteli"
                                fieldName='titteli'
                                label='titteli'
                                multiline={true}
                                fullWidth={true}

                                value={addUserData.titteli}
                                onChange={(event) => setAddUserData({ ...addUserData, titteli: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormTextField
                                error={false}
                                key="addUserSalasana"
                                fieldName='salasana'
                                label='salasana'
                                multiline={true}
                                fullWidth={true}

                                value={addUserData.password}
                                onChange={(event) => setAddUserData({ ...addUserData, password: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={createPersonToo}
                                        onChange={(event) => setCreatePersonToo(event.target.checked)}
                                        name="createPersonToo"
                                        color="primary"
                                    />
                                }
                                label="Luo myös henkilö"
                            />
                        </Grid>
                        <Box display="flex" justifyContent="flex-end" width="100%" pt={1}>

                            <Button
                                onClick={() => handleLisääUser(addUserData)}
                                size="small"
                                variant="contained"
                                startIcon={<AddIcon />}
                                sx={{ backgroundColor: 'taphaGreen.main' }}
                            >
          Lisää käyttäjä
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            )}
            {showAddForm ? (
                <Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={handleAddClick}
                        size='small'
                        variant='contained'
                        startIcon={<CloseIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Sulje
                    </Button>
                </Box>
            ) :
                (<Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={handleAddClick}
                        size='small'
                        variant='contained'
                        startIcon={<AddIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Lisää
                    </Button>
                </Box>)}
            {/* Pagination arrows */}
            <Box mt={2} ml={2} display="flex" flexDirection="column" alignItems="center">
                <PaginationInfo
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    filteredArray={filteredUsers}
                    array={users}
                />
                <Box display="flex" justifyContent="center" width="100%">
                    <Box width="740px" display="flex" justifyContent="center"> {/* Centered box with a specified width */}
                        <IconButton
                            size="small"
                            onClick={handlePreviousPage}
                            disabled={currentPage <= 1}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={handleNextPage}
                            disabled={currentPage >= totalPages}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );}

export default UserList;


