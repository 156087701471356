import { Controller, useFormContext } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';

import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { TextField } from '@mui/material';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';

const FormTimePicker = ({ fieldName, ...otherProps }) => {

    const { control } = useFormContext();

    const timePickerStyle = {
        components: {
            LeftArrowIcon: ArrowLeftOutlinedIcon,
            RightArrowIcon: ArrowRightOutlinedIcon
        },
        InputProps: {
            sx: { '& .MuiSvgIcon-root': { color: 'taphaBlue.main' } }
        },
        PopperProps: {
            sx: {
                '& .MuiPaper-root': {
                    border: '1px solid',
                    borderColor: 'taphaBlue.main'
                }
            }
        }
    };


    return (
        <Controller
            name={fieldName}
            control={control}
            render={({
                field,
                fieldState
            }) =>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fi'>
                    <DesktopTimePicker
                        label='Kellonaika' // Default label, can be overriden with otherProps.
                        { ...field }
                        { ...timePickerStyle }
                        { ...otherProps }
                        inputFormat='HH:mm'
                        ampm={false}
                        renderInput={(params) =>
                            <TextField
                                { ...params }
                                error={!!fieldState.error}
                                helperText={fieldState?.error?.message ?? ''}
                                autoComplete='off'
                            />
                        }
                    />
                </LocalizationProvider>
            }
        />
    );
};

FormTimePicker.propTypes = {
    fieldName: PropTypes.string
};

export default FormTimePicker;