import { useState } from 'react';
import { Grid, Typography, Box, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormTextField from '../../components/FormFields/FormTextField';
import { useAddNewYhteystietoMutation  } from '../../api/yhteystiedotApiSlice';
import UpdateButton from './adminUtils/updateButton';
import SearchBar from './adminUtils/SearchBar';
import PaginationInfo from './PaginationInfo';

function YhteystiedotList({ yhteystiedot, handleDeleteYhteystieto, handleUpdateYhteystieto, fetchData, onSuccess, onError }) {
    const [selectedYhteystieto, setSelectedYhteystieto] = useState(null);
    const [newYhteystietoData, setNewYhteystietoData] = useState({ yhteystieto: '' });
    const [addYhteystietoData, setAddYhteystietoData] = useState({ yhteystieto: '' });
    const [showAddForm, setShowAddForm] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [addNewYhteystieto, { isLoading }] = useAddNewYhteystietoMutation();



    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
        setCurrentPage(1); // Reset to the first page when searching
    };

    // Apply the search filter
    const filteredYhteystiedot = yhteystiedot?.length > 0 && yhteystiedot?.filter((Yhteystieto) =>
        Yhteystieto.yhteystieto.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalPages = Math.ceil(filteredYhteystiedot?.length / itemsPerPage);
    // Get the slice of luonteet for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedYhteystiedot = yhteystiedot?.length > 0 && filteredYhteystiedot?.slice(startIndex, endIndex);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleUserClick = (Yhteystieto) => {
        if(selectedYhteystieto?.id === Yhteystieto.id) {
            setSelectedYhteystieto(null);

            setNewYhteystietoData({ yhteystieto: '' });

            return;
        }
        setSelectedYhteystieto(Yhteystieto);
        setNewYhteystietoData(Yhteystieto);
    };

    const handleAddClick = () => {
        setShowAddForm(!showAddForm);
    };

    const handleLisääYhteystieto = async(Yhteystieto) => {
        try {
            const result = await addNewYhteystieto(Yhteystieto);
            if(result.error) onError('Yhteystiedon luominen epäonnistui');
            if(!result.error) onSuccess('Yhteystieto added successfully.', 'yhteystiedot', result.data.id);
            setAddYhteystietoData({ Yhteystieto: '' });
            setShowAddForm(false);
            fetchData();
        } catch (error) {
            console.error(error); // handle error
        }};


    return (
        <Grid container spacing={2}>
            <Box mt={3} ml={2}> {/* Add spacing to separate SearchBar */}
                <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
            </Box>
            {yhteystiedot?.length > 0 && displayedYhteystiedot.map((Yhteystieto) => (
                <Grid key={Yhteystieto.id} item xs={12} >
                    <Box key={Yhteystieto.id} onClick={() => handleUserClick(Yhteystieto)} sx={{
                        backgroundColor: 'taphaWhite.main',
                        borderRadius: 5,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginTop: 0.6,
                        gap: 5,
                        '&:hover': {
                            border: '1px solid',
                            borderLeft: '30px solid',
                            transition: '0.4s',
                            borderColor: 'taphaGreen.main',
                            cursor: 'pointer'
                        }
                    }}>
                        <Typography variant="h6">
                            {Yhteystieto.yhteystieto}
                        </Typography>
                        <IconButton onClick={(event) => {
                            handleDeleteYhteystieto(event, Yhteystieto);
                            if (selectedYhteystieto && selectedYhteystieto.id === Yhteystieto.id) {
                                setSelectedYhteystieto(null);
                                setNewYhteystietoData({ yhteystieto: '' });
                            }
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                    {selectedYhteystieto && selectedYhteystieto.id === Yhteystieto.id && (
                        <Grid container>

                            <Grid item xs={8} sx={{ pt: 2 }}>
                                <FormTextField

                                    fieldName='yhteystieto'
                                    label='yhteystieto'
                                    multiline={true}
                                    fullWidth={true}

                                    value={newYhteystietoData.yhteystieto}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setNewYhteystietoData((prevState) => ({ ...prevState, [name]: value }));
                                    }}

                                />
                            </Grid>


                            <UpdateButton handleUpdate={handleUpdateYhteystieto} data={newYhteystietoData}/>
                        </Grid>
                    )}
                </Grid>
            ))}
            {showAddForm && (
                <Box sx={{
                    flexGrow: 1, // Add flexGrow to make the box grow
                }}>
                    <Typography variant="h6" sx={{ pt: 2 }}>Yhteystiedon lisääminen</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <FormTextField
                                key="addYhteystieto"
                                fieldName='yhteystieto'
                                label='yhteystieto'
                                multiline={true}
                                fullWidth={true}

                                value={addYhteystietoData.yhteystieto}
                                onChange={(event) => setAddYhteystietoData({ ...addYhteystietoData, yhteystieto: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                        </Grid>

                        <Box display="flex" justifyContent="flex-end" width="100%" pt={1}>

                            <Button
                                onClick={() => handleLisääYhteystieto(addYhteystietoData)}
                                size="small"
                                variant="contained"
                                startIcon={<AddIcon />}
                                sx={{ backgroundColor: 'taphaGreen.main' }}
                            >
          Lisää yhteystieto
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            )}
            {showAddForm ? (
                <Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={handleAddClick}
                        size='small'
                        variant='contained'
                        startIcon={<CloseIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Sulje
                    </Button>
                </Box>
            ) :
                (<Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={handleAddClick}
                        size='small'
                        variant='contained'
                        startIcon={<AddIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Lisää
                    </Button>
                </Box>)}
            {/* Pagination arrows */}
            <Box mt={2} ml={2} display="flex" flexDirection="column" alignItems="center">
                <PaginationInfo
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    filteredArray={filteredYhteystiedot}
                    array={yhteystiedot}
                />
                <Box display="flex" justifyContent="center" width="100%">
                    <Box width="740px" display="flex" justifyContent="center"> {/* Centered box with a specified width */}
                        <IconButton
                            size="small"
                            onClick={handlePreviousPage}
                            disabled={currentPage <= 1}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={handleNextPage}
                            disabled={currentPage >= totalPages}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
}

export default YhteystiedotList;