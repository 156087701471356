import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({


    vakimaara: Yup.number().required('Pakollinen')
        .transform((value) => (isNaN(value) ? undefined : value)).nullable(),

    laskut: Yup.array().of(
        Yup.object().shape({
            maksava_taho: Yup.string().required('Pakollinen'),
            tilaajan_viite: Yup.string().required('Pakollinen'),
            saa_laskuttaa_vasta: Yup.string().required('Pakollinen'),
            laskutyyppi: Yup.string().required('Pakollinen'),
            laskutusosoite: Yup.string().required('Pakollinen'),
            kustannuspaikka: Yup.string().required('Pakollinen'),
            summa: Yup.number().required('Pakollinen')
                .transform((value) => (isNaN(value) ? undefined : value)).nullable()
        })
    )
});

export const convertDataToFormSchema = (event, user) => {

    // Attach the event's ID to the report.

    const reportData = {
        tapahtumaId: event.id
    };

    // The default values for React Hook Form have to be set here, as the event
    // doesn't contain all the fields in the reporting form yet.

    reportData.yhteistyotahot = event.yhteistyotahot.map(yhteistyotaho => ({
        nimi: yhteistyotaho,
        suhtautuminen: 0
    }));

    // Parse the wanted fields from the schedule and prepare the default values
    // the event doesn't have yet.



    return reportData;
};

export const defaultValues = {
    tapahtumaId: '',
    yhteistyotahot: [],
    laskut: [],
    vakimaara: Number,
    muistiinpano: '',
};