/*
The purpose of this module is to provide a single point for all date operations,
so importing DayJs is not required anywhere else for formatting
*/

import dayjs from 'dayjs'; // Lightweight date parsing library.

// Finnish locale for dates, importing it is required.
// Used for Finnish month names.
import 'dayjs/locale/fi';
dayjs.locale('fi');

import localeData from 'dayjs/plugin/localeData';
dayjs.extend(localeData);

// Update the Finnish locale of the dayjs to use capitalized month names.
// as they look better in the MUI DatePicker's calendar.

import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale);
dayjs.updateLocale('fi', {
    months: dayjs.months().map(month => month.charAt(0).toUpperCase() + month.substring(1))
});

dayjs().localeData();

// Returns the current month in its Finnish short format. E.g. "Tammi", "Helmi", "Maalis".
const getCurrentMonthShort = () => dayjs().format('MMM');

// Returns all month names in their Finnish short formats.
const getAllMonthNamesShort = () => dayjs.monthsShort();

// Returns the year as a string for a given date.
const getCurrentYear = (date) => dayjs(date).format('YYYY');

// Return the formatted time string of a given date.
const getFormattedDate = (date) => dayjs(date).format('DD.MM.YYYY');

// Return the formatted time string of a given date with hours and minutes.
const getFormattedDateWithHours = (date) => dayjs(date).format('DD.MM.YYYY HH:mm');

// Returns all the different time/date fields for an event, to be used
// throughout the whole app. Focus it all here for easier maintaining.
const getDateFields = (date) => {

    const dayjsDate = dayjs(date, 'DD.MM.YYYY HH:mm');

    return {
        day: dayjs(dayjsDate).format('DD'),
        month: dayjs(dayjsDate).format('MM'),
        year: dayjs(dayjsDate).format('YYYY'),
        monthName: dayjs(dayjsDate).format('MMM'),
        dateString: dayjs(dayjsDate).format('DD.MM.YYYY')
    };
};

const sortByDateDesc = (array, dateField) => array.sort((a, b) => dayjs(b[dateField]) - dayjs(a[dateField]));

// Returns an array of years between EVENT_MIN_YEAR environment var and the current year + 1.
const getFilterYears = () => {

    // minYear is the year of the earliest events in our DB.
    const minYear = parseInt(process.env.REACT_APP_EVENT_MIN_YEAR);
    const currentYear = parseInt(getCurrentYear());

    // Form an array of years, with the first element being the minYear,
    // and the last element being currentYear + 1, with every other element
    // representing a year between them. The array is then used as filter
    // values in EventList.js.

    return Array.from(
        new Array(currentYear - minYear + 2),
        (nullEl, i) => (minYear + i).toString()
    );
};

export default {
    sortByDateDesc,
    getDateFields,
    getCurrentMonthShort,
    getAllMonthNamesShort,
    getFormattedDate,
    getFormattedDateWithHours,
    getCurrentYear,
    getFilterYears
};
