import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider
} from 'react-router-dom';

import HomeLayout from './components/HomeLayout';
import Prefetch from './features/authentication/Prefetch';
import PersistedUserLogin from './features/authentication/components/PersistedUserLogin';
import RequireAuthentication from './features/authentication/components/RequireAuthentication';

import { roles } from './config/roles';

import AdminPage from './pages/AdminPage/AdminPage';
import EventForm from './features/events/components/EventForm';
import EventPage from './pages/EventPage';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import StatisticsPage from './pages/StatisticsPage';

import WithEvent from './features/events/components/WithEvent';
import ReportingForm from './features/events/components/ReportingForm';
import ViewEvent from './features/events/components/ViewEvent';
import WithReport from './features/events/components/WithReport';
import BugiRaporttiForm from './pages/Bugiraportointi';

// Updated router to use the react-router-dom 6.4 APIs.

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/'>
            <Route path='/login' element={ <LoginPage documentTitle='Kirjaudu sisään' /> }/>

            { /* Log in required*/ }
            <Route element={ <PersistedUserLogin />}>
                <Route element={ <RequireAuthentication restrictedTo={[...Object.values(roles)]}/> }>
                    <Route element={ <Prefetch /> }>

                        <Route path='/' element={ <HomeLayout /> }>
                            <Route index element={ <HomePage documentTitle='Omat tapahtumat' /> }/>

                            <Route path='/tapahtumat'>
                                <Route index element={ <EventPage documentTitle='Tapahtumat'/> } />
                                <Route path=':id' element={
                                    <WithEvent>
                                        <ViewEvent />
                                    </WithEvent>
                                }/>
                                <Route element={<RequireAuthentication restrictedTo={[roles.suunnittelija, roles.admin, roles.laskuttaja, roles.muokkaaja]}/>}>
                                    <Route path='uusi' element={ <EventForm documentTitle='Uusi tapahtuma' /> }/>
                                    <Route path='muokkaa/:id' element={
                                        <WithEvent restrictToSameUser={true} disableEditingPast={true}>
                                            <EventForm isEditing={true} documentTitle='Muokkaa tapahtumaa' />
                                        </WithEvent>
                                    }/>
                                    <Route path='raportit/:id' element={
                                        <WithEvent restrictToSameUser={true}>
                                            <ReportingForm documentTitle={'Raportoi tapahtuma'} />
                                        </WithEvent>
                                    }/>
                                    <Route path='raportit/muokkaa/:id' element={
                                        <WithEvent restrictToSameUser={true}>
                                            <WithReport>
                                                <ReportingForm documentTitle='Muokkaa raporttia' isEditing={true} />
                                            </WithReport>
                                        </WithEvent>
                                    }/>
                                </Route>
                            </Route>
                            <Route element={<RequireAuthentication restrictedTo={[roles.suunnittelija, roles.admin, roles.laskuttaja, roles.muokkaaja]}/>}>
                                <Route path='/tilastot' element={ <StatisticsPage documentTitle='Tilastot'/> }/>
                            </Route>

                            <Route path='/bugiraportointi' element={ <BugiRaporttiForm documentTitle='Bugiraportointi'/> }/>


                            <Route element={ <RequireAuthentication restrictedTo={[roles.admin]}/> }>
                                <Route path='/admin' key="adminPageKey" element={ <AdminPage documentTitle='Admin-paneeli' />} />
                            </Route>
                        </Route>
                    </Route>
                </Route>
            </Route>
            { /* Log in required*/ }
        </Route>
    )
);

function App() {
    return <RouterProvider router={router} />;
}

export default App;
