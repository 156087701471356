

const handleUpdate = async (endpoint, accessToken, updatedEl, updateListFunc, successFunction, errorFunction, sendLog, name ) => {



    try {
        const formData = new FormData();
        if(updatedEl.henkilokuva) {


            // Append text data fields to the FormData
            formData.append('id', updatedEl.id);
            formData.append('email', updatedEl.email);
            formData.append('nimi', updatedEl.nimi);
            formData.append('puhelinnumero', updatedEl.puhelinnumero);
            formData.append('tyonkuva', updatedEl.tyonkuva);
            formData.append('puhelinnumero2', updatedEl.puhelinnumero2);
            formData.append('yhteystiedot', updatedEl.yhteystiedot);
            formData.append('titteli', updatedEl.titteli);
            // Append the file to the FormData

            if (updatedEl.henkilokuva && updatedEl.henkilokuva.file) {
                formData.append('henkilokuva', updatedEl.henkilokuva.file);
            } else if (updatedEl.henkilokuva) {
                // If henkilokuva is an object, stringify it and append it
                formData.append('henkilokuva', JSON.stringify(updatedEl.henkilokuva));
            }
        }


        const response = await fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
            method: 'PUT',
            headers: {
                ...(updatedEl.henkilokuva ? {} : { 'Content-Type': 'application/json' }),
                'Authorization': `Bearer ${accessToken}`
            },
            body: updatedEl.henkilokuva ? formData : JSON.stringify(updatedEl)
        });



        if (!response.ok) {
            errorFunction('Päivitys epäonnistui');
            throw new Error('Failed to update');

        }


        const responseData = await response.json();
        /*await sendLog({
            name: name,
            action: 'muokkasi',
            document: {
                type: endpoint,
                documentId: updatedEl.id
            }
        });*/
        successFunction('Päivitys onnistui!');

        // Update the allRoles state with the updated role
        updateListFunc(updatedEl, responseData);

    } catch (error) {
        console.error(error);
    }
};

export default handleUpdate;