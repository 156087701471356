import React, { useState, useEffect } from 'react';
import { Button, Typography, List, ListItem, ListItemText, CircularProgress, Box } from '@mui/material';
import { useGetLokiQuery } from '../../api/lokiSlice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const LogList = () => {
    const [pageNumber, setPageNumber] = useState(1); // Track the current page number
    const pageSize = 20; // Number of logs to fetch per page

    // Fetch logs using React Query
    const { data: loki, isLoading, isError, refetch } = useGetLokiQuery({ page: pageNumber, size: pageSize });

    // Function to format the time
    const formatTime = (timeString) => {
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        };
        return new Date(timeString).toLocaleString('fi-FI', options);
    };

    const handleFetchMoreLogs = () => {
        setPageNumber(pageNumber + 1); // Increment page number to fetch the next page
    };

    const handleFetchPreviousLogs = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Decrement page number to fetch the previous page
        }
    };

    return (
        <div>
            <Typography variant="h5">Loki</Typography>

            {isLoading && <CircularProgress />}
            {isError && <Typography>Error fetching logs</Typography>}
            {loki && (
                <>
                    <List>
                        {loki.map(log => (
                            <ListItem key={log._id} disablePadding>
                                <ListItemText
                                    primary={`${formatTime(log.aika)} - ${log.action.toUpperCase()} (${log.name})`}
                                    secondary={log.document ? `[${log.document.type} ${log.document.documentId}]` : '[No document]'}
                                />
                            </ListItem>
                        ))}
                    </List>
                    <Box mt={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={pageNumber === 1} // Disable button if on the first page
                            onClick={handleFetchPreviousLogs}
                            startIcon={<ArrowBackIcon />}
                        />
                        <Typography variant="body1" component="span" ml={2}>
                            Sivu: {pageNumber}
                        </Typography>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleFetchMoreLogs}
                            disabled={loki.length < pageSize}
                            endIcon={<ArrowForwardIcon />}
                        />
                    </Box>
                </>
            )}
        </div>
    );
};

export default LogList;

