import { useFieldArray } from 'react-hook-form';
import {
    Alert,
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import PropTypes from 'prop-types';

import FormTextField from '../../../../components/FormFields/FormTextField';

// Semidynamic field array for the form. Currently only two inputs/subfields
// are usable. Could support an unlimited amount, but for now theres no need.

const FormListField = ({ fieldName, subfields, emptyMsg }) => {

    const {
        fields,
        append,
        remove
    } = useFieldArray({ name: fieldName });

    // Creates an object containing the given subfields as properties with
    // an empty string as their value.

    const newElement = () => Object.fromEntries(
        subfields.map(subfield => [subfield, ''])
    );

    const listFields = fields.map((field, i) =>
        <ListItem
            key={field.id}
            disableGutters
            sx={{ p: 0, display: 'flex', justifyContent: 'space-between' }}
            secondaryAction={
                <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => remove(i)}>
                    <ClearIcon sx={{ color: 'taphaBlue.dark' }} />
                </IconButton>
            }>
            {subfields.map(subfield =>
                <ListItemText key={subfield}>
                    <FormTextField
                        fieldName={`${fieldName}.[${i}].${subfield}`}
                        label={subfield.charAt(0).toUpperCase() + subfield.slice(1)}
                        variant='standard'
                    />
                </ListItemText>
            )}
        </ListItem>
    );

    return (
        <>
            <List sx={{ width: '100%' }} disablePadding>
                {listFields}
                {!listFields.length && <Alert severity='info' sx={{ borderRadius: 3 }}>{emptyMsg}</Alert>}
            </List>
            <Box display='flex' justifyContent='flex-end' width='100%' pt={2}>
                <Button
                    onClick={() => append(newElement())}
                    size='small'
                    variant='contained'
                    startIcon={<AddIcon />}
                    sx={{ backgroundColor: 'taphaBlue.main' }}
                >
                    Lisää
                </Button>
            </Box>
        </>
    );
};

FormListField.propTypes = {
    fieldName: PropTypes.string,
    subfields: PropTypes.array,
    emptyMsg: PropTypes.string
};

export default FormListField;