import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

import ModalContent from './ModalContent';

// A modal component used for searching and displaying events.
// Allows more specific searches for events by for example the region
// and center of the event.

// Events are only passed to ModalContent.js as props through this component.
// We could access the events directly through the RTK cache, but it's better to pass
// the events as props here, as they already have the date fields added to them.

const SearchModal = ({ isOpen, onClose }) => {

    const boxStyle = {
        borderRadius: 3,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '90%',
        bgcolor: 'whitesmoke',
        p: 1
    };

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            keepMounted>
            <Box sx={boxStyle}>
                <IconButton
                    onClick={onClose}
                    size='large'
                    sx={{ color: 'black' }}>
                    <CloseIcon fontSize='large'/>
                </IconButton>
                <ModalContent />
            </Box>
        </Modal>
    );
};

export default SearchModal;