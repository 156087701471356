import { useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
    Alert,
    Box,
    IconButton,
    ListSubheader,
    Button
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useFormContext } from 'react-hook-form';
import ScheduleListItem from './ScheduleListItem';

const ScheduleDate = ({ control, nestedIndex, fieldName, subfieldName, date, kansanopisto, setHours }) => {

    const { getValues } = useFormContext();

    const { fields, remove, append } = useFieldArray({
        control,
        name: `${fieldName}.[${nestedIndex}].${subfieldName}`
    });

    const handleTilaisuusRemove = (nestedIndex, subFieldIndex) => {
        const fieldValues = getValues();

        // Access the specific tilaisuudet array
        const tilaisuudetArray = fieldValues[fieldName][nestedIndex].tilaisuudet;


        // Access the specific tilaisuus object
        const removedTilaisuus = tilaisuudetArray[subFieldIndex];

        // Access the kansanopistotunnit value within the tilaisuus object
        const removedHours = removedTilaisuus.kansanopistotunnit && removedTilaisuus.kansanopistotunnit[0]?.kansanopistotunnit || 0;


        // Update the total hours
        setHours((prevTotal) => prevTotal - removedHours);

        // Handle the removal of the tilaisuus from the schedule
        remove(subFieldIndex);
    };

    const subHeaderStyle = {
        textAlign: 'center',
        fontFamily: '"Bree Serif", sans-serif',
        fontSize: 18,
        color: 'black'
    };

    let content;

    if (!fields.length)
        content = (
            <Alert component='li' severity='info' sx={{ borderRadius: 3 }}>
                Tälle päivälle ei vielä ole ohjelmaa
            </Alert>);


    else
        content = fields?.map((field, index) =>
            <ScheduleListItem
                key={field.id}
                kansanopisto={kansanopisto}
                fieldPath={`${fieldName}.[${nestedIndex}].${subfieldName}.[${index}]`}
                setHours={setHours}
                handleRemove={() => handleTilaisuusRemove(nestedIndex, index)}
            />

        );

    return (
        <>
            <ListSubheader variant='overline' disableSticky disableGutters sx={subHeaderStyle}>
                {date}
            </ListSubheader>
            {content}
            <Box display='flex' justifyContent='flex-end' p={0}>
                <Button size='small'
                    onClick={() => append({ nimi: '', aloitus: '', lopetus: '', kuvaus: '', henkilot: [] })}
                    variant='contained'
                    sx={{ backgroundColor: 'taphaBlue.main', whiteSpace: 'nowrap', px: 3, mt:1 }}>Lisää tilaisuus</Button>
            </Box>
        </>
    );
};

ScheduleDate.propTypes = {
    control: PropTypes.object,
    nestedIndex: PropTypes.number,
    fieldName: PropTypes.string
};

export default ScheduleDate;