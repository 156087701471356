import { useWatch } from 'react-hook-form';
import { Alert, ListItemText } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const SchedulePreview = ({ name, hasErrors }) => {


    const scheduleDetails = useWatch({ name });



    const textProps = scheduleDetails.aloitus && scheduleDetails.nimi.length > 4
        ? {
            primary: scheduleDetails.nimi,
            secondary: (() => {
                const aloitusTime = dayjs(scheduleDetails.aloitus).format('HH:mm');
                const lopetusTime = scheduleDetails.lopetus && dayjs(scheduleDetails.lopetus).isValid()
                    ? dayjs(scheduleDetails.lopetus).format('HH:mm')
                    : '';

                return `${aloitusTime} - ${lopetusTime}`;
            })(),
        }
        : { secondary: 'Tilaisuudella ei vielä ole tarvittavia tietoja' };

    if (hasErrors)
        return (
            <Alert severity='error'>
                Tilaisuudelta puuttuu tietoja.
            </Alert>
        );

    return (
        <ListItemText
            { ...textProps }
            sx={{ ml: 1 }}
            primaryTypographyProps={{ variant: 'h6' }}
            secondaryTypographyProps={{ variant: 'overline' }}

        />
    );
};

SchedulePreview.propTypes = {
    name: PropTypes.string,
    hasErrors: PropTypes.bool
};

export default SchedulePreview;