import {
    AppBar,
    Container,
    Toolbar,
    Typography
} from '@mui/material';


const Header = () => {

    return (
        <AppBar position='static' color='taphaGreen'>
            <Container maxWidth='xl'>
                <Toolbar disableGutters sx={{ justifyContent: 'center' }}>
                    <Typography
                        variant="h4"
                        noWrap
                        sx={{
                            mr: 2,
                            display: 'flex',
                            color: 'white',
                            letterSpacing: '1px',
                            textDecoration: 'none',
                        }}
                    >SRO Tapahtumien hallinta
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>

    );
};

export default Header;