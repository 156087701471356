import React from 'react';
import { Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';

const AttendingPerson = ({ name, allPeopleNamesAndTitles }) => {

    const personWithMatchingName = allPeopleNamesAndTitles?.find(person => person.nimi === name);

    const titteli =  (personWithMatchingName?.titteli !== 'Undefined' &&  personWithMatchingName?.titteli) || '';

    return(
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                fontSize: '14px', // Customize the font size
                paddingY: '4px',
            }}
        >
            <Avatar sx={{ width: '24px', height: '24px' }}>{name.charAt(0)}</Avatar>
            <Typography variant="body2">
                {name}
                {titteli && <span style={{ color: 'gray', marginLeft: '0.5rem' }}>({titteli})</span>}
            </Typography>
        </Box>
    );};

const TilaisuusHenkilotList = ({ attendingPeople, findTitles }) => {

    return(
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                marginLeft: 1,
            }}
        >
            <Typography variant="body2">Tilaisuuteen liittyvät henkilöt:</Typography>
            {attendingPeople.map((person) => (
                <AttendingPerson key={person.henkilo} name={person.henkilo} allPeopleNamesAndTitles={findTitles} />
            ))}
        </Box>
    );};

export default TilaisuusHenkilotList;