/*
    API slice used to fetch the roles from the backend.
    It's not inside any feature's directory, as it will likely be used in at
    least two different features (AdminPanel, Events).
*/

import { createSelector, createEntityAdapter } from '@reduxjs/toolkit';

import { apiSlice } from './apiSlice';

const rolesAdapter = createEntityAdapter({
    selectId: (role) => role.id
});
const initialState = rolesAdapter.getInitialState();

export const rolesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getRoles: builder.query({
            query: () => ({
                url: '/roolit',
                validateStatus: (response, result) =>
                    response.status === 200 && !result.isError
            }),
            transformResponse: responseData => {
                return rolesAdapter.setAll(initialState, responseData);
            },
            providesTags: ['Roles']
        }),
        addNewRole: builder.mutation({
            query: newRole => ({
                url: '/roolit',
                method: 'POST',
                body: { ...newRole }
            }),
            invalidatesTags: ['Roles'],
            onQueryStarted: (_, { dispatch }) => {
                dispatch(rolesApiSlice.util.invalidateTags('Roles'));
            }, onQueryCompleted: (_, { dispatch }) => {
                dispatch(rolesApiSlice.endpoints.getRoles.initiate());
            }
        }),
        deleteRole: builder.mutation({
            query: ({ id }) => ({
                url: '/roolit',
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: ['Roles']
        }),
    })
});

export const {
    useGetRolesQuery,
    useAddNewRoleMutation,
    useDeleteRoleMutation
} = rolesApiSlice;

export const selectRolesResult = rolesApiSlice.endpoints.getRoles.select('RolesList');

const selectRolesData = createSelector(
    selectRolesResult,
    rolesResult => rolesResult.data
);

export const {
    selectIds: selectRoleIds,
    selectEntities: selectRoleEntities
} = rolesAdapter.getSelectors(state => selectRolesData(state) ?? initialState);

// Selector for only the roles themselves without ids.
export const selectAllRoles = createSelector(
    selectRoleIds,
    selectRoleEntities,
    (ids, roles) => ids?.map(id => roles[id].rooli)
);