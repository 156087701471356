import React from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    Grid,
    Paper,
    Typography,
    Box
} from '@mui/material';
import Kuvaus from './Kuvaus';
import { useGetKustannuspaikatQuery } from '../../../../api/kustannuspaikatApiSlice';

const ShowReport = ({ report, event }) => {
    const typographyStyles = {
        variant: 'h5',
        align: 'center',
        fontFamily: '"Bree Serif", serif',
        sx: { textDecoration: 'underline', pb: 1 }
    };



    return (
        <Container
            maxWidth='md'
            display='flex'
            sx={{ justifyContent: 'center', mt: 2 }}
        >
            <Paper
                id='reporting-form'
                elevation={3}
                sx={{
                    borderRadius: 8,
                    my: 3,
                    px: 8,
                    py: 4
                }}
            >
                <Grid rowSpacing={5} container width='100%'>

                    <Grid item xs={12} sx={{ pb: 3 }}>
                        <Typography variant='h4' align='center'>
                            Raportti: {event.nimi}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography { ...typographyStyles }>
                            Tapahtuman väkimäärä
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography align='center'>Väkimäärä: {report.vakimaara}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography { ...typographyStyles }>
                            Yhteistyötahot & suhtautuminen
                        </Typography>
                        <Grid item xs={12}>
                            {report.yhteistyotahot?.map(yhteistyotaho => (
                                <Box
                                    key={yhteistyotaho.nimi}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        mb: 1
                                    }}
                                >
                                    <Typography>{yhteistyotaho.nimi}</Typography>
                                    <Box
                                        sx={{
                                            width: 20,
                                            height: 20,
                                            borderRadius: '50%',
                                            backgroundColor: yhteistyotaho.suhtautuminen === -1 ? 'red' : yhteistyotaho.suhtautuminen === 0 ? 'yellow' : 'green',
                                        }}
                                    />
                                </Box>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography { ...typographyStyles }>
                            Raportin muistiinpanot
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Kuvaus eventDesc={report.muistiinpano}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography { ...typographyStyles }>
                            Tapahtuman laskut
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        {report.laskut.map((lasku, index) => (
                            <Paper key={index} elevation={3} sx={{ p: 2, mb: 2 }}>
                                <Typography>Maksava taho: {lasku.maksava_taho}</Typography>
                                <Typography>Laskutusosoite: {lasku.laskutusosoite}</Typography>
                                <Typography>Tilaajan viite: {lasku.tilaajan_viite}</Typography>
                                <Typography>Saa laskuttaa vasta: {lasku.saa_laskuttaa_vasta}</Typography>
                                <Typography>Laskutyyppi: {lasku.laskutyyppi}</Typography>
                                <Typography>Summa: {lasku.summa} €</Typography>
                                <Typography>Kustannuspaikka: {lasku.kustannuspaikka}</Typography>
                                <Typography>Lisätiedot: {lasku.lisatiedot}</Typography>
                            </Paper>
                        ))}
                    </Grid>

                </Grid>
            </Paper>
        </Container>
    );
};

ShowReport.propTypes = {
    event: PropTypes.object,
    report: PropTypes.object
};

export default ShowReport;