import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ListItemText, ListItemButton } from '@mui/material';

const NavLinkItem = ({ icon, text, to }) => {

    const location = useLocation();

    return (
        <ListItemButton
            component={Link}
            to={to}
            selected={location.pathname === to} // Highlight the navlink according to the stem of the route's path.
            sx={{
                fontFamily: '"Bree Serif", serif',
                borderRadius: { md: 5, xs: 0 },
                textTransform: 'uppercase',
                color: 'white',
                mx: 1,
                '&.Mui-selected': {
                    border: '1px solid white',
                    backgroundColor: 'inherit'
                }
            }}
        >
            {icon}
            <ListItemText sx={{
                ml: 1,
                mt: 0,
                mb: 0
            }}
            primaryTypographyProps={{ fontFamily: '"Open Sans", sans-serif' }}
            primary={text === 'tapahtumat/uusi' ? 'Lisää tapahtuma' : text === '' ? 'Etusivu' : text}/>
        </ListItemButton>
    );
};

NavLinkItem.propTypes = {
    icon: PropTypes.element,
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
};

export default NavLinkItem;