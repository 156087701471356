import PropTypes from 'prop-types';
import {
    CircularProgress,
    Typography,
    Stack
} from '@mui/material';

const ProgressLoader = ({ msg }) => {
    return (
        <Stack
            paddingTop={2}
            alignItems='center'
            spacing={3}>
            <Typography
                variant='overline'
                fontSize='1rem'>{msg}</Typography>
            <CircularProgress
                disableShrink
                size='5rem'
                color='taphaGreen'
                value={50}/>
        </Stack>
    );
};

ProgressLoader.propTypes = {
    msg: PropTypes.string
};

export default ProgressLoader;