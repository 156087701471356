/*
    API slice used to fetch target audiences (kohdeyleisöt) from the backend.
    it's not inside any feature's directory, as it will likely be used in at
    least two different features (AdminPanel, Events).
*/

import { createSelector, createEntityAdapter } from '@reduxjs/toolkit';

import { apiSlice } from './apiSlice';

const audienceAdapter = createEntityAdapter({
    selectId: (audience) => audience.id
});
const initialState = audienceAdapter.getInitialState();

export const audiencesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAudiences: builder.query({
            query: () => ({
                url: '/kohdeyleisot',
                validateStatus: (response, result) =>
                    response.status === 200 && !result.isError
            }),
            transformResponse: responseData =>
                audienceAdapter.setAll(initialState, responseData),
            providesTags: ['Audiences']
        }),
        addNewAudience: builder.mutation({
            query: newAudience => ({
                url: '/kohdeyleisot',
                method: 'POST',
                body: { ...newAudience }
            }),
            invalidatesTags: ['Audiences']
        }),
        deleteAudience: builder.mutation({
            query: ({ id }) => ({
                url: '/kohdeyleisot',
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: ['Audiences']
        })
    }),
});

export const {
    useGetAudiencesQuery,
    useAddNewAudienceMutation,
    useDeleteAudienceMutation
} = audiencesApiSlice;

export const selectAudiencesResult = audiencesApiSlice.endpoints.getAudiences.select('AudiencesList');

const selectAudiencesData = createSelector(
    selectAudiencesResult,
    audienceResult => audienceResult.data
);

export const {
    selectIds: selectAudienceIds,
    selectEntities: selectAudienceEntities
} = audienceAdapter.getSelectors(state => selectAudiencesData(state) ?? initialState);

// Selector for only the audiences themselves without ids.
export const selectAllAudiences = createSelector(
    selectAudienceIds,
    selectAudienceEntities,
    (ids, audiences) => ids.map(id => audiences[id].yleiso)
);