import PropTypes from 'prop-types';
import {
    Box,
    Tab,
    Tabs
} from '@mui/material';

const EventFilters = ({ selected, handleChange, values }) => {

    const tabsStyle = {
        '.MuiTabs-scrollButtons.Mui-disabled': { // Keep the scroll buttons faded out when theres nothing to scroll. They hide by default.
            opacity: '0.3'
        },
        '& button.Mui-selected': {
            fontWeight: 700,
            color: 'taphaBlue.main',
        }
    };

    return (
        <Box sx={{ pb: '1.5rem', justifyContent: 'center', display: 'flex' }}>
            <Tabs
                sx={tabsStyle}
                TabIndicatorProps={{
                    sx: {
                        backgroundColor: 'taphaBlue.main',
                        height: 3
                    }
                }}
                value={selected}
                onChange={handleChange}
                scrollButtons='auto'
                allowScrollButtonsMobile={true}
                variant='scrollable'
                aria-label='scrollable selection '>
                {values.map(value =>
                    <Tab
                        key={value}
                        label={value}
                        value={value}
                        sx={{
                            fontFamily: 'Open Sans',
                            fontWeight: 600,
                            color: 'dimGray'
                        }}/>
                )}
            </Tabs>
        </Box>
    );
};

EventFilters.propTypes = {
    selectedMonth: PropTypes.string,
    handleFilterChange: PropTypes.func,
    values: PropTypes.array
};

export default EventFilters;