/*
  API slice used the fetch places for events from the Paikat-collection.

  Not inside any feature's directory, as it will likely be used in at least two
  different features (AdminPanel, Events).
*/

import { createSelector } from '@reduxjs/toolkit';
import { createEntityAdapter } from '@reduxjs/toolkit';

import { apiSlice } from './apiSlice';

const placesAdapter = createEntityAdapter({
    selectId: (place) => place.id
});
const initialState = placesAdapter.getInitialState();

export const placesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPlaces: builder.query({
            query: () => ({
                url: '/paikat',
                validateStatus: (response, result) =>
                    response.status === 200 & !result.isError
            }),
            transformResponse: responseData =>
                placesAdapter.setAll(initialState, responseData),
            providesTags: ['Places']
        }),
        addNewPlace: builder.mutation({
            query: newPlace => ({
                url: '/paikat',
                method: 'POST',
                body: { ...newPlace }
            }),
            invalidatesTags: ['Places']
        }),
        deletePlace: builder.mutation({
            query: ({ id }) => ({
                url: '/paikat',
                method: 'DELETE',
                body: { id }
            })
        })
    })
});

export const {
    useGetPlacesQuery,
    useAddNewPlaceMutation,
    useDeletePlaceMutation
} = placesApiSlice;

export const selectPlacesResult = placesApiSlice.endpoints.getPlaces.select('PlacesList');

const selectPlacesData = createSelector(
    selectPlacesResult,
    placesResult => placesResult.data
);

export const {
    selectEntities: selectPlaceEntities,
    selectIds: selectPlaceIds,
    selectById: selectPlaceById,
    selectByIds
} = placesAdapter.getSelectors(state => selectPlacesData(state) ?? initialState);

export const selectAllPlaces = createSelector(
    selectPlaceEntities,
    selectPlaceIds,
    (places, ids) => ids.map(id => places[id])
);