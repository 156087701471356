
import { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Alert,
    Box,
    Checkbox,
    FormControlLabel,
    Button,
    TextField,
    Typography
} from '@mui/material';

import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useField from '../../../hooks/useField';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { useLoginMutation } from '../authApiSlice';
import ProgressLoader from '../../../components/ProgressLoader';
import { useAddNewLokiMutation } from '../../../api/lokiSlice';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../authSlice';

const LoginForm = () => {

    const usernameRef = useRef();
    const passwordRef = useRef();
    const [errorMsg, setErrorMsg] = useState('');

    const navigate = useNavigate();

    const [login, { isLoading }] = useLoginMutation();
    const [ sendLog ] = useAddNewLokiMutation();

    const { setValue: setUsername, ...username } = useField('text'); // Rename the setters accordingly, as they both have setValue by default.
    const { setValue: setPassword, ...password } = useField('password');
    const [rememberUser, setRememberUser] = useLocalStorage('rememberMe', false);

    // Set the focus to the username field on component mount.
    useEffect(() => {
        usernameRef.current.focus();
    }, []);

    // Wipe out the error message whenever the user stars typing.
    useEffect(() => {
        setErrorMsg('');
    }, [username.value, password.value]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const credentials = {
            username: username.value.toLowerCase(), // Username shouldn't be case sensitive. Backend takes care of this, but include it here as well.
            password: password.value
        };

        try {
            const result = await login(credentials).unwrap();
            await sendLog({
                name: result.name,
                action: 'kirjautui sisään'
            });
            setUsername('');
            setPassword('');

            navigate('/');
        } catch (error) {

            if (error.status === 400)
                setErrorMsg('Käyttäjätunnus tai salasana puuttuvat');
            else if (error.status === 401)
                setErrorMsg('Väärä salasana tai käyttäjätunnus');
            else if (!error.status || error.status === 500)
                setErrorMsg('Ei vastausta palvelimelta. Ota yhteyttä IT-tukeen.');
            else
                setErrorMsg(error.data?.message);
        }
    };

    if (isLoading)
        return <ProgressLoader msg={'Kirjaudutaan sisään..'}/>;

    return (
        <form onSubmit={handleSubmit}>
            { errorMsg && <Alert severity='error' >{errorMsg}</Alert> }
            <Box display='flex'
                flexDirection={'column'}
                maxWidth={500}
                justifyContent={'center'}
                margin='auto'
                mt={5}
                p={{ xs: 2, sm: 7,  md: 10 }}
                borderRadius={5}
                boxShadow={'5px 5px 10px #ccc'}
                sx={{ ':hover': { boxShadow: '10px 10px 20px #ccc' } }}>
                <Typography
                    variant='h4'
                    padding={3}
                    align='center'
                    sx={{ display: 'block', fontSize: '1.5rem' }}
                >Kirjaudu sisään</Typography>
                <TextField
                    inputRef={usernameRef}
                    margin='normal'
                    variant='outlined'
                    placeholder='Käyttäjätunnus tai sähköpostiosoite'
                    required
                    { ...username }
                    onKeyDown={(e) => {
                        if (username.length && e === 'Enter')
                            passwordRef.current.focus();
                    }}
                />
                <TextField
                    inputRef={passwordRef}
                    FormHelperTextProps={{ fontFamily: '"Bree Serif", sans-serif' }}
                    margin='normal'
                    variant='outlined'
                    placeholder='Salasana'
                    required
                    { ...password }
                    onKeyDown={(e) => {
                        if (password.length && username.length && e === 'Enter')
                            handleSubmit();
                    }}
                />
                <Button
                    sx={{
                        marginTop: 3,
                        borderRadius: 3,
                        color: 'white',
                        '&:hover': {
                            bgcolor: 'taphaBlue.main',
                            color: 'white',
                            transition: '0.2s'
                        },
                        fontFamily: '"Bree Serif"',
                        letterSpacing: '1px'
                    }}
                    variant='contained'
                    color='taphaGreen'
                    size='medium'
                    type='submit'
                >Kirjaudu sisään</Button>
                <Box display='flex' justifyContent='center' marginBottom={0} marginTop={3}>
                    <FormControlLabel
                        label={
                            <Typography
                                sx={{ fontFamily: '"Bree Serif", serif' }}
                                variant='body1'
                            >Muista minut</Typography>
                        }
                        control={
                            <Checkbox
                                name='Muista minut'
                                value='Muista minut'
                                checked={rememberUser}
                                onChange={() => setRememberUser(!rememberUser)}
                                inputProps={{ 'aria-label': 'controlled' }}
                                color='taphaBlue'
                            />
                        }
                    />
                </Box>
            </Box>
        </form>
    );
};

export default LoginForm;