import { useState } from 'react';
import { Grid, Typography, Box, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormTextField from '../../components/FormFields/FormTextField';
import { useAddNewEventTypeMutation  } from '../../api/eventTypesApiSlice';
import UpdateButton from './adminUtils/updateButton';
import SearchBar from './adminUtils/SearchBar';
import PaginationInfo from './PaginationInfo';

function LuonteetList({ luonteet, handleDeleteLuonne, handleUpdateLuonne, fetchData, onSuccess, onError }) {
    const [selectedLuonne, setSelectedLuonne] = useState(null);
    const [newLuonneData, setNewLuonneData] = useState({ luonne: '' });
    const [addLuonneData, setAddLuonneData] = useState({ luonne: '' });
    const [showAddForm, setShowAddForm] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [addNewLuonne, { isLoading }] = useAddNewEventTypeMutation();



    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
        setCurrentPage(1); // Reset to the first page when searching
    };

    // Apply the search filter
    const filteredLuonteet = luonteet.filter((Luonne) =>
        Luonne.luonne.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalPages = Math.ceil(filteredLuonteet.length / itemsPerPage);
    // Get the slice of luonteet for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedLuonteet = filteredLuonteet.slice(startIndex, endIndex);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleUserClick = (Luonne) => {
        if(selectedLuonne?.id === Luonne.id) {
            setSelectedLuonne(null);

            setNewLuonneData({ luonne: '' });

            return;
        }
        setSelectedLuonne(Luonne);
        setNewLuonneData(Luonne);
    };

    const handleAddClick = () => {
        setShowAddForm(!showAddForm);
    };

    const handleLisääLuonne = async(Luonne) => {
        try {
            const result = await addNewLuonne(Luonne);
            if(result.error) onError('Luonteen luominen epäonnistui');
            if(!result.error) onSuccess('Luonne added successfully.', 'luonteet', result.data.id);
            setAddLuonneData({ luonne: '' });
            setShowAddForm(false);
            fetchData();
        } catch (error) {
            console.error(error); // handle error
        }};


    return (
        <Grid container spacing={2}>
            <Box mt={3} ml={2}> {/* Add spacing to separate SearchBar */}
                <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
            </Box>
            {displayedLuonteet.map((Luonne) => (
                <Grid key={Luonne.id} item xs={12} >
                    <Box key={Luonne.id} onClick={() => handleUserClick(Luonne)} sx={{
                        backgroundColor: 'taphaWhite.main',
                        borderRadius: 5,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginTop: 0.6,
                        gap: 5,
                        '&:hover': {
                            border: '1px solid',
                            borderLeft: '30px solid',
                            transition: '0.4s',
                            borderColor: 'taphaGreen.main',
                            cursor: 'pointer'
                        }
                    }}>
                        <Typography variant="h6">
                            {Luonne.luonne}
                        </Typography>
                        <IconButton onClick={(event) => {
                            handleDeleteLuonne(event, Luonne);
                            if (selectedLuonne && selectedLuonne.id === Luonne.id) {
                                setSelectedLuonne(null);
                                setNewLuonneData({ luonne: '' });
                            }
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                    {selectedLuonne && selectedLuonne.id === Luonne.id && (
                        <Grid container>

                            <Grid item xs={8} sx={{ pt: 2 }}>
                                <FormTextField

                                    fieldName='luonne'
                                    label='luonne'
                                    multiline={true}
                                    fullWidth={true}

                                    value={newLuonneData.luonne}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setNewLuonneData((prevState) => ({ ...prevState, [name]: value }));
                                    }}

                                />
                            </Grid>


                            <UpdateButton handleUpdate={handleUpdateLuonne} data={newLuonneData}/>
                        </Grid>
                    )}
                </Grid>
            ))}
            {showAddForm && (
                <Box sx={{
                    flexGrow: 1, // Add flexGrow to make the box grow
                }}>
                    <Typography variant="h6" sx={{ pt: 2 }}>Luonteen lisääminen</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <FormTextField
                                key="addLuonneLuonne"
                                fieldName='luonne'
                                label='luonne'
                                multiline={true}
                                fullWidth={true}

                                value={addLuonneData.luonne}
                                onChange={(event) => setAddLuonneData({ ...addLuonneData, luonne: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                        </Grid>

                        <Box display="flex" justifyContent="flex-end" width="100%" pt={1}>

                            <Button
                                onClick={() => handleLisääLuonne(addLuonneData)}
                                size="small"
                                variant="contained"
                                startIcon={<AddIcon />}
                                sx={{ backgroundColor: 'taphaGreen.main' }}
                            >
          Lisää luonne
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            )}
            {showAddForm ? (
                <Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={handleAddClick}
                        size='small'
                        variant='contained'
                        startIcon={<CloseIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Sulje
                    </Button>
                </Box>
            ) :
                (<Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={handleAddClick}
                        size='small'
                        variant='contained'
                        startIcon={<AddIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Lisää
                    </Button>
                </Box>)}
            {/* Pagination arrows */}
            <Box mt={2} ml={2} display="flex" flexDirection="column" alignItems="center">
                <PaginationInfo
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    filteredArray={filteredLuonteet}
                    array={luonteet}
                />
                <Box display="flex" justifyContent="center" width="100%">
                    <Box width="740px" display="flex" justifyContent="center"> {/* Centered box with a specified width */}
                        <IconButton
                            size="small"
                            onClick={handlePreviousPage}
                            disabled={currentPage <= 1}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={handleNextPage}
                            disabled={currentPage >= totalPages}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
}

export default LuonteetList;