import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@mui/material';
import Select from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';

const CustomSelectIcon = (props) => {
    return (
        <Select.Icon {...props}>
            <ExpandMoreIcon sx={{ transform: 'rotate(90deg)' }} />
        </Select.Icon>
    );
};

const DropdownFilterVastuuhenkilo = ({ options, label, onChange, value, placeholdervastuuhenkilo, ...otherProps }) => {
    const [selectedValue, setSelectedValue] = useState('');

    useEffect(() => {
        if (options.includes(placeholdervastuuhenkilo)) {
            setSelectedValue(placeholdervastuuhenkilo);
            onChange({ target: { name: 'vastuuhenkilo', value: placeholdervastuuhenkilo } });
        } else {
            setSelectedValue(value || ''); // Set the default value if placeholdervastuuhenkilo is not in options
            onChange({ target: { name: 'vastuuhenkilo', value: value || '' } }); // Trigger onChange with default value
        }

    }, [placeholdervastuuhenkilo]);

    const handleChange = (event) => {
        const newValue = event?.target?.value === placeholdervastuuhenkilo ? placeholdervastuuhenkilo : event?.target?.value;
        setSelectedValue(newValue);

        onChange({ target: { name: 'vastuuhenkilo', value: newValue } }); // Pass the new value to the onChange handler
    };


    return (
        <TextField {...otherProps}
            select
            label={label}
            onChange={handleChange}
            value={selectedValue}
            defaultValue={otherProps.placeholdervastuuhenkilo}>
            <MenuItem value=''>-</MenuItem>
            {options?.map(option =>
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            )}
        </TextField>
    );
};

DropdownFilterVastuuhenkilo.propTypes = {
    options: PropTypes.array,
    label: PropTypes.string,
    onChange: PropTypes.func,
    otherProps: PropTypes.object,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default DropdownFilterVastuuhenkilo;