import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import {
    Alert,
    Button,
    Box,
    List,
    ListItem,
    ListItemSecondaryAction,
    IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { useGetKustannuspaikatQuery } from '../../../../../api/kustannuspaikatApiSlice';
import BillFields from './BillFields';

const BillsArray = ({ fieldName }) => {
    const { fields, append, remove } = useFieldArray({ name: fieldName });

    const { data: kustannuspaikat } = useGetKustannuspaikatQuery();
    const kustannuspaikatArray = kustannuspaikat ? Object.values(kustannuspaikat.entities) : [];

    const objectFormat = {
        maksava_taho: '',
        laskutusosoite: '',
        tilaajan_viite: '',
        saa_laskuttaa_vasta: '',
        laskutyyppi: '',
        lisatiedot: '',
        summa: '',
        kustannuspaikka: '' // Add the kustannuspaikka field
    };

    const listFields = fields.map((field, index) => (
        <ListItem
            disableGutters
            key={field.id}
            sx={{
                mb: 2,
                my: 3,
                p: 2,
            }}>
            <BillFields fieldName={fieldName} fieldIndex={index} kustannuspaikatArray={kustannuspaikatArray} />
            <ListItemSecondaryAction sx={{ mr: -4 }}>
                <IconButton
                    edge='end'
                    aria-label='delete'
                    onClick={() => remove(index)}
                >
                    <ClearIcon sx={{ color: 'taphaBlue.dark' }} />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    ));

    return (
        <>
            <List
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}>
                {listFields}
                {!listFields.length && (
                    <Alert severity='info' sx={{ width: '100%', borderRadius: 3 }}>
                        Raportilla ei vielä ole laskuja
                    </Alert>
                )}
            </List>
            <Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                <Button
                    onClick={() => append(objectFormat)}
                    size='small'
                    variant='contained'
                    startIcon={<AddIcon />}
                    sx={{ backgroundColor: 'taphaBlue.main' }}
                >
                    Lisää lasku
                </Button>
            </Box>
        </>
    );
};

BillsArray.propTypes = {
    fieldName: PropTypes.string.isRequired
};

export default BillsArray;