import React, { useState } from 'react';
import {
    Box,
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
} from '@mui/material';
import FormTextField from '../../../../components/FormFields/FormTextField';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { useAddNewPartnerMutation } from '../../../../api/partnersApiSlice';
import { selectAllRegionNames } from '../../../../api/regionsApiSlice';
const YhteistyotahotForm = ({ initialValues, onSuccess, onError }) => {
    const [formData, setFormData] = useState(initialValues);
    const [showForm, setShowForm] = useState(false);
    const [yhttahoData, setYhttahoData ] = useState({ tyyppi: '', nimi: '', maakunta: '' });
    const regions = useSelector(selectAllRegionNames);
    const tyypit = ['Seurakunta', 'Muu taho', 'Koulutuskeskus'];
    const [addYhttaho, { isLoading }] = useAddNewPartnerMutation();

    const handleLisääYhttaho = async(Yhttaho) => {

        try {
            const result = await addYhttaho(Yhttaho);
            if(result.error) onError('Yhteistyötahon luominen epäonnistui');
            if(!result.error) onSuccess('Yhteistyötahon luominen onnistui.', 'yhteistyotahot', result.data.id);
            setYhttahoData({ tyyppi: '', nimi: '', maakunta: '' });
            setShowForm(false);
        } catch (error) {
            console.error(error); // handle error
        }};

    return (
        <Box
            sx={{
                flexGrow: 1,
            }}
        >
            {showForm && (
                <Grid container spacing={2}>
                    <Typography variant="h6" sx={{ pt: 2, pl: 2 }}>
                Yhteistyötahon lisääminen
                    </Typography>
                    <Grid item xs={12} sx={{ width: '100%', mb: -2 }}>
                        <FormTextField

                            fieldName='yhttahonimi'
                            label='nimi'
                            multiline={true}
                            fullWidth={true}

                            value={yhttahoData.nimi}
                            onChange={(event) => {
                                const { value } = event.target;
                                setYhttahoData((prevState) => ({ ...prevState, nimi: value }));
                            }}

                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="tyyppi-label">tyyppi</InputLabel>
                            <Select
                                labelId="tyyppiId"
                                id="tyyppi-select"
                                label="tyyppi"
                                value={yhttahoData.tyyppi}
                                onChange={(event) => {
                                    const { value } = event.target;


                                    setYhttahoData((prevState) => ({ ...prevState, 'tyyppi': value }));

                                }}

                            >
                                {tyypit.map((tyyppi) => (
                                    <MenuItem key={tyyppi} value={tyyppi}>
                                        {tyyppi}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ pt: 2 }}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="maakunta-label">maakunta</InputLabel>
                            <Select
                                labelId="maakuntaId"
                                id="maakunta-select"
                                value={yhttahoData.maakunta}
                                label="maakunta"
                                onChange={(event) => {
                                    const { value } = event.target;


                                    setYhttahoData((prevState) => ({ ...prevState, 'maakunta': value }));

                                }}

                            >
                                {regions.map((region) => (
                                    <MenuItem key={region} value={region}>
                                        {region}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Box display="flex" justifyContent="flex-end" width="100%" pt={1}>
                        <Button
                            onClick={() => handleLisääYhttaho(yhttahoData)}
                            size="small"
                            variant="contained"
                            startIcon={<AddIcon />}
                            sx={{ backgroundColor: 'taphaGreen.main' }}
                        >
                        Tallenna
                        </Button>
                    </Box>
                </Grid>
            )}
            {showForm ? (
                <Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={() => setShowForm(!showForm)}
                        size='small'
                        variant='contained'
                        startIcon={<CloseIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Sulje
                    </Button>
                </Box>
            ) :
                (<Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={() => setShowForm(!showForm)}
                        size='small'
                        variant='contained'
                        startIcon={<AddIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Lisää yhteistyötaho
                    </Button>
                </Box>)}
        </Box>
    );
};

export default YhteistyotahotForm;