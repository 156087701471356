import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';

// Very similar to FormDatePicker.js. The only difference is that this one
// isn't controlled with RHF.

const DatePicker = ({ value, onChange, format, ...otherProps }) => {

    const datePickerStyle = {
        components: {
            OpenPickerIcon: CalendarMonthOutlinedIcon,
            LeftArrowIcon: ArrowLeftOutlinedIcon,
            RightArrowIcon: ArrowRightOutlinedIcon
        },
        InputProps: {
            sx: { '& .MuiSvgIcon-root': { color: 'taphaBlue.main' } }
        },
        PopperProps: {
            sx: {
                '& .MuiPaper-root': {
                    border: '1px solid taphaBlue.main',
                    p: 2,
                    mt: 1
                }
            }
        }
    };


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
                { ...datePickerStyle }
                label='Päivämäärä'
                inputFormat={format}
                value={value}
                onChange={onChange}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        { ...otherProps }
                        inputProps={{
                            ...params.inputProps,
                            placeholder: 'pp.kk.vvvv'
                        }}
                    />
                }
            />
        </LocalizationProvider>
    );
};

export default DatePicker;