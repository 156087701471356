import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import store from '../../app/store';
import { eventTypesApiSlice } from '../../api/eventTypesApiSlice';
import { placesApiSlice } from '../../api/placesApiSlice';
import { regionsApiSlice } from '../../api/regionsApiSlice';
import { audiencesApiSlice } from '../../api/audiencesApiSlice';
import { partnersApiSlice } from '../../api/partnersApiSlice';
import { rolesApiSlice } from '../../api/rolesApiSlice';
import { peopleApiSlice } from '../../api/peopleApiSlice';
import { centersApiSlice } from '../../api/centersApiSlice';

const Prefetch = () => {

    // Use RTK's prefetch utility to prefetch the data.
    // The data should be prefetched right after a successful login or refresh.

    useEffect(() => {
        store.dispatch(eventTypesApiSlice.util.prefetch(
            'getEventTypes', 'EventTypesList', { force: true }
        ));
        store.dispatch(placesApiSlice.util.prefetch(
            'getPlaces', 'PlacesList', { force: true }
        ));
        store.dispatch(regionsApiSlice.util.prefetch(
            'getRegions', 'RegionsList', { force: true }
        ));
        store.dispatch(audiencesApiSlice.util.prefetch(
            'getAudiences', 'AudiencesList', { force: true }
        ));
        store.dispatch(partnersApiSlice.util.prefetch(
            'getPartners', 'PartnersList', { force: true }
        ));
        store.dispatch(rolesApiSlice.util.prefetch(
            'getRoles', 'RolesList', { force: true }
        ));
        store.dispatch(peopleApiSlice.util.prefetch(
            'getPeople', 'PeopleList', { force: true }
        ));
        store.dispatch(centersApiSlice.util.prefetch(
            'getCenters', 'CentersList', { force: true }
        ));
    }, []);

    return <Outlet />;
};

export default Prefetch;