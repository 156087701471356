import Authentication from '../features/authentication';
import useDocumentTitle from '../hooks/useDocumentTitle';

// A page for displaying the Authentication feature.
// Should be routed to /login-page.

const LoginPage = ({ documentTitle }) => {

    useDocumentTitle(documentTitle);

    return <Authentication />;
};

export default LoginPage;