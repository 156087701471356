import * as Yup from 'yup';
import dayjs from 'dayjs';

export const validationSchema = Yup.object().shape({
    nimi: Yup.string().required('Pakollinen')
        .min(5, 'Vähintään 5 merkkiä pitkä'),
    aloitus: Yup.object().shape({
        pvm: Yup.string().nullable().required('Pakollinen'),
        aika: Yup.string().nullable().required('Pakollinen')
    }),
    lopetus: Yup.object().shape({
        pvm: Yup.string().nullable().required('Pakollinen')
            .test({
                name: 'LopetusIsValid',
                exclusive: false,
                params: {},
                message: 'Tapahtuman lopetus ei voi olla ennen sen aloitusta',
                test: function (_, testContext) {
                    // This compares the times as well, even though the resulting
                    // error is for the datePicker only.

                    const { aloitus, lopetus } = testContext.options.context;

                    const startTime = dayjs(aloitus.aika);
                    const start = dayjs(aloitus.pvm)
                        .set('hour', startTime.hour())
                        .set('minute', startTime.minute());

                    const endTime = dayjs(lopetus.aika);
                    const end = dayjs(lopetus.pvm)
                        .set('hour', endTime.hour())
                        .set('minute', endTime.minute());

                    return end.isAfter(start);
                }
            }),
        aika: Yup.string().nullable().required('Pakollinen')
    }),
    luonteet: Yup.array()
        .max(5, 'Tapahtumalla voi olla enintään viisi luonnetta'),
    kohdeyleiso: Yup.array()
        .max(5, 'Tapahtumalla voi olla enintään viisi kohdeyleisöä'),
    kuvaus: Yup.string()
    ,
    vastuuhenkilot: Yup.array().of(
        Yup.object().shape({
            henkilo: Yup.string().required('Pakollinen')
        })
    ),
    mukanaolevat: Yup.array().of(
        Yup.object().shape({
            henkilo: Yup.string().required('Pakollinen'),
            rooli: Yup.string().required('Pakollinen'),
            julkinen: Yup.string().required('Pakollinen')
        })
    ),
    musiikki: Yup.array().of(
        Yup.object().shape({
            kappale: Yup.string().required('Pakollinen'),
            artisti: Yup.string().required('Pakollinen')
        })
    ),
    paikka: Yup.object().shape({
        keskus: Yup.string().required('Pakollinen'),
        nimi: Yup.string().when('keskus', {
            is: (keskus) => keskus !== 'Ystävämatkat',
            then: (schema) => schema.required('Pakollinen')
                .min(8, 'Vähintään 8 merkkiä pitkä')
        }),
        osoite: Yup.string().when('keskus', {
            is: (keskus) => keskus !== 'Ystävämatkat',
            then: (schema) => schema.required('Pakollinen')
                .min(8, 'Vähintään 8 merkkiä pitkä')
        }),
        postinumero: Yup.string().when('keskus', {
            is: (keskus) => keskus !== 'Ystävämatkat',
            then: (schema) => schema.required('Pakollinen')
                .matches(/^[0-9]+$/, 'Vain numeroita')
                .length(5, 'Postinumeron tulee olla 5 merkkiä pitkä'),
        }),
        postitoimipaikka: Yup.string().when('keskus', {
            is: (keskus) => keskus !== 'Ystävämatkat',
            then: (schema) => schema.required('Pakollinen')
                .min(2, 'Vähintään 2 merkkiä pitkä')
        }),
        kunta: Yup.string().when('keskus', {
            is: (keskus) => keskus !== 'Ystävämatkat',
            then: (schema) => schema.required('Pakollinen')
                .min(2, 'Vähintään 2 merkkiä pitkä'),
        }),
        maakunta: Yup.string().when('keskus', {
            is: (keskus) => keskus !== 'Ystävämatkat',
            then: (schema) => schema.required('Pakollinen')
                .min(4, 'Vähintään 4 merkkiä pitkä'),
        }),
        kuvaus: Yup.string().when('keskus', {
            is: (keskus) => keskus === 'Ystävämatkat',
            then: (schema) => schema.required('Pakollinen')
                .min(30, 'Vähintään 30 merkkiä pitkä')
        })
    }),
    ohjelma: Yup.array().of(
        Yup.object().shape({
            pvm: Yup.string().required('Pakollinen'),
            tilaisuudet: Yup.array().of(
                Yup.object().shape({
                    aloitus: Yup.string().nullable().required('Pakollinen').test(
                        'isValidAloitusLopetus',
                        'Aloitusaika on oltava ennen lopetusaikaa',
                        function (value) {
                            const lopetus = this.parent.lopetus;
                            if (!value || (!lopetus || lopetus === 'Invalid Date')) return true; // Allow if either value is missing.
                            return value < lopetus;
                        }
                    ),
                    lopetus: Yup.string().nullable().test(
                        'isValidAloitusLopetus',
                        'Lopetusaika on oltava aloitusajan jälkeen',
                        function (value) {
                            const aloitus = this.parent.aloitus;
                            if ((!value || value === 'Invalid Date') || !aloitus) return true; // Allow if either value is missing.
                            return value > aloitus;
                        }
                    ),
                    nimi: Yup.string().required('Pakollinen')
                        .min(5, 'Tulee olla 5 merkkiä pitkä'),
                    henkilot: Yup.array().of(
                        Yup.object().shape({
                            henkilo: Yup.string().required('Pakollinen'),
                            titteli: Yup.string().required('Pakollinen')
                        })
                    )
                })
            )
        })
    ),
    status: Yup.string().required('Pakollinen'),
    lahetys: Yup.array().required('Pakollinen'),
});

export const defaultValues = {
    nimi: '',
    aloitus: {
        pvm: null,
        aika: null
    },
    lopetus: {
        pvm: null,
        aika: null
    },
    luonteet: [],
    kohdeyleiso: [],
    kuvaus: '',
    lisatiedot: '',
    paikka: {
        id: '',
        kunta: '',
        maakunta: '',
        nimi: '',
        osoite: '',
        postinumero: '',
        postitoimipaikka: '',
        keskus: ''
    },
    lyytilinkit: {
        ilmoittautumislinkki: '',
        eta_ilmoittautumislinkki: ''
    },
    vastuuhenkilot: [],
    mukanaolevat: [],
    musiikki: [],
    yhteistyotahot: [],
    kuvat: [],
    lahetys: ['ei lahetysta'],
    status: 'luonnos',
    ohjelma: [],
    kansanopistokurssi: false,
    lastEditedTimestamp: null
};

export const convertDataToFormSchema = (data) => {

    const { aloitus, lopetus, ohjelma, kuvat, ...rest } = data;

    const eventStart = dayjs(aloitus, 'DD.MM.YYYY HH:mm');
    const eventEnd = dayjs(lopetus, 'DD.MM.YYYY HH:mm');

    const ohjelmaToForm = ohjelma.map(ohjelma => ({
        ...ohjelma,
        tilaisuudet: ohjelma.tilaisuudet.map(tilaisuus => ({
            ...tilaisuus,
            aloitus: dayjs(tilaisuus.aloitus, 'HH:mm'),
            lopetus: dayjs(tilaisuus.lopetus, 'HH:mm'),
            henkilot: tilaisuus.henkilot.map(henkilo => ({
                ...henkilo,
                titteli: henkilo.titteli === true ? 'Kyllä' : 'Ei'
            }))
        }))
    }));

    return {
        ...rest,
        aloitus: {
            pvm: eventStart,
            aika: eventStart
        },
        lopetus: {
            pvm: eventEnd,
            aika: eventEnd
        },
        ohjelma: ohjelmaToForm,
        kuvat: kuvat
    };
};


export const convertDataToAPISchema = (data) => {

    const { lastEditedTimestamp, ...event } = data;


    event.ohjelma = data.ohjelma.map(ohjelma => ({
        ...ohjelma,
        tilaisuudet: ohjelma.tilaisuudet.map(tilaisuus => {
            tilaisuus.aloitus = dayjs(tilaisuus.aloitus).format('HH:mm');
            tilaisuus.lopetus = dayjs(tilaisuus.lopetus).format('HH:mm');
            tilaisuus.henkilot = tilaisuus.henkilot.map(henkilo => ({
                ...henkilo,
                titteli: henkilo.titteli === 'Kyllä' ? true : false
            }));
            return tilaisuus;
        })
    }));

    event.lastEditedTimestamp = lastEditedTimestamp;

    // Parse both Day.js objects into a formatted date string for the db.
    // This string will be used in the backend to form a JS date for the DB.

    const startDate = dayjs(data.aloitus.pvm);
    const startTime = dayjs(data.aloitus.aika);
    event.aloitus = startDate
        .set('hour', startTime.hour())
        .set('minute', startTime.minute())
        .format('DD.MM.YYYY HH:mm');

    const endDate = dayjs(data.lopetus.pvm);
    const endTime = dayjs(data.lopetus.aika);
    event.lopetus = endDate
        .set('hour', endTime.hour())
        .set('minute', endTime.minute())
        .format('DD.MM.YYYY HH:mm');

    const { kuvat } = event;

    const newImages = kuvat.filter(img => img.savedToServer === false);
    const oldImages = kuvat.filter(img => img.savedToServer === true);

    event.kuvat = oldImages;


    const formData = new FormData();

    newImages.forEach((image, index) => {

        formData.append('kuvat', image.file);
        formData.append(`kuvat[${index}].bannerImage`, image.bannerImage);
    });


    formData.append('eventData', JSON.stringify(event));


    return formData;
};