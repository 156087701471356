import { createEntityAdapter } from '@reduxjs/toolkit';

import { apiSlice } from './apiSlice';

const bugiraportitAdapter = createEntityAdapter({
    selectId: (bugiraportti) => bugiraportti.id
});
const initialState = bugiraportitAdapter.getInitialState();

export const bugiraportitApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addNewBugiraportti: builder.mutation({
            query: ({ newBugiraportti, currentUser }) => ({
                url: '/bugiraportit',
                method: 'POST',
                body: { ...newBugiraportti, currentUser }
            }),
            invalidatesTags: ['Bugiraportit'],
            onQueryStarted: (_, { dispatch }) => {
                dispatch(bugiraportitApiSlice.util.invalidateTags('Bugiraportit'));
            }, onQueryCompleted: (_, { dispatch }) => {
                dispatch(bugiraportitApiSlice.endpoints.getTiedotteet.initiate());
            }
        }),
    })
});

export const {
    useAddNewBugiraporttiMutation,
} = bugiraportitApiSlice;