import { createSlice } from '@reduxjs/toolkit';

// Action creators for authentication.

const authSlice = createSlice({
    name: 'auth',
    initialState: { accessToken: null, user: null },
    reducers: {
        setAccessToken: (state, action) => {
            const { accessToken } = action.payload;
            state.accessToken = accessToken;
        },
        setUser: (state, action) => {
            const { user } = action.payload;
            state.user = user;
        },
        logOut: (state, action) => {
            state.accessToken = null;
            state.user = null;
        }
    }
});

export default authSlice;

export const { setAccessToken, setUser, logOut } = authSlice.actions;

export const selectCurrentAccessToken = (state) => state.auth.accessToken;
export const selectCurrentUser = (state) => state.auth.user;