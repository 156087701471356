import { useState } from 'react';
import { Box, Button } from '@mui/material';

const ImageSlider = ({ images }) => {

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handlePrevClick = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const handleNextClick = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const showPrevButton = images.length > 1 && currentImageIndex > 0;
    const showNextButton = images.length > 1 && currentImageIndex < images.length - 1;

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '100%',
                maxHeight: '400px',
                minHeight: '400px',
                backgroundColor: '#f5f5f5',
                padding: '1rem',
                borderRadius: '1rem',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                overflow: 'hidden',
                position: 'relative',
            }}
        >
            {showPrevButton && (
                <Button
                    sx={{
                        zIndex: 999,
                        position: 'absolute',
                        top: '50%',
                        left: 0,
                        transform: 'translateY(-50%)',
                        backgroundColor: 'transparent',
                        border: 'none',
                        fontSize: '2rem',
                        minWidth: 'auto',
                        color: '#333',
                        '&:hover': { color: '#555' },
                    }}
                    onClick={handlePrevClick}
                >
                    {'<'}
                </Button>
            )}
            {showNextButton && (
                <Button
                    disableRipple
                    sx={{
                        zIndex: 999,
                        position: 'absolute',
                        top: '50%',
                        right: 0,
                        transform: 'translateY(-50%)',
                        border: 'none',
                        fontSize: '2rem',
                        color: 'taphaBlue.dark',
                        '&:hover': { color: 'taphaBlue.main' },
                    }}
                    onClick={handleNextClick}
                >
                    {'>'}
                </Button>
            )}
            <Box
                sx={{
                    backgroundImage: `url(${images[currentImageIndex]?.src})`,
                    height: '400px',
                    width: '100%',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            ></Box>
        </Box>
    );
};

export default ImageSlider;