import { useState } from 'react';
import { Grid, Typography, Box, IconButton, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormTextField from '../../components/FormFields/FormTextField';
import { useAddNewPlaceMutation  } from '../../api/placesApiSlice';
import RenderedItem from './adminUtils/renderedItem';
import { paikkaFields } from './adminUtils/fields';
import { selectAllRegionNames } from '../../api/regionsApiSlice';
import { selectMunicipalitiesByRegion } from '../../api/regionsApiSlice';
import UpdateButton from './adminUtils/updateButton';
import SearchBar from './adminUtils/SearchBar';
import { selectAllCenterNames, selectAllCenters } from '../../api/centersApiSlice';
import PaginationInfo from './PaginationInfo';

function PlacesList({ places, handleDeletePlace, handleUpdatePlace, fetchData, onSuccess, onError }) {
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [newPlaceData, setNewPlaceData] = useState({ keskus: '', nimi: '', osoite: '', maakunta: '', kunta: '', postitoimipaikka: '', postinumero: '' });
    const [addPlaceData, setAddPlaceData] = useState({ keskus: '', nimi: '', osoite: '', maakunta: '', kunta: '', postitoimipaikka: '', postinumero: '' });
    const centers = useSelector(selectAllCenterNames);

    const [showAddForm, setShowAddForm] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [addNewPlace, { isLoading }] = useAddNewPlaceMutation();
    const fields = paikkaFields;
    const regionNames = useSelector(selectAllRegionNames);
    const regions = useSelector(selectMunicipalitiesByRegion);

    const municipalitiesByRegion = regions.reduce((acc, region) => {
        const municipalities = region.kunnat.map(kunta => kunta.nimi);
        return { ...acc, [region.nimi]: municipalities };
    }, {});

    const [searchTerm, setSearchTerm] = useState('');

    const filteredPlaces = places.filter((Place) =>
        Place.nimi.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const totalPages = Math.ceil(filteredPlaces.length / itemsPerPage);



    const handleSearchChange = (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
        setCurrentPage(1); // Reset to the first page when searching
    };

    // Apply the search filter

    // Get the slice of luonteet for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedPlaces = filteredPlaces.slice(startIndex, endIndex);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleUserClick = (Place) => {
        if(selectedPlace?.id === Place.id) {setSelectedPlace(null);
            setNewPlaceData({ keskus: '', nimi: '', osoite: '', maakunta: '', kunta: '', postitoimipaikka: '', postinumero: '' });
            return;}
        setSelectedPlace(Place);
        setNewPlaceData(Place);
    };

    const handleAddClick = () => {
        setShowAddForm(!showAddForm);
    };

    const handleLisääPlace = async(Place) => {
        try {
            const result = await addNewPlace(Place);

            if(result.error) onError('Paikan luominen epäonnistui');
            if(!result.error) onSuccess('Paikan luominen onnistui.', 'paikat', result.data.id);
            setAddPlaceData({ keskus: '', nimi: '', osoite: '', maakunta: '', kunta: '', postitoimipaikka: '', postinumero: '' });
            setShowAddForm(false);
            fetchData();
        } catch (error) {
            console.error(error); // handle error
        }};


    return (
        <Grid container spacing={2}>
            <Box mt={3} ml={2}> {/* Add spacing to separate SearchBar */}
                <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
            </Box>
            {displayedPlaces.map((Place, index) => (
                <Grid key={Place.id} item xs={12} >
                    <Box key={index} onClick={() => handleUserClick(Place)} sx={{
                        backgroundColor: 'taphaWhite.main',
                        borderRadius: 5,

                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginTop: 0.6,
                        gap: 5,
                        '&:hover': {
                            border: '1px solid',
                            borderLeft: '30px solid',
                            transition: '0.4s',
                            borderColor: 'taphaGreen.main',
                            cursor: 'pointer'
                        }
                    }}>
                        <Typography variant="h6">
                            {Place.nimi}
                        </Typography>
                        <IconButton onClick={(event) => {
                            handleDeletePlace(event, Place);
                            if (selectedPlace && selectedPlace.id === Place.id) {
                                setSelectedPlace(null);
                                setNewPlaceData({ keskus: '', nimi: '', osoite: '', maakunta: '', kunta: '', postitoimipaikka: '', postinumero: '' });
                            }
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                    {selectedPlace && selectedPlace.id === Place.id && (
                        <Grid container>

                            <Grid item xs={8} sx={{ pt: 2 }}>
                                <FormTextField

                                    fieldName='nimi'
                                    label='nimi'
                                    multiline={true}
                                    fullWidth={true}

                                    value={newPlaceData.nimi}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setNewPlaceData((prevState) => ({ ...prevState, [name]: value }));
                                    }}

                                />
                                <Grid item xs={8} sx={{ pt: 2, pb: 2 }}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="keskus-label">keskus</InputLabel>
                                        <Select
                                            labelId="keskusId"
                                            id="keskus-select"
                                            value={newPlaceData.keskus}
                                            label="keskus"
                                            onChange={(event) => {
                                                const { value } = event.target;


                                                setNewPlaceData((prevState) => ({ ...prevState, 'keskus': value }));


                                            }}
                                        >
                                            {centers.map((center) => (
                                                <MenuItem key={center} value={center}>
                                                    {center}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <FormTextField

                                    fieldName='osoite'
                                    label='osoite'
                                    multiline={true}
                                    fullWidth={true}

                                    value={newPlaceData.osoite}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setNewPlaceData((prevState) => ({ ...prevState, [name]: value }));
                                    }}

                                /><Grid item xs={8} sx={{ pt: 2, pb: 2 }}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="maakunta-label">maakunta</InputLabel>
                                        <Select
                                            labelId="maakuntaId"
                                            id="maakunta-select"
                                            value={newPlaceData.maakunta}
                                            label="maakunta"
                                            onChange={(event) => {
                                                const { value } = event.target;


                                                setNewPlaceData((prevState) => ({ ...prevState, 'maakunta': value }));


                                            }}
                                        >
                                            {regionNames.map((region) => (
                                                <MenuItem key={region} value={region}>
                                                    {region}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid><Grid item xs={8} sx={{ pt: 2, pb: 2 }}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="kunta-label">kunta</InputLabel>
                                        <Select
                                            labelId="kuntaId"
                                            id="kunta-select"
                                            value={newPlaceData.kunta}
                                            label="kunta"
                                            onChange={(event) => {
                                                const { value } = event.target;


                                                setNewPlaceData((prevState) => ({ ...prevState, 'kunta': value }));


                                            }}
                                        >
                                            {municipalitiesByRegion[newPlaceData.maakunta].map((kunta) => (
                                                <MenuItem key={kunta} value={kunta}>
                                                    {kunta}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid><FormTextField

                                    fieldName='postitoimipaikka'
                                    label='postitoimipaikka'
                                    multiline={true}
                                    fullWidth={true}

                                    value={newPlaceData.postitoimipaikka}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setNewPlaceData((prevState) => ({ ...prevState, [name]: value }));
                                    }}

                                /><FormTextField

                                    fieldName='postinumero'
                                    label='postinumero'
                                    multiline={true}
                                    fullWidth={true}

                                    value={newPlaceData.postinumero}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setNewPlaceData((prevState) => ({ ...prevState, [name]: value }));
                                    }}

                                />
                            </Grid>

                            <UpdateButton handleUpdate={handleUpdatePlace} data={newPlaceData}/>
                        </Grid>
                    )}
                </Grid>
            ))}
            {showAddForm && (
                <Box sx={{
                    flexGrow: 1, // Add flexGrow to make the box grow
                }}>
                    <Typography variant="h6" sx={{ pt: 2 }}>Paikan lisääminen</Typography>
                    <Grid container spacing={2}>
                        {
                            fields.map((fieldName) => (
                                fieldName === 'maakunta' ? (
                                    <Grid item xs={8} sx={{ pt: 2, pb: 2 }} key={fieldName}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputLabel id="maakuntaAdd-label">maakunta</InputLabel>
                                            <Select
                                                labelId="maakuntaAddId"
                                                id="maakuntaAdd-select"
                                                value={addPlaceData.maakunta}
                                                label="maakunta"
                                                onChange={(event) => {
                                                    const { value } = event.target;


                                                    setAddPlaceData((prevState) => ({ ...prevState, 'maakunta': value }));


                                                }}
                                            >
                                                {regionNames.map((region) => (
                                                    <MenuItem key={region} value={region}>
                                                        {region}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                ) : fieldName === 'kunta' ? (<Grid item xs={8} sx={{ pt: 2, pb: 2 }} key={fieldName}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="kuntaAdd-label">kunta</InputLabel>
                                        <Select
                                            labelId="kuntaAddId"
                                            id="kuntaAdd-select"
                                            value={addPlaceData.kunta}
                                            label="kunta"
                                            onChange={(event) => {
                                                const { value } = event.target;


                                                setAddPlaceData((prevState) => ({ ...prevState, 'kunta': value }));


                                            }}
                                        >
                                            {municipalitiesByRegion[addPlaceData.maakunta]?.map((kunta) => (
                                                <MenuItem key={kunta} value={kunta}>
                                                    {kunta}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>) : fieldName === 'keskus' ? (<Grid item xs={8} sx={{ pt: 2, pb: 2 }} key={fieldName}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="addkeskus-label">keskus</InputLabel>
                                        <Select
                                            labelId="addkeskusId"
                                            id="addkeskus-select"
                                            value={addPlaceData.keskus}
                                            label="keskus"
                                            onChange={(event) => {
                                                const { value } = event.target;


                                                setAddPlaceData((prevState) => ({ ...prevState, 'keskus': value }));


                                            }}
                                        >
                                            {centers.map((center) => (
                                                <MenuItem key={center} value={center}>
                                                    {center}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>) :
                                    (<RenderedItem
                                        key={`${fieldName}`}
                                        nameVal={fieldName}
                                        valFirst={'addPlaceData'}
                                        settingFunction={setAddPlaceData}
                                    />)
                            ))
                        }


                        <Box display="flex" justifyContent="flex-end" width="100%" pt={1}>

                            <Button
                                onClick={() => handleLisääPlace(addPlaceData)}
                                size="small"
                                variant="contained"
                                startIcon={<AddIcon />}
                                sx={{ backgroundColor: 'taphaGreen.main' }}
                            >
          Lisää paikka
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            )}
            {showAddForm ? (
                <Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={handleAddClick}
                        size='small'
                        variant='contained'
                        startIcon={<CloseIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Sulje
                    </Button>
                </Box>
            ) :
                (<Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={handleAddClick}
                        size='small'
                        variant='contained'
                        startIcon={<AddIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Lisää
                    </Button>
                </Box>)}
            {/* Pagination arrows */}
            <Box mt={2} ml={2} display="flex" flexDirection="column" alignItems="center">
                <PaginationInfo
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    filteredArray={filteredPlaces}
                    array={places}
                />
                <Box display="flex" justifyContent="center" width="100%">
                    <Box width="740px" display="flex" justifyContent="center"> {/* Centered box with a specified width */}
                        <IconButton
                            size="small"
                            onClick={handlePreviousPage}
                            disabled={currentPage <= 1}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={handleNextPage}
                            disabled={currentPage >= totalPages}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
}

export default PlacesList;