import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Button,
    CircularProgress,
    Container,
    Grid,
    Paper,
    Typography,
    IconButton,
    FormControlLabel,
    Checkbox,
    Box,
    Alert
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { initial, useDebounce } from 'lodash';
import { useAddNewEventMutation, useEditEventMutation } from '../../../../api/eventsApiSlice';
import { useDeleteImagesMutation } from '../../../../api/imagesApiSlice';
import { selectAllEventTypes } from '../../../../api/eventTypesApiSlice';
import { selectAllAudiences } from '../../../../api/audiencesApiSlice';
import { selectAllPartners } from '../../../../api/partnersApiSlice';
import { selectAllPeopleNames } from '../../../../api/peopleApiSlice';
import { selectAllRoles } from '../../../../api/rolesApiSlice';
import { selectAllCenters } from '../../../../api/centersApiSlice';
import AddIcon from '@mui/icons-material/Add';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import ProgressLoader from '../../../../components/ProgressLoader';
import MessageSnackbar from '../../../../components/MessageSnackbar';
import FormDatePicker from '../../../../components/FormFields/FormDatePicker';
import FormTextField from '../../../../components/FormFields/FormTextField';
import FormListField from './FormListField';
import FormListSelect from './FormListSelect';
import FormSelectMultiple from './FormSelectMultiple';
import FormTimePicker from './FormTimePicker';
import FormRadioGroup from './FormRadioGroup';
import FormNotes from './FormNotes';
import ImageUploader from './ImageUploader/ImageUploader';
import Places from './Places/Places';
import Schedule from './Schedule';
import { useGetPlacesQuery } from '../../../../api/placesApiSlice';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
    validationSchema,
    defaultValues,
    convertDataToAPISchema,
    convertDataToFormSchema
} from './eventFormSchemas';
import FormCheckbox from './FormCheckbox';
import MusicSelect from '../MusicSelect';
import Vastuuhenkilöt from '../VastuuhenkilötSelect';
import YhteistyotahotForm from './YhteistyötahoLisäysComponent';
import HenkilotAddForm from './henkilöLisäysComponent';
import MukanaOlevat from './MukanaOlevat';
import RichTextEditor from '../../../../components/RichTextEditor';
import debounce from 'lodash/debounce';
import HelpIconWithTooltip from '../../../../components/HelpIconWithTooltip';
import { useAddNewLokiMutation } from '../../../../api/lokiSlice';
import { selectCurrentUser } from '../../../authentication/authSlice';
import FormCheckboxGroup from './FormCheckboxGroup';




const EventForm = ({ event, isEditing, documentTitle }) => {


    useDocumentTitle(documentTitle);

    const copiedSessionValues = sessionStorage.getItem('copiedSessionValues');
    function tryParse(input) {
        try {
            return JSON.parse(input);
        } catch (e) {
            return undefined;
        }
    }
    const parsedCopiedValues = tryParse(copiedSessionValues);
    const [successMessage, setSuccessMessage] = useState('');
    const [showWarning, setShowWarning] = useState(false);
    const warningMessage = 'Huomio: Et voi nähdä toisen ihmisen luomaa tapahtumaa, jonka tallennat luonnokseksi, jos et ole admin tai muokkaaja';
    const [errorMessage, setErrorMessage] = useState('');
    const [henkiloSuccessMessage, setHenkiloSuccessMessage] = useState('');
    const [henkiloErrorMessage, setHenkiloErrorMessage] = useState('');

    const [initialFormValues, setInitialFormValues] = useState(parsedCopiedValues || defaultValues);

    const [eventLength, setEventLength] = useState(0);

    const handleSuccessfulCreation = (message) => {
        setSuccessMessage(message);
    };
    const handleError = (message) => {
        setErrorMessage(message);
    };







    const [{
        submitting,
        submitted,
        error,
        message
    }, setFormStatus] = useState({
        submitting: false,
        submitted: false,
        error: false,
        message: ''
    });

    const eventTypes = useSelector(selectAllEventTypes);
    const audiences = useSelector(selectAllAudiences);
    const partners = useSelector(selectAllPartners);
    const roles = useSelector(selectAllRoles);
    const people = useSelector(selectAllPeopleNames);
    const centers = useSelector(selectAllCenters);
    const { data: places }  = useGetPlacesQuery();
    let placesArray;
    if(places) placesArray = Object.values(places?.entities);

    // Can't use the flags here, as theyll be in stale closure. We can just
    // create a basic state to keep track of it.

    const [mutationToUse] = isEditing ? useEditEventMutation() : useAddNewEventMutation();
    const [deleteImagesFromDisk] = useDeleteImagesMutation();

    const navigate = useNavigate();

    const handleCancelCopy = () => {
        // Clear copiedSessionValues in storage
        sessionStorage.removeItem('copiedSessionValues');

        // Redirect the user to the previous page (assuming you have a way to do this)
        // You can use the `useHistory` hook or your preferred navigation method.
        // Example using react-router-dom's useHistory:
        // import { useHistory } from 'react-router-dom';
        navigate('/tapahtumat'); // Redirect to the previous page

        // Alternatively, you can use the window's history object to go back:
        // window.history.back();
    };

    useEffect(() => {
        if (event || parsedCopiedValues) {
            const convertedData = convertDataToFormSchema(event || parsedCopiedValues);
            setInitialFormValues(convertedData);

        }
    }, [event]);

    const { formState, ...methods } = useForm({
        resolver: (data, context, options) => // Spread our most recent data to context, so it's always available in tests.
            yupResolver(validationSchema)(data, { ...data, isEditing }, options),
        mode: 'onBlur', // Validate on input state change.
        reValidateMode: 'onChange', // After a failed submit validate onChange.
        defaultValues: parsedCopiedValues || defaultValues,
        values: initialFormValues ?? defaultValues,
        resetOptions: { keepDefaultValues: true }
    });
    const { errors } = formState;



    const [kansanopistokurssi, setKansanopistoKurssi] = useState(initialFormValues?.kansanopistokurssi || false);
    const [kuvausValue, setKuvausValue] = useState(initialFormValues?.kuvaus || '');
    const [lisatiedotValue, setLisatiedotValue] = useState(initialFormValues?.lisatiedot || '');
    const debouncedHandleKuvausChange = debounce((fieldName, value) => {
        // Update the state with the new value
        setKuvausValue(value);
        // Update the form field's value associated with "kuvaus"
    }, 100); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
    const debouncedHandleLisatiedotChange = debounce((fieldName, value) => {
        // Update the state with the new value
        setLisatiedotValue(value);
        // Update the form field's value associated with "kuvaus"
    }, 100); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
    const handleKuvausChange = (fieldName, value) => {
        // Call the debounced function to update the state
        debouncedHandleKuvausChange(fieldName, value);
    };
    const handleLisatiedotChange = (fieldName, value) => {
        // Call the debounced function to update the state
        debouncedHandleLisatiedotChange(fieldName, value);
    };

    useEffect(() => {
        setKansanopistoKurssi(initialFormValues?.kansanopistokurssi || false);
    }, [initialFormValues.kansanopistokurssi]);
    useEffect(() => {
        setKuvausValue(initialFormValues?.kuvaus || '');
    }, [initialFormValues.kuvaus]);

    const { name } = useSelector(selectCurrentUser);




    const calculateTotalHours = () => {
        let totalHours = 0;

        initialFormValues.ohjelma.forEach((day) => {
            if (day.tilaisuudet && Array.isArray(day.tilaisuudet)) {
                day.tilaisuudet.forEach((tilaisuus) => {
                    if (tilaisuus.kansanopistotunnit && Array.isArray(tilaisuus.kansanopistotunnit)) {

                        tilaisuus.kansanopistotunnit.forEach((hoursObj) => {

                            totalHours += hoursObj.kansanopistotunnit || 0;

                        });
                    }
                });
            }
        });


        return totalHours;
    };

    const [hours, setHours] = useState(0);
    const [ sendLog ] = useAddNewLokiMutation();
    useEffect(() => {
        const newTotalHours = calculateTotalHours();
        setHours(newTotalHours);
    }, [initialFormValues]);

    const startTime = methods.watch('aloitus.pvm'); // Replace with actual field names
    const endTime = methods.watch('lopetus.pvm');

    useEffect(() => {
        if (startTime && endTime) {
            // Calculate the difference between start and end times
            const start = new Date(startTime);
            const end = new Date(endTime);
            const durationInMillis = end - start;

            // Convert milliseconds to hours
            const durationInDays = durationInMillis / (1000 * 60 * 60 * 24);

            // Update the total duration
            setEventLength(Math.floor(durationInDays + 1));
        }
    }, [startTime, endTime]);
    //makes it possible to have lopetus.pvm value copied from aloitus.pvm


    const firstRender = useRef(true);

    useEffect(() => {
        const aloitusPvmValue = methods.watch('aloitus.pvm');

        if (firstRender.current) {
            // Skip copying on the first render
            firstRender.current = false;
        } else if (!initialFormValues.lopetus.pvm) {
            // Only copy when lopetus.pvm is empty and the form is not in the initial state
            methods.setValue('lopetus.pvm', aloitusPvmValue);
        }

    }, [methods.watch('aloitus.pvm'), initialFormValues.lopetus.pvm]);



    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            setFormStatus(prev => ({
                ...prev,
                submitting: false,
                error: true,
                message: 'Virhe: Lomakkeen tiedoista puuttuu jotain tai niissä on virheitä.'
            }));
        }
    }, [errors]);


    const status = methods.watch('status');



    const onSubmit = async (data) => {



        if (kansanopistokurssi && status !== 'luonnos') {
            if (eventLength < 2 || hours < eventLength * 5) {
                setFormStatus(prev => ({
                    ...prev,
                    submitting: false,
                    error: true,
                    message: 'Virhe: Kansanopistokurssi vaatii vähintään 2 päivää ja riittävästi tunteja.'
                }));
                return; // Prevent submission
            }
        }


        data.lastEditedTimestamp = Date.now();


        setFormStatus(prev => ({
            ...prev,
            submitting: true,
            error: false
        }));



        const convertedData = convertDataToAPISchema(data);




        try {
            const res = await mutationToUse(convertedData).unwrap();



            setFormStatus(prev => ({
                ...prev,
                submitting: false,
                submitted: true,
                message: isEditing ? 'Muutokset tallennettu' : 'Tapahtuma luotu'
            }));
            /*await sendLog({
                name: name,
                action: isEditing ? 'muokkasi' : 'loi',
                document: {
                    type: 'tapahtumat',
                    documentId: res.id
                }

            });*/

            setTimeout(() => {
                navigate(`/tapahtumat/${res.id}`);
            }, 1000);

        } catch (err) {
            setFormStatus(prev => ({
                ...prev,
                submitting: false,
                error: true,
                message: (
                    `Virhe: tapahtuman ${ isEditing ? 'muokkaaminen' : 'tallentaminen'} epäonnistui.
                     Jos virhe toistuu, ota yhteyttä IT-tukeen.${err?.status && ` [status: ${err.status}]`}`
                )
            }));
        }
    };
    const nimiValue = methods.watch('nimi');
    const kuvausWatchValue = methods.watch('kuvaus');



    useEffect(() => {
        if (status === 'luonnos' && name !== event?.user?.name && event?.user?.name !== '' && event) {
            // Display the warning message if the conditions are met
            setShowWarning(true);
        } else {
            // Otherwise, hide the warning message
            setShowWarning(false);
        }
    }, [status, name, event?.user?.name]);



    // Wait until all of the data has been received from the selectors, to
    // silence MUI's out of range warnings on the FormListSelects. They wont
    // cause a crash, just unnecessary spam.

    // TODO: add a proper loading check to this, by adding flags to the
    // selectors or something like that. Currently, if any of the collections
    // are empty, it will just return the ProgressLoader.



    if (!(eventTypes.length && audiences.length && partners.length && roles.length && people.length && centers.length))
        return <ProgressLoader msg='Ladataan tietoja...' />;

    return (
        <Container
            maxWidth='md'
            display='flex'
            sx={{ justifyContent: 'center', mt: 2 }}>
            {/* FormProvider allows the use of useFormContext in child components */}
            <Button
                title="Esikatsele"
                type='submit'
                form='event-form'
                variant='contained'
                disabled={submitting || submitted}
                sx={{
                    position: 'fixed',
                    right: '20px', // Adjust the position as per your requirement
                    bottom: '40px', // Adjust the position as per your requirement
                    zIndex: 1000, // Ensure it's above other elements
                }}
            ><VisibilityIcon/></Button>
            <FormProvider
                { ...methods }
                formState={formState}
                defaultValues={defaultValues}
            >
                <Paper
                    id='event-form'
                    component='form'
                    onSubmit={methods.handleSubmit(onSubmit)}
                    onKeyDown={(e) => { // Prevent default enter submit.
                        if (e.key === 'Enter')
                            e.preventDefault();
                    }}
                    elevation={3}
                    sx={{
                        borderRadius: 8,
                        my: 3,
                        px: 8,
                        py: 4
                    }}
                >
                    <Grid container rowSpacing={7} width='100%'>

                        <Grid item xs={12} sx={{ pb: 5 }}>
                            <Typography align='center' variant='h4'>{parsedCopiedValues ? `${nimiValue} (kopio)` : nimiValue || 'Uusi tapahtuma'}</Typography>
                            {parsedCopiedValues && (
                                <Typography align='center' variant='subtitle1' color='text.secondary' sx={{ mt: 3 }}>
                                    Muokkaat kopiota
                                </Typography>
                            )}
                            {parsedCopiedValues && (<Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                                <Button
                                    variant="outlined" // You can adjust the variant and styling as needed
                                    onClick={handleCancelCopy}

                                >
                                    Peru kopiointi
                                </Button>
                            </Box>)}

                        </Grid>

                        {/* Nimi */}


                        <Grid item xs={4} pt={1}>
                            <Typography variant='h6'>Tapahtuman nimi <HelpIconWithTooltip iconSize={20} tooltipFontSize="16px" text="Kirjoita tähän tapahtuman nimi"/></Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FormTextField
                                fieldName='nimi'
                                label='Tapahtuman nimi'
                                fullWidth={true}
                            />
                        </Grid>


                        {/* Luonne */}

                        <Grid item xs={4} pt={1}>
                            <Typography variant='h6'>Tapahtuman luonne <HelpIconWithTooltip text="Luonteet kertovat mistä tapahtumassa on pääpiirteittäin kyse" iconSize={20} tooltipFontSize="16px"/></Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FormSelectMultiple
                                fieldName='luonteet'
                                options={eventTypes}
                                label='Tapahtumaluonteet'
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant='h6'>Tapahtuman tyyppi</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FormCheckbox fieldName='kansanopistokurssi'
                                label='Kansanopistokurssi' onChange={value => setKansanopistoKurssi(value)}/>
                        </Grid>
                        {kansanopistokurssi && <Grid item xs={12} sx={{ textAlign: 'center', py: 2 }}>
                            <Typography variant='h6'>Kansanopistotunnit</Typography>
                            {startTime && endTime && <><Typography variant='body1'>
                                Yhteensä {hours} tuntia, vaadittu vähintään {eventLength * 5} tuntia
                            </Typography>
                            {(eventLength *5) - hours > 0 ? <Typography variant='body1'>
                                Jäljellä {(eventLength *5) - hours} tuntia lisättäväksi
                            </Typography> : <Typography variant='body1'>Kansanopistotunteja on riittävästi</Typography>}</>}
                            {(!startTime || !endTime) && <Typography>Et ole vielä määritellyt tapahtuman aloitus- tai lopetusaikaa</Typography>}
                        </Grid>}

                        { /* Kohdeyleisö */}

                        <Grid item xs={4}>
                            <Typography variant='h6'>Tapahtuman kohdeyleisö <HelpIconWithTooltip text="Kenelle tapahtuma on suunnattu?" iconSize={20} tooltipFontSize="16px"/></Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FormSelectMultiple
                                fieldName='kohdeyleiso'
                                options={audiences}
                                label='Kohdeyleisö'
                            />
                        </Grid>

                        {/* Aloitus & lopetus */}

                        <Grid item xs={4}>
                            <Typography variant='h6'>Tapahtuman ajankohta</Typography>
                        </Grid>
                        <Grid item xs={8} gap={3} display='flex' justifyContent='space-between'>
                            <Typography width='10%' marginY='auto' variant='subtitle1'>Aloitus</Typography>
                            <FormDatePicker fieldName='aloitus.pvm'  />
                            <FormTimePicker fieldName='aloitus.aika' />
                        </Grid>

                        <Grid item xs={4} />
                        <Grid item xs={8} gap={3} display='flex' justifyContent='space-between'>
                            <Typography width='10%' marginY='auto' variant='subtitle1'>Lopetus</Typography>
                            <FormDatePicker fieldName='lopetus.pvm' />
                            <FormTimePicker fieldName='lopetus.aika' />
                        </Grid>

                        {/* Paikka */}

                        <Grid item xs={4} >
                            <Typography variant='h6'>Tapahtuman paikka</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Places fieldName='paikka' centers={centers} places={placesArray}/>
                        </Grid>


                        {/* Vastuuhenkilöt */}
                        <Grid item xs={4}>
                            <Typography variant='h6'>Luo henkilöitä</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            {henkiloSuccessMessage && (
                                <Box my={2}>
                                    <Alert severity="success" onClose={() => setHenkiloSuccessMessage('')}>
                                        {henkiloSuccessMessage}
                                    </Alert>
                                </Box>
                            )}
                            {henkiloErrorMessage && (
                                <Box my={2}>
                                    <Alert severity="error" onClose={() => setHenkiloErrorMessage('')}>
                                        {henkiloErrorMessage}
                                    </Alert>
                                </Box>
                            )}
                            <HenkilotAddForm onError={setHenkiloErrorMessage} onSuccess={setHenkiloSuccessMessage} />
                        </Grid>

                        <Grid item xs={4}>
                            <Typography variant='h6'>Tapahtuman vastuuhenkilöt</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Vastuuhenkilöt fieldName='vastuuhenkilot' people={people} />
                        </Grid>

                        {/* Tapahtumassa mukana olevat */}

                        <Grid item xs={4}>
                            <Typography variant='h6'>Tapahtumassa mukanaolevat henkilöt</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <MukanaOlevat
                                fieldName='mukanaolevat'
                                subfields={[ // Expects the name of the subfield inside the form state, along with the possible options.
                                    { name: 'henkilo', options: people, xs: 5 },
                                    { name: 'rooli', options: roles, xs: 4 },
                                    { name: 'julkinen', options: ['Kyllä', 'Ei'], xs: 3 }
                                ]}
                                emptyMsg='Tapahtumalla ei vielä ole mukanaolevia henkilöitä'
                            />
                        </Grid>

                        {/* Tapahtuman yhteistyötahot */}
                        <Grid item xs={4}>
                            <Typography variant='h6'>Tapahtuman yhteistyötahot</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FormSelectMultiple
                                fieldName='yhteistyotahot'
                                options={partners}
                                label='Yhteistyötahot'
                            />
                            {successMessage && (
                                <Box my={2}>
                                    <Alert severity="success" onClose={() => setSuccessMessage('')}>
                                        {successMessage}
                                    </Alert>
                                </Box>
                            )}
                            {errorMessage && (
                                <Box my={2}>
                                    <Alert severity="error" onClose={() => setErrorMessage('')}>
                                        {errorMessage}
                                    </Alert>
                                </Box>
                            )}
                            <YhteistyotahotForm onError={setErrorMessage} onSuccess={setSuccessMessage} />
                        </Grid>


                        {/* Tapahtuman kuvaus */}

                        <Grid item xs={4}>
                            <Typography variant='h6'>Tapahtuman kuvaus *</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <RichTextEditor
                                fieldName='kuvaus'
                                defaultValue={kuvausValue} // Provide the value from state
                                onChange={handleKuvausChange} // Pass the onChange handler
                            />
                        </Grid>

                        {/* Tapahtuman ohjelma */}

                        <Grid item xs={4}>
                            <Typography variant='h6'>Tapahtuman ohjelma *</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Schedule kansanopisto={kansanopistokurssi} fieldName='ohjelma' setHours={setHours}/>
                        </Grid>

                        {/* Tapahtuman lisätiedot */}

                        <Grid item xs={4}>
                            <Typography variant='h6'> Tapahtuman lisätiedot</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <RichTextEditor
                                fieldName='lisatiedot'
                                defaultValue={lisatiedotValue} // Provide the value from state
                                onChange={handleLisatiedotChange} // Pass the onChange handler
                            />
                        </Grid>

                        {/* Tapahtuman kuvat */}

                        <Grid item xs={4}>
                            <Typography variant='h6'> Tapahtuman kuvat</Typography>
                        </Grid>

                        <Grid item xs={8}>
                            <ImageUploader fieldName='kuvat'/>
                        </Grid>

                        {/* Tapahtuman musiikki */}

                        <Grid item xs={4}>
                            <Typography variant='h6'>Tapahtuman musiikki</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FormListField
                                fieldName='musiikki'
                                subfields={['kappale', 'artisti']}
                                emptyMsg='Tapahtumalla ei vielä ole musiikkia'
                            />
                        </Grid>

                        {/* Lyytilinkit */}

                        <Grid item xs={4} marginY='auto' mt={1}>
                            <Typography variant='h6'>Lyytilinkit</Typography>
                        </Grid>
                        <Grid container item xs={8}>
                            <Grid item xs={12}>
                                <FormTextField
                                    fieldName='lyytilinkit.ilmoittautumislinkki'
                                    label='Ilmoittautumislinkki'
                                    fullWidth={true}
                                />

                            </Grid>
                            <Grid item xs={12}>
                                <FormTextField
                                    fieldName='lyytilinkit.eta_ilmoittautumislinkki'
                                    label='Etäosallistujan ilmoittautumislinkki'
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>

                        {/* Laskutus */}

                        <Grid item xs={4}>
                            <Typography variant='h6'>Laskutus</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FormTextField
                                fieldName='laskutus'
                                label='Tapahtuman laskutustiedot'
                                fullWidth={true}
                                multiline={true}
                                rows={8}

                            />
                        </Grid>

                        {/* Muistiinpanot */}

                        <Grid item xs={4}>
                            <Typography variant='h6'>Tapahtuman <br/> muistiinpanot</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FormNotes fieldName='muistiinpanot' />
                        </Grid>

                        <Grid item xs={4} my='auto'>
                            <Typography variant='h6'>Tapahtuman lähetys</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FormCheckboxGroup
                                fieldName="lahetys"
                                defaultValue={['ei lahetysta']} // Default value as an array
                                options={['ei lahetysta', 'striimataan', 'radioidaan (radio dei)', 'radioidaan (radio316)']}
                            />
                        </Grid>

                        {/* Tapahtuman tila */}

                        <Grid item xs={4} my='auto'>
                            <Typography variant='h6'>Tapahtuman tila</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FormRadioGroup fieldName='status' options={['peruutettu', 'julkaistu', 'luonnos' , 'esitiedot']} />
                        </Grid>

                        {/* Submit-button */}
                        <Grid item container xs={12} pt={3} justifyContent='center'>
                            <Button
                                type='submit'
                                form='event-form'
                                variant='contained'
                                disabled={submitting || submitted}
                                sx={{
                                    width: '50%',
                                    backgroundColor: 'taphaBlue.dark',
                                }}>
                                { !submitting
                                    ? isEditing ? 'Tallenna muutokset' : 'Tallenna tapahtuma'
                                    : (
                                        <CircularProgress
                                            thickness={5}
                                            size={25}
                                            sx={{ color: 'white' }}
                                            value={90}
                                        />
                                    )
                                }
                            </Button>
                        </Grid>
                        <MessageSnackbar
                            showSnackbar={ submitted || error }
                            severity={ submitted ? 'success' : 'error' }
                            msg={ message }
                        />
                        {showWarning && (
                            <Grid item container xs={12}  justifyContent='center' sx={{ pb: 8 }}>

                                <Box  display="flex" justifyContent="center" alignItems="center"> {/* Centering the entire container */}
                                    <Alert severity="warning" onClose={() => setShowWarning(false)}>
                                        {warningMessage}
                                    </Alert>
                                </Box>
                            </Grid>)}

                    </Grid>
                </Paper>
            </FormProvider>
        </Container>
    );
};

export default EventForm;