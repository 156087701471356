import { useState } from 'react';
import { Grid, Typography, Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormTextField from '../../components/FormFields/FormTextField';
import UpdateButton from './adminUtils/updateButton';
import SearchBar from './adminUtils/SearchBar';
import PaginationInfo from './PaginationInfo';
import { dateUtils } from '../../utils';

function BugiraportitList({ bugiraportit, handleDeleteBugiraportti }) {
    const [selectedBugiraportit, setSelectedBugiraportit] = useState(null);
    const [newBugiraportitData, setNewBugiraportitData] = useState({ otsikko: '', raportti: '', lähetetty: null, lisatiedot: '', user: { name: '', id: null } });
    const [currentPage, setCurrentPage] = useState(1);
    /*const [showSuccessAlert, setShowSuccessAlert] = useState(false);*/
    const itemsPerPage = 10;




    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
        setCurrentPage(1); // Reset to the first page when searching
    };

    // Apply the search filter
    const filteredBugiraportit = bugiraportit?.filter((Bugiraportit) =>
        Bugiraportit.otsikko.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalPages = Math.ceil(filteredBugiraportit.length / itemsPerPage);

    // Get the slice of luonteet for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedBugiraportit = filteredBugiraportit.slice(startIndex, endIndex);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };



    const handleUserClick = (bugiraportit) => {
        if(selectedBugiraportit?.id === bugiraportit.id) {setSelectedBugiraportit(null);
            setNewBugiraportitData({ otsikko: '', raportti: '', lähetetty: null, lisatiedot: '', user: { name: '', id: null } });
            return;}
        setSelectedBugiraportit(bugiraportit);
        setNewBugiraportitData(bugiraportit);
    };



    return (
        <Grid container spacing={2}>
            <Box mt={3} ml={2}> {/* Add spacing to separate SearchBar */}
                <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
            </Box>
            {displayedBugiraportit.map((bugiraportti) => (
                <Grid key={bugiraportti.id} item xs={12} >
                    <Box key={bugiraportti.id} onClick={() => handleUserClick(bugiraportti)} sx={{
                        backgroundColor: 'taphaWhite.main',
                        borderRadius: 5,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginTop: 0.6,
                        gap: 5,
                        '&:hover': {
                            border: '1px solid',
                            borderLeft: '30px solid',
                            transition: '0.4s',
                            borderColor: 'taphaGreen.main',
                            cursor: 'pointer'
                        }
                    }}>
                        <Typography variant="h6">
                            {bugiraportti.otsikko}
                        </Typography>
                        <IconButton onClick={(event) => {
                            handleDeleteBugiraportti(event, bugiraportti);
                            if (selectedBugiraportit && selectedBugiraportit.id === bugiraportti.id) {
                                setSelectedBugiraportit(null);
                                setNewBugiraportitData({ otsikko: '', raportti: '', lähetetty: null, lisatiedot: '', user: { name: '', id: null } });
                            }
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                    {selectedBugiraportit && selectedBugiraportit.id === bugiraportti.id && (
                        <Grid container>

                            <Grid item xs={8} sx={{ pt: 2 }}>
                                <FormTextField

                                    fieldName='otsikko'
                                    label='otsikko'
                                    multiline={true}
                                    fullWidth={true}
                                    value={newBugiraportitData.otsikko}

                                />
                            </Grid>
                            <Grid item xs={8}>
                                <FormTextField
                                    error={true}
                                    helperText='Bugiraportti'

                                    fieldName='raportti'
                                    label='raportti'
                                    multiline={true}
                                    fullWidth={true}
                                    value={newBugiraportitData.raportti}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <FormTextField
                                    error={true}
                                    helperText='milloin raportti on lähetetty'

                                    fieldName='lähetetty'
                                    label='lähetetty'
                                    multiline={true}
                                    fullWidth={true}
                                    value={dateUtils.getFormattedDateWithHours(newBugiraportitData.lähetetty)}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <FormTextField
                                    error={true}
                                    helperText='lisätiedot'

                                    fieldName='lisatiedot'
                                    label='lisätiedot'
                                    multiline={true}
                                    fullWidth={true}
                                    value={newBugiraportitData.lisatiedot}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <FormTextField
                                    error={true}
                                    helperText='lähettänyt'

                                    fieldName='user'
                                    label='Lähettänyt'
                                    multiline={true}
                                    fullWidth={true}
                                    value={newBugiraportitData.user?.name}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            ))}

            {/* Pagination arrows */}
            <Box mt={2} ml={2} display="flex" flexDirection="column" alignItems="center">
                <PaginationInfo
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    filteredArray={filteredBugiraportit}
                    array={bugiraportit}
                />
                <Box display="flex" justifyContent="center" width="100%">
                    <Box width="740px" display="flex" justifyContent="center"> {/* Centered box with a specified width */}
                        <IconButton
                            size="small"
                            onClick={handlePreviousPage}
                            disabled={currentPage <= 1}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={handleNextPage}
                            disabled={currentPage >= totalPages}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
}

export default BugiraportitList;