import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Radio
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';

const ImageListItem = ({ image, value, isBanner, handleChange, handleRemove }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);

    const imageIsOnServer = image.savedToServer;

    const imagePath = imageIsOnServer
        ? image.src
        : URL.createObjectURL(image.file);

    const imageName = imageIsOnServer
        ? image.originalFileName
        : image.file.name;

    const WIDTH = 1200;
    const HEIGHT = 630;

    useEffect(() => {
        if (image && isOpen) {

            // If the image is selected as the banner image, convert it to
            // 1200 x 630 resolution for previewing. Banner images should always
            // be in this format. This is only for previewing uploaded files,
            // as the backend will convert the file to the needed resolution.

            if (isBanner && !imageIsOnServer) {

                const img = new Image();
                img.src = imagePath;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = WIDTH;
                    canvas.height = HEIGHT;

                    const context = canvas.getContext('2d');
                    context.drawImage(img, 0, 0, WIDTH, HEIGHT);
                    const dataURL = canvas.toDataURL('image/jpeg');
                    setPreviewImage(dataURL);
                };
            } else
                setPreviewImage(imagePath);
        }
    }, [image, isOpen]);

    const listItemStyle = {
        border: '1px solid',
        backgroundColor: 'taphaWhite.main',
        borderColor: 'taphaWhite.main',
        borderRadius: 3,
        display: 'flex',
        justifyContent: 'space-evenly',
        my: 2,
        '&:hover': {
            borderColor: 'taphaGreen.main',
            cursor: 'pointer'
        }
    };

    return (
        <>
            <ListItem sx={listItemStyle}>
                <Radio
                    name='banner-image'
                    value={value}
                    checked={isBanner}
                    onChange={handleChange}
                    sx={{
                        '& .MuiSvgIcon-root': {
                            fontSize: '2rem',
                        },
                    }}
                    checkedIcon={<FlagCircleIcon sx={{ color: 'taphaGreen.main' }} />}
                />
                <ListItemAvatar>
                    <Avatar alt={imageName} src={imagePath} />
                </ListItemAvatar>
                <ListItemText sx={{ overflow: 'hidden', maxWidth: '55%' }} primary={imageName} />
                <ListItemSecondaryAction sx={{ px: 4 }}>
                    <IconButton edge='start' onClick={() => setIsOpen(true)}>
                        <VisibilityIcon sx={{ color: 'taphaBlue.dark' }} />
                    </IconButton>
                </ListItemSecondaryAction>
                <ListItemSecondaryAction>
                    <IconButton onClick={handleRemove} edge="end">
                        <ClearIcon sx={{ color: 'taphaBlue.dark' }} />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogContent>
                    <img src={previewImage} alt={imageName} style={{ maxWidth: '100%' }} />
                    <DialogContentText pt={3}>
                        {isBanner && 'Tämä kuva on valittu banner-kuvaksi. Banner-kuvat muokataan esikatselussa resoluutiolle 1200 x 630px.'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', display: 'flex' }}>
                    <Button
                        onClick={() => setIsOpen(false)}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                        variant='contained'
                    >
                        Sulje
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

ImageListItem.propTypes = {
    image: PropTypes.object
};

export default ImageListItem;