import { useState } from 'react';
import { Grid, Typography, Box, IconButton, Button, Alert, FormControlLabel, Checkbox } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormTextField from '../../components/FormFields/FormTextField';
import { useAddNewRaporttiMutation  } from '../../api/tiedotteetApiSlice';
import UpdateButton from './adminUtils/updateButton';
import SearchBar from './adminUtils/SearchBar';
import PaginationInfo from './PaginationInfo';

function RaportitList({ raportit, handleDeleteRaportti, fetchData, onSuccess, onError }) {
    const [selectedRaportti, setSelectedRaportti] = useState(null);
    const [newRaporttiData, setNewRaporttiData] = useState({ otsikko: '', Raportti: '', etusivu: false });
    const [addRaporttiData, setAddRaporttiData] = useState({ otsikko: '', Raportti: '', etusivu: false });
    const [showAddForm, setShowAddForm] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    /*const [showSuccessAlert, setShowSuccessAlert] = useState(false);*/
    const itemsPerPage = 10;



    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
        setCurrentPage(1); // Reset to the first page when searching
    };

    // Apply the search filter
    const filteredRaportit = raportit?.filter((Raportti) =>
        Raportti.tapahtumaId.toString().includes(searchTerm.toLowerCase())
    );

    const totalPages = Math.ceil(filteredRaportit.length / itemsPerPage);

    // Get the slice of luonteet for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedRaportit = filteredRaportit.slice(startIndex, endIndex);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };



    const handleUserClick = (Raportti) => {
        if(selectedRaportti?.id === Raportti.id) {setSelectedRaportti(null);
            setNewRaporttiData({ tapahtumaId: null });
            return;}
        setSelectedRaportti(Raportti);
        setNewRaporttiData(Raportti);
    };

    const handleAddClick = () => {
        setShowAddForm(!showAddForm);
    };


    return (
        <Grid container spacing={2}>
            <Box mt={3} ml={2}> {/* Add spacing to separate SearchBar */}
                <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
            </Box>
            {displayedRaportit.map((Raportti, index) => (
                <Grid key={Raportti.id} item xs={12} >
                    <Box key={Raportti.id} onClick={() => handleUserClick(Raportti)} sx={{
                        backgroundColor: 'taphaWhite.main',
                        borderRadius: 5,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginTop: 0.6,
                        gap: 5,
                        '&:hover': {
                            border: '1px solid',
                            borderLeft: '30px solid',
                            transition: '0.4s',
                            borderColor: 'taphaGreen.main',
                            cursor: 'pointer'
                        }
                    }}>
                        <Typography variant="h6">
                            Tapahtuman id: {Raportti.tapahtumaId}
                        </Typography>
                        <IconButton onClick={(event) => {
                            handleDeleteRaportti(event, Raportti);
                            if (selectedRaportti && selectedRaportti.id === Raportti.id) {
                                setSelectedRaportti(null);
                                setNewRaporttiData({ tapahtumaId: null });
                            }
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                </Grid>
            ))}
            {/* Pagination arrows */}
            <Box mt={2} ml={2} display="flex" flexDirection="column" alignItems="center">
                <PaginationInfo
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    filteredArray={filteredRaportit}
                    array={raportit}
                />
                <Box display="flex" justifyContent="center" width="100%">
                    <Box width="740px" display="flex" justifyContent="center"> {/* Centered box with a specified width */}
                        <IconButton
                            size="small"
                            onClick={handlePreviousPage}
                            disabled={currentPage <= 1}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={handleNextPage}
                            disabled={currentPage >= totalPages}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
}

export default RaportitList;