import { useEffect } from 'react';

// Simple hook for updating the document title on a given route/component.

const useDocumentTitle = (newTitle) => {

    useEffect(() => {
        const prevTitle = document.title;
        document.title = newTitle;

        // On dismount set back to the prevTitle.
        return () => document.title = prevTitle;

    });
};

export default useDocumentTitle;