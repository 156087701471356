import { createSelector, createEntityAdapter } from '@reduxjs/toolkit';
import { apiSlice } from './apiSlice';

const centerAdapter = createEntityAdapter({
    selectId: (center) => center.id
});
const initialState = centerAdapter.getInitialState();

export const centersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCenters: builder.query({
            query: () => ({
                url: '/keskukset',
                validateStatus: (response, result) =>
                    response.status === 200 && !result.isError
            }),
            transformResponse: responseData =>
                centerAdapter.setAll(initialState, responseData),
            providesTags: ['Centers']
        }),
        addNewCenter: builder.mutation({
            query: newCenter => ({
                url: '/keskukset',
                method: 'POST',
                body: { ...newCenter }
            }),
            invalidatesTags: ['Centers']
        }),
        deleteCenter: builder.mutation({
            query: ({ id }) => ({
                url: '/keskukset',
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: ['Centers']
        })
    })
});

export const {
    useGetCentersQuery,
    useAddNewCenterMutation,
    useDeleteCenterMutation
} = centersApiSlice;

export const selectCentersResult = centersApiSlice.endpoints.getCenters.select('CentersList');

const selectCentersData = createSelector(
    selectCentersResult,
    centerResult => centerResult.data
);

export const {
    selectIds: selectCenterIds,
    selectEntities: selectCenterEntities,
} = centerAdapter.getSelectors(state => selectCentersData(state) ?? initialState);

// Selector for all centers.

export const selectAllCenters = createSelector(
    selectCenterIds,
    selectCenterEntities,
    (ids, centers) => ids.map(id => centers[id])
);

// Select all centers names.

export const selectAllCenterNames = createSelector(
    selectCenterIds,
    selectCenterEntities,
    (ids, centers) => ids.map(id => centers[id].nimi)
);