import { Box, Button } from '@mui/material';

const UpdateButton = ({ handleUpdate, data }) => {


    return (<Box display="flex" alignItems="flex-end"><Button onClick={() => handleUpdate(data)}>
    Päivitä
    </Button></Box>);

};

export default UpdateButton;