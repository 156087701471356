import { useEffect, useState, useCallback, useMemo, useRef } from 'react';

import { useGetEventsQuery } from '../../../api/eventsApiSlice';
import { dateUtils } from '../../../utils';

// Store the paginated results into a "cache" out side the hook, so that if
// the hook is called from different components with the same amount of years,
// we won't have to refetch all of the data again.

const cache = new Map();

// Hook for fetching and concatenating the results of paginated event queries.
// If the hook is called with same amount of years as previously, it will
// return the cached results from the previous query to avoid needless
// refetching.

const useEventsData = ({ pageSize, years }) => {

    const [events, setEvents] = useState([]);
    const [page, setPage] = useState(1);
    const [finished, setFinished] = useState(false);
    const [deletedEventId, setDeletedEventId] = useState(null);
    const prevDeletedEventIdRef = useRef(null);


    // The cache key to identify a change in years.

    const cacheKey = useMemo(() => {
        return { years, deletedEventId };
    }, [years, deletedEventId]);

    // A memoized setter for the cache data.

    const setDataToCache = useCallback((data) => {
        cache.set(cacheKey, data);
    }, [cacheKey]);

    const cachedData = cache.get(cacheKey);

    const { currentData, refetch } = useGetEventsQuery(
        {
            pageSize: pageSize ?? 200,
            years: years ?? 2,
            page: page
        },
        { skip: finished }
    );

    useEffect(() => {

        // If we have data in the cache, meaning that the data was fetched for
        // the same amount of years the last time, set the cached data to the
        // state and return it.

        //Bad logic setting events to cachedData, that will return the old events after deletion instead of the new list
        /*if (cachedData && !finished) {
            setEvents(cachedData);
            setFinished(true);
            return;
        }*/
        if (cachedData && !finished && !deletedEventId) {
            setEvents(cachedData);
            setFinished(true);
            return;
        }

        // Else, paginate through all the results and concatenate them together.

        if (currentData && !finished) {

            // newEvents are always the events for a particular page from the
            // results.

            const { tapahtumat: newEvents, totalPages } = currentData;

            // Concat the new events to the previous state, and attach the
            // the date fields to them. The Date fields are used for filtering
            // and sorting the data.

            const allEvents = [
                ...events,
                ...newEvents.map(event => ({
                    ...event,
                    date: dateUtils.getDateFields(event.aloitus) // Attach the date-field on fetch. No point saving this to the db. Used for easier filtering.
                }))
            ];

            setEvents(allEvents);

            if (page < totalPages)
                setPage(prevPage => prevPage + 1);
            else {
                setFinished(true);
                setDataToCache(allEvents);
            }
            setDeletedEventId(null);
        }
    }, [currentData, finished, page, deletedEventId, cachedData]);

    useEffect(() => {
        // Check if deletedEventId has changed since the last render
        if (deletedEventId && deletedEventId !== prevDeletedEventIdRef.current) {
            prevDeletedEventIdRef.current = deletedEventId; // Update the ref to track the latest deletedEventId
            // Update the events state after deletion
            if (events.length > 0) {
                const updatedEvents = events.filter((event) => event.id !== deletedEventId);
                setEvents(updatedEvents);
            }
        }
    }, [deletedEventId, events]);

    return { events, finished, setDeletedEventId, refetch };
};

export default useEventsData;