/*
    This module contains all the JWT logic in the App, apart from
    action creators in authSlice.js.

    Provides Login, Refresh and Logout mutations.
*/
import jwtDecode from 'jwt-decode';

import { apiSlice } from '../../api/apiSlice';
import { logOut, setAccessToken, setUser } from './authSlice';

const onQueryStarted = async (arg, { dispatch, queryFulfilled }) => {
    // A function used for storing the Access Token and user info to
    // Redux store. Used in both login and refresh mutations.

    try {
        // On a succesful login or refresh, spread the accessToken from the response
        // and store it by dispatching the action creator.

        const response = await queryFulfilled;
        const { accessToken } = response.data;
        dispatch(setAccessToken({ accessToken }));

        // Decode the Access Token to get all the needed user info for the client,
        // and store by dispatching action creator.

        const { username, name, role, userId } = jwtDecode(accessToken); // Only destructure the wanted properties.
        const user = { username, name, role, userId };
        dispatch(setUser({ user }));
    } catch (e) {
        console.log(e);
    }
};

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        // User login. Response body contains the Access Token.
        // The response also sets the Refresh Token as an httpOnly cookie.

        login: builder.mutation({
            query: credentials => ({
                url: '/auth/login',
                method: 'POST',
                body: { ...credentials }
            }),
            onQueryStarted
        }),
        // Refresh the users Access Token with a request, if the Refresh Token is still valid.
        // Directly sets the new Access Token to Redux state.
        // Simplifies the authentication in the App, as all the token logic
        // is contained within this slice.

        refresh: builder.mutation({
            query: () => ({
                url: '/auth/refresh',
                method: 'GET'
            }),
            onQueryStarted
        }),
        // Logs the user out of the App. The Server response deletes the
        // httpOnly cookie Refresh Token.

        sendLogout: builder.mutation({
            query: () => ({
                url: '/auth/logout',
                method: 'POST'
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    // Wait until the query has been fulfilled, and logout.
                    await queryFulfilled;
                    dispatch(logOut());

                    // Possible bug with Redux subscriptions, where the components
                    // aren't properly unsubscribed on dismount. Resetting the API state,
                    // after a short time out, should give the app enough time to realise the
                    // components have been dismounted and to unsubscribe the listeners.

                    setTimeout(() => {
                        dispatch(apiSlice.util.resetApiState());
                    }, 1000);
                } catch (e) {
                    console.log(e);
                }
            }
        }),
    })
});

export const {
    useLoginMutation,
    useSendLogoutMutation,
    useRefreshMutation
} = authApiSlice;