import { useState, useEffect } from 'react';
import {
    Alert,
    Paper,
    List
} from '@mui/material';

import useEventsData from '../../hooks/useEventsData';
import EventFilters from './EventFilters';
import EventListItem from './EventListItem';
import { dateUtils } from '../../../../utils';
import ProgressLoader from '../../../../components/ProgressLoader';
import { useDeleteEventMutation } from '../../../../api/eventsApiSlice';

const EventList = () => {

    // Fetch all events from the previous 10 years, as well as future events.

    const { events, finished, setDeletedEventId, refetch } = useEventsData({ pageSize: 200, years: 10  });

    const [deleteEvent, { isLoading }] = useDeleteEventMutation();


    // The current selected year in string format.
    const [selectedYear, setSelectedYear] = useState(dateUtils.getCurrentYear());
    const handleYearChange = (event, newValue) => setSelectedYear(newValue);

    // The current month in short format, e.g. 'tammi', 'helmi', 'maalis'.
    const [selectedMonth, setSelectedMonth] = useState(dateUtils.getCurrentMonthShort());
    const handleMonthChange = (event, newValue) => setSelectedMonth(newValue);

    // Years and months for the filters. Months are in their short finnish
    // names, e.g. 'Tammi', 'Helmi', 'Maalis'.
    const years = dateUtils.getFilterYears();
    const months = dateUtils.getAllMonthNamesShort();

    const [isDataLoaded, setIsDataLoaded] = useState(false);



    useEffect(() => {
        if (finished) {
            setIsDataLoaded(true);
        }
    }, [finished]);

    useEffect(() => {
        return () => {


        };
    }, [events]);

    const deleteEventById = async(event) => {
        try {
            deleteEvent(event);
            refetch();
            setDeletedEventId(event.id);
        } catch(error){
            console.log(error);
        }
    };



    let content;

    if (!isDataLoaded) content = <ProgressLoader msg="Ladataan tapahtumia.." />;

    else if (finished) {
        const eventsToDisplay = events
            ?.filter(
                (event) =>
                    event.date.year === selectedYear &&
              event.date.monthName === selectedMonth && event.status !== 'luonnos'
            )
            .sort((a, b) => a.date.day - b.date.day);

        if (eventsToDisplay?.length) {
            content = eventsToDisplay.map((event) => (
                <EventListItem
                    deleteEvent={deleteEventById}
                    key={event.id}
                    event={event}
                    events={events}
                />
            ));
        } else {
            content = (
                <Alert severity="info" sx={{ justifyContent: 'center', borderRadius: 3 }}>
              Tälle kuulle ei vielä ole lisätty tapahtumia
                </Alert>
            );
        }
    }

    return (
        <Paper
            elevation={3}
            sx={{
                minHeight: '85vh',
                borderRadius: 8,
                my: 2,
                px: 8,
                py: 4
            }}>
            <EventFilters
                values={years}
                selected={selectedYear}
                handleChange={handleYearChange} />
            <EventFilters
                values={months}
                selected={selectedMonth}
                handleChange={handleMonthChange} />
            <List sx={{
                outline: 0
            }}>
                {content}
            </List>
        </Paper>
    );
};

export default EventList;