import React, { useState } from 'react';
import { Container, Paper, Typography, Grid, Button, Snackbar } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import FormTextField from '../components/FormFields/FormTextField';
import { useAddNewBugiraporttiMutation } from '../api/bugiraportitApiSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ThemeProvider } from '@mui/material';
import theme from '../assets/muiTheme';
import { selectCurrentUser } from '../features/authentication/authSlice';
import { useSelector } from 'react-redux';

const BugiRaporttiForm = () => {
    const methods = useForm({
        defaultValues: {
            otsikko: '',
            raportti: '',
            lisatiedot: ''
        },
        resolver: yupResolver(
            yup.object().shape({
                otsikko: yup.string().required('Otsikko on pakollinen').min(5, 'Otsikon pituus on oltava vähintään 5 merkkiä'),
                raportti: yup.string().required('Raportti on pakollinen').min(5, 'Raportin pituus on oltava vähintään 5 merkkiä'),
                lisatiedot: yup.string() // Optional field, no validation
            })
        )
    });
    const currentUser = useSelector(selectCurrentUser);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);

    const { handleSubmit, formState: { errors } } = methods; // Destructure errors from formState
    const [addNewBugiraportti] = useAddNewBugiraporttiMutation();

    const onSubmit = async (newBugiraportti) => {
        try {
            await addNewBugiraportti({ newBugiraportti, currentUser });
            setOpenSuccess(true);
            methods.reset();
        } catch (error) {
            console.error(error);
            setOpenError(true);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccess(false);
        setOpenError(false);
    };

    return (
        <Container maxWidth='md' display='flex' justifyContent='center' mt={2}>
            <FormProvider {...methods}>
                <Paper
                    component='form'
                    onSubmit={handleSubmit(onSubmit)}
                    elevation={3}
                    sx={{
                        borderRadius: 8,
                        my: 3,
                        px: 8,
                        py: 4
                    }}
                >
                    <Grid container rowSpacing={3} justifyContent='center'>
                        <Grid item xs={12}>
                            <Typography variant='h5' align='center'>Bugin raportointi</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField
                                fieldName='otsikko'
                                label='otsikko'
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField
                                fieldName='raportti'
                                label='raportti'
                                fullWidth={true}
                                multiline={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField
                                fieldName='lisatiedot'
                                label='lisätiedot'
                                fullWidth={true}
                                multiline={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type='submit' variant='contained' disabled={Object.keys(errors).length > 0}>Lähetä raportti</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </FormProvider>

            {/* Success Snackbar */}
            <ThemeProvider theme={theme}>
                <Snackbar
                    open={openSuccess}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    message="Raportti lähetetty onnistuneesti!"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                />

                {/* Error Snackbar */}
                <Snackbar
                    open={openError}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    message="Raportin lähettäminen epäonnistui"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                />
            </ThemeProvider>
        </Container>
    );
};

export default BugiRaporttiForm;