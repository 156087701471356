import React from 'react';
import { Modal, Box, Typography, List, ListItem, ListItemText, Link } from '@mui/material';

const ReportsModal = ({ open, onClose, reports, yhteistyotaho }) => {
    const filteredReports = reports.filter(report =>
        report.yhteistyotahot.some(y => y.nimi === yhteistyotaho)
    );

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ width: 400, margin: 'auto', marginTop: '10%', bgcolor: 'background.paper', p: 4 }}>
                <Typography variant="h6" component="h2">
                    Raportit - {yhteistyotaho}
                </Typography>
                <List>
                    {filteredReports.map((report) => {
                        const yhteistyotahoData = report.yhteistyotahot.find(y => y.nimi === yhteistyotaho);
                        let lightColor;
                        if (yhteistyotahoData.suhtautuminen === 1) {
                            lightColor = 'green';
                        } else if (yhteistyotahoData.suhtautuminen === 0) {
                            lightColor = 'orange';
                        } else {
                            lightColor = 'red';
                        }

                        return (
                            <ListItem key={report.id}>
                                <ListItemText
                                    primary={`Tapahtuman ID: ${report.tapahtumaId}`}
                                    secondary={
                                        <React.Fragment>
                                            <Link href={`/tapahtumat/${report.tapahtumaId}`} target="_blank" rel="noopener">
                                                Katso tapahtuma
                                            </Link>
                                            <div
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    borderRadius: '50%',
                                                    background: lightColor,
                                                    display: 'inline-block',
                                                    marginLeft: '10px',
                                                    verticalAlign: 'middle',
                                                }}
                                            />
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </Box>
        </Modal>
    );
};

export default ReportsModal;
