import { useWatch } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Autocomplete, Chip, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useState } from 'react';

const FormSelectMultiple = ({ options, existingYhteystiedot, onUpdate, fieldName, ...otherProps }) => {

    const [selectedOptions, setSelectedOptions] = useState([]);

    const formValues = useWatch({
        name: fieldName,
        defaultValue: null
    });

    const {
        control,
        formState: { errors }
    } = useFormContext();

    const handleSelectChange = (event, selectedItems) => {
        setSelectedOptions(selectedItems);
        onUpdate && onUpdate(selectedItems); // Call the onUpdate callback to update the parent component's state
    };

    const handleCombinedChange = (event, selectedItems, field) => {
        field.onChange(selectedItems); // Call the default onChange provided by RHF
        onUpdate && handleSelectChange(event, selectedItems); // Call your custom onChange function
    };

    return (
        <Controller
            name={fieldName}
            control={control}
            render={({ field }) =>
                <Autocomplete
                    multiple
                    fullWidth
                    autoHighlight
                    value={existingYhteystiedot ? existingYhteystiedot : formValues ? formValues : []}
                    options={options}
                    onChange={(event, selectedItems) => handleCombinedChange(event, selectedItems, field)}
                    renderInput={(params) =>
                        <TextField
                            { ...params }
                            { ...otherProps }
                            variant='outlined'
                            error={!!errors[fieldName]}
                            helperText={errors?.[fieldName]?.message ?? ' '}
                        />
                    }
                    renderTags={(tags, getTagProps) =>
                        tags.map((option, i) =>
                            <Chip
                                { ...getTagProps({ i }) }
                                key={option}
                                label={option}
                                variant='outlined'
                                deleteIcon={<ClearIcon sx={{ color: 'taphaBlue.main' }} />}
                            />
                        )
                    }
                />
            }
        />
    );
};

FormSelectMultiple.propTypes = {
    values: PropTypes.array,
    name: PropTypes.string
};

export default FormSelectMultiple;