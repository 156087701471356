import React from 'react';
import Typography from '@mui/material/Typography';

const PaginationInfo = ({ currentPage, itemsPerPage, filteredArray, array }) => {
    const firstIndex = (currentPage - 1) * itemsPerPage + 1;
    const lastIndex = Math.min(firstIndex + itemsPerPage - 1, filteredArray.length);

    return (
        <Typography variant="body2" mb={1}>
            {filteredArray.length > 0
                ? `${firstIndex} - ${lastIndex} / ${array.length} `
                : 'Haulla ei löytynyt tuloksia '}
            | Sivu {filteredArray.length > 0 ? currentPage : 0} / {Math.ceil(filteredArray.length / itemsPerPage)}
        </Typography>
    );
};

export default PaginationInfo;