import { useEffect, useState } from 'react';

// A hook for setting a given value to local storage.
// At the moment it's only used for setting the RememberMe-flag
// to LocalStorage on user log in.

const useLocalStorage = (key, defaultValue) => {

    const [value, setValue] = useState(JSON.parse(localStorage.getItem(key) || defaultValue));

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [value]);

    return [value, setValue];
};

export default useLocalStorage;