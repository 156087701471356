import { apiSlice } from './apiSlice';

export const deleteImageApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        deleteImages: builder.mutation({
            query: (imageNames) => ({
                url: '/images',
                method: 'DELETE',
                body: imageNames
            })
        })
    })
});

export const { useDeleteImagesMutation } = deleteImageApiSlice;