import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@mui/material';
import Select from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CustomSelectIcon = (props) => {
    return (
        <Select.Icon {...props}>
            <ExpandMoreIcon sx={{ transform: 'rotate(90deg)' }} />
        </Select.Icon>
    );
};

const DropdownFilter = ({ options, ...otherProps }) => {

    return (
        <TextField { ...otherProps } select>
            <MenuItem value=''>-</MenuItem>
            {options?.map(option =>
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            )}
        </TextField>
    );
};

DropdownFilter.propTypes = {
    options: PropTypes.array,
    label: PropTypes.string,
    onChange: PropTypes.func,
    otherProps: PropTypes.object,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default DropdownFilter;