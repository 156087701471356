import { useState } from 'react';
import { Grid, Typography, Box, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormTextField from '../../components/FormFields/FormTextField';
import { useAddNewAudienceMutation  } from '../../api/audiencesApiSlice';
import UpdateButton from './adminUtils/updateButton';
import SearchBar from './adminUtils/SearchBar';
import PaginationInfo from './PaginationInfo';

function AudiencesList({ yleisöt, handleDeleteYleiso, handleUpdateYleiso, fetchData, onSuccess, onError }) {
    const [selectedYleiso, setSelectedYleiso] = useState(null);
    const [newYleisoData, setNewYleisoData] = useState({ yleiso: '' });
    const [addYleisoData, setAddYleisoData] = useState({ yleiso: '' });
    const [showAddForm, setShowAddForm] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [addNewYleiso, { isLoading }] = useAddNewAudienceMutation();



    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
        setCurrentPage(1); // Reset to the first page when searching
    };

    // Apply the search filter
    const filteredYleisöt = yleisöt.filter((Yleiso) =>
        Yleiso.yleiso.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalPages = Math.ceil(filteredYleisöt.length / itemsPerPage);


    // Get the slice of luonteet for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedYleisöt = filteredYleisöt.slice(startIndex, endIndex);


    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleUserClick = (Yleiso) => {
        if(selectedYleiso?.id === Yleiso.id) {setSelectedYleiso(null);
            setNewYleisoData({ Yleiso: '' });
            return;}
        setSelectedYleiso(Yleiso);
        setNewYleisoData(Yleiso);
    };

    const handleAddClick = () => {
        setShowAddForm(!showAddForm);
    };

    const handleLisääYleiso = async(Yleiso) => {
        try {
            const result = await addNewYleiso(Yleiso);

            if(result.error) onError('Yleisön luominen epäonnistui');
            if(!result.error) onSuccess('Yleisön luominen onnistui.', 'yleisot', result.data.id);

            setAddYleisoData({ yleiso: '' });
            setShowAddForm(false);
            fetchData();
        } catch (error) {
            console.error(error); // handle error
        }};


    return (
        <Grid container spacing={2}>
            <Box mt={3} ml={2}> {/* Add spacing to separate SearchBar */}
                <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
            </Box>
            {displayedYleisöt.map((Yleiso, index) => (
                <Grid key={Yleiso?.id} item xs={12} >
                    <Box key={index} onClick={() => handleUserClick(Yleiso)} sx={{
                        backgroundColor: 'taphaWhite.main',
                        borderRadius: 5,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginTop: 0.6,
                        gap: 5,
                        '&:hover': {
                            border: '1px solid',
                            borderLeft: '30px solid',
                            transition: '0.4s',
                            borderColor: 'taphaGreen.main',
                            cursor: 'pointer'
                        }
                    }}>
                        <Typography variant="h6">
                            {Yleiso?.yleiso}
                        </Typography>
                        <IconButton onClick={(event) => {
                            handleDeleteYleiso(event, Yleiso);
                            if (selectedYleiso && selectedYleiso.id === Yleiso.id) {
                                setSelectedYleiso(null);
                                setNewYleisoData({ yleiso: '' });
                            }
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                    {selectedYleiso && selectedYleiso.id === Yleiso.id && (
                        <Grid container>

                            <Grid item xs={8} sx={{ pt: 2 }}>
                                <FormTextField

                                    fieldName='yleiso'
                                    label='yleisö'
                                    multiline={true}
                                    fullWidth={true}

                                    value={newYleisoData.yleiso}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setNewYleisoData((prevState) => ({ ...prevState, [name]: value }));
                                    }}

                                />
                            </Grid>

                            <UpdateButton handleUpdate={handleUpdateYleiso} data={newYleisoData}/>

                        </Grid>
                    )}
                </Grid>
            ))}
            {showAddForm && (
                <Box sx={{
                    flexGrow: 1, // Add flexGrow to make the box grow
                }}>
                    <Typography variant="h6" sx={{ pt: 2 }}>Kohdeyleisön lisääminen</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <FormTextField
                                key="addYleiso"
                                fieldName='yleiso'
                                label='yleisö'
                                multiline={true}
                                fullWidth={true}

                                value={addYleisoData.yleiso}
                                onChange={(event) => setAddYleisoData({ ...addYleisoData, yleiso: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                        </Grid>

                        <Box display="flex" justifyContent="flex-end" width="100%" pt={1}>

                            <Button
                                onClick={() => handleLisääYleiso(addYleisoData)}
                                size="small"
                                variant="contained"
                                startIcon={<AddIcon />}
                                sx={{ backgroundColor: 'taphaGreen.main' }}
                            >
          Lisää yleisö
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            )}
            {showAddForm ? (
                <Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={handleAddClick}
                        size='small'
                        variant='contained'
                        startIcon={<CloseIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Sulje
                    </Button>
                </Box>
            ) :
                (<Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={handleAddClick}
                        size='small'
                        variant='contained'
                        startIcon={<AddIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Lisää
                    </Button>
                </Box>)}
            {/* Pagination arrows */}
            <Box mt={2} ml={2} display="flex" flexDirection="column" alignItems="center">
                <PaginationInfo
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    filteredArray={filteredYleisöt}
                    array={yleisöt}
                />
                <Box display="flex" justifyContent="center" width="100%">
                    <Box width="740px" display="flex" justifyContent="center"> {/* Centered box with a specified width */}
                        <IconButton
                            size="small"
                            onClick={handlePreviousPage}
                            disabled={currentPage <= 1}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={handleNextPage}
                            disabled={currentPage >= totalPages}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
}

export default AudiencesList;