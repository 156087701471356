import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Button,
    Container,
    Grid, Box, Alert
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FormControlLabel from '@mui/material/FormControlLabel';
import EventList from './EventList';
import SearchModal from './SearchModal';
import Checkbox from '@mui/material/Checkbox';

import { selectCurrentUser } from '../../authentication/authSlice';

// This module contains the event feature.
// All the event related components and functions should be accessed
// through here. The event feature is displayed on /tapahtumat-route.

const Events = () => {

    const [showModal, setShowModal] = useState(false);
    const [copyCanceled, setCopyCanceled] = useState(false);
    const [copiedValue, setCopiedValue] = useState({});





    const searchButtonStyle = {
        mx: 1,
        px: 2,
        borderRadius: '10em',
        fontFamily: '"Open Sans", sans-serif',
        borderColor: 'taphaBlue.main',
        color: 'taphaBlue.main'
    };

    const addButtonStyle = {
        px: 2,
        backgroundColor: 'taphaBlue.main',
        borderRadius: '10em',
        fontFamily: '"Open Sans", sans-serif',
        borderColor: 'taphaGreen.main'
    };

    useEffect(() => {
        const previousValue = sessionStorage.getItem('copiedSessionValues');
        setCopiedValue(previousValue);
    }, []);


    const user = useSelector(selectCurrentUser);

    return (
        <Container
            maxWidth='lg'
            display='flex'
            sx={{
                justifyContent: 'center',
                mt: 2
            }}>
            <Grid container>

                <Grid item xs={12} display='flex' justifyContent='center'>

                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                onClick={() => setShowModal(true)}
                                sx={searchButtonStyle}
                                variant='outlined'
                                startIcon={<SearchIcon sx={{ color: 'taphaBlue.main' }} />}
                            >
          Hae tapahtumia
                            </Button>
                            {user.role === 'Admin' || user.role === 'Suunnittelija' || user.role === 'Muokkaaja' ? (
                                <>
                                    <Button
                                        variant='contained'
                                        component={Link}
                                        to='/tapahtumat/uusi'
                                        startIcon={<AddIcon sx={{ color: 'white' }} />}
                                        sx={addButtonStyle}
                                    >
              Lisää tapahtuma
                                    </Button>
                                </>
                            ) : (
                                <></>
                            )}
                        </Box>

                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <EventList/>
                </Grid>
            </Grid>

            <SearchModal isOpen={showModal} onClose={() => setShowModal(false)} />
        </Container>
    );

};

export default Events;