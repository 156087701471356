import { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';

const ConfirmationDialog = ({
    open,
    title,
    message,
    handleConfirm,
    handleCancel,
    confirmLabel,
    cancelLabel
}) => (
    <Dialog open={open} maxWidth='sm' fullWidth>
        <DialogTitle textAlign='center'>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText sx={{ p: 3 }}>{message}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly', p: 2 }}>
            <Button variant='contained' sx={{ backgroundColor: 'green' }} onClick={handleConfirm}>
                { confirmLabel ?? 'Vahvista' }
            </Button>
            <Button variant='contained' sx={{ backgroundColor: 'red' }} onClick={handleCancel}>
                { cancelLabel ?? 'Peruuta' }
            </Button>
        </DialogActions>
    </Dialog>
);

// A hook that returns true or false depending on the user's input in the
// confirmation dialog. Requires an async wrapper.

const useConfirmDialog = (props) => {
    const [promise, setPromise] = useState(null);

    const confirm = () => {
        return new Promise((resolve) => {
            setPromise(() => resolve);
        });
    };

    const handleConfirm = () => {
        promise?.(true);
        setPromise(null);
    };

    const handleCancel = () => {
        promise?.(false);
        setPromise(null);
    };

    // Returns a rendered ConfirmationDialog and the confirm function, used to
    // get the user's input by returning true or false.
    // Return the rendered ConfirmationDialog, so there will be no flickering
    // if used in a component that will rerender state when we use this hook.

    return [
        () => (
            <ConfirmationDialog
                { ...props }
                open={promise !== null}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
            />
        ),
        confirm
    ];
};


export default useConfirmDialog;