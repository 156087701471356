import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    IconButton,
    Snackbar
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const MessageSnackbar = ({ showSnackbar, msg, severity }) => {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (showSnackbar)
            setOpen(true);
    }, [showSnackbar]);


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={500000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            sx={{ width: 400 }}
        >
            <Alert
                severity={severity}
                action={
                    <IconButton onClick={handleClose} size='small'>
                        <CloseIcon />
                    </IconButton>
                }
                sx={{
                    width: '100%',
                    borderRadius: 3,
                    mb: 3,
                    '& .MuiAlert-message': {
                        flex: 1,
                        textAlign: 'center'
                    }
                }}>
                {msg}
            </Alert>
        </Snackbar>
    );
};

MessageSnackbar.propTypes = {
    open: PropTypes.bool,
    msg: PropTypes.string,
    variant: PropTypes.string
};

export default MessageSnackbar;