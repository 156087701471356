import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import {
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    List,
    ListItem,
    ListItemText,
    Alert
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const RadioGroupArray = ({ fieldName, subfieldName }) => {

    const { control } = useFormContext();
    const { fields } = useFieldArray({ name: fieldName });

    const options = [
        {
            value: -1,
            label: 'Kielteinen',
            color: '#cc3232'
        },
        {
            value: 0,
            label: 'Neutraali',
            color: '#E7B416'
        },
        {
            value: 1,
            label: 'Positiivinen',
            color: '#2dc937'
        }
    ];

    const radioGroups = fields.map((field, index) =>
        <ListItem key={field.id}>
            <ListItemText
                inset
                sx={{ width: '30%' }}
                primary={field.nimi}
                primaryTypographyProps={{
                    variant: 'h7',
                    fontFamily: '"Bree Serif", serif',
                    maxWidth: '5%'
                }}
            />
            <Controller
                control={control}
                name={`${fieldName}.[${index}].${subfieldName}`}
                render={({ field }) =>
                    <RadioGroup
                        { ...field }
                        sx={{
                            width: '100%',
                            flexDirection: 'row',
                            justifyContent: 'space-evenly'
                        }}>
                        {options.map(option =>
                            <FormControlLabel
                                sx={{ width: '20%' }}
                                key={option.value}
                                value={option.value}
                                label={ index === 0 ? option.label : ''} // Only add the labels for the first row.
                                labelPlacement='top'
                                control={
                                    <Radio
                                        checkedIcon={ <CheckCircleIcon sx={{ color: option.color }} />}
                                    />
                                }
                            />
                        )}
                    </RadioGroup>
                }
            />
        </ListItem>
    );

    return (
        <List >
            {!radioGroups.length && <Alert sx={{ borderRadius: 3 }}severity='info'>Tapahtumalla ei ollut yhteistyötahoja</Alert>}
            {radioGroups}
        </List>
    );
};

export default RadioGroupArray;