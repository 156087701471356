import { Outlet } from 'react-router-dom';

import Navbar from './Navbar';

const HomeLayout = () => {
    return (
        <>
            <Navbar />
            <div>
                <Outlet />
            </div>
        </>
    );
};

export default HomeLayout;