import { useState, useEffect } from 'react'; // Import useState
import { FixedSizeList } from 'react-window';
import PropTypes from 'prop-types';
import {
    Grid,
    InputAdornment,
    TextField,
    Typography,
    Button,
    Snackbar,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import throttle from 'lodash.throttle';
import useField from '../../hooks/useField';
import SearchListItem from './SearchListItem';
import dayjs from 'dayjs';
import 'dayjs/locale/fi'; // Import the Finnish locale for dayjs
import MuotoiltuListItem from './MuotoiltuListItem';
import { useDeleteEventMutation } from '../../api/eventsApiSlice';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../features/authentication/authSlice';

const ITEMS_PER_PAGE = 15; // Define the number of items per page

const SearchList = ({ data, title, color, listDimensions, primaryPropKey, secondaryPropKey, noPagination, muotoiltu, filterTime, deleteEvent, showPast }) => {
    const { setValue, ...searchField } = useField('text');
    const [currentPage, setCurrentPage] = useState(0); // Track the current page
    const [showCurrentAndFuture, setShowCurrentAndFuture] = useState(true);
    const [showMuotoiltu, setShowMuotoiltu] = useState(false);
    const [isTulevaOrMennyt, setIsTulevaOrMennyt ] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar

    const { role } = useSelector(selectCurrentUser);


    const currentDate = dayjs();
    const searchTerm = searchField.value?.replace(/\s/g, '').toLowerCase();

    let filteredData = data; // Initialize with unfiltered data


    filteredData = showPast ? data?.filter((item) => {
        const eventDate = dayjs(item.aloitus, 'DD.MM.YYYY HH:mm').locale('fi');
        return eventDate.isBefore(currentDate);
    }) : (showCurrentAndFuture || showMuotoiltu)
        ? data?.filter((item) => {
            const eventLopetusDate = dayjs(item.lopetus, 'DD.MM.YYYY HH:mm').locale('fi');
            const eventAloitusDate = dayjs(item.aloitus, 'DD.MM.YYYY HH:mm').locale('fi');
            return !eventLopetusDate.isBefore(currentDate) || eventAloitusDate.isBefore(currentDate) && !eventLopetusDate.isBefore(currentDate);
        })
        : data?.filter((item) => {
            const eventDate = dayjs(item.aloitus, 'DD.MM.YYYY HH:mm').locale('fi');
            return eventDate.isBefore(currentDate);
        });

    // Apply search term filtering if searchTerm is defined
    if (searchTerm) {
        filteredData = filteredData?.filter((item) => {
            const primaryProp = item[primaryPropKey].replace(/\s/g, '').toLowerCase();
            return primaryProp.includes(searchTerm.toLowerCase());
        });
    }

    if(filterTime) {filteredData?.sort((a, b) => {
        const eventA = dayjs(a.aloitus, 'DD.MM.YYYY HH:mm').valueOf();
        const eventB = dayjs(b.aloitus, 'DD.MM.YYYY HH:mm').valueOf();
        return eventA - eventB;
    });}




    const totalPages = Math.ceil(filteredData?.length / ITEMS_PER_PAGE); // Calculate the total number of pages


    const handleScroll = throttle(() => {
        // Handle scroll event here (if needed)
    }, 100);

    // Calculate the start and end indices for the current page
    const startIndex = currentPage * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    // Slice the data for the current page
    const dataForPage = filteredData?.slice(startIndex, endIndex);

    let content;


    const handleMuotoiltuListausButtonClick = () => {
        setShowMuotoiltu(true);
        setShowCurrentAndFuture(false);
        setIsTulevaOrMennyt(false);
    };

    const handleTulevatButtonClick = () => {
        setShowCurrentAndFuture(true);
        setCurrentPage(0);
        setShowMuotoiltu(false);
        setIsTulevaOrMennyt(true);
    };

    const handleMenneetButtonClick = () => {
        setShowCurrentAndFuture(false);
        setCurrentPage(0);
        setShowMuotoiltu(false);
        setIsTulevaOrMennyt(true);
    };

    const handleOpenSnackbar = (message) => {
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };



    const muotoiltuListDimensions={ height: 350, itemSize: 100 };

    if (!filteredData)
        content = (
            <Typography variant='overline'>
                Ei näytettäviä tuloksia
            </Typography>
        );
    else
        content = (
            <>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
                    {role !== 'Laskuttaja' || !showPast ? <>
                        <Button
                            key="future"
                            variant={(showCurrentAndFuture && isTulevaOrMennyt && !showMuotoiltu) ? 'contained' : 'outlined'}
                            onClick={handleTulevatButtonClick}
                            sx={{ marginX: '4px' }}>
                            Tulevat
                        </Button>
                        <Button
                            key="past"
                            variant={(!showCurrentAndFuture && isTulevaOrMennyt && !showMuotoiltu) ? 'contained' : 'outlined'}
                            onClick={handleMenneetButtonClick}
                            sx={{ marginX: '4px' }}>
                            Menneet
                        </Button></> : <></>}
                    {muotoiltu && <Button
                        variant={!showMuotoiltu ? 'outlined' : 'contained'}
                        onClick={handleMuotoiltuListausButtonClick}
                        sx={{ marginX: '4px' }}>
                        Muotoiltu listaus
                    </Button>}

                </div>
                {isTulevaOrMennyt ? (
                    <FixedSizeList
                        {...listDimensions}
                        itemCount={noPagination ? filteredData.length : dataForPage.length}
                        overscanCount={4}
                        onScroll={handleScroll}
                        itemData={noPagination ? filteredData : dataForPage}>
                        {({ data, index, style }) => (
                            <SearchListItem
                                deleteEvent={deleteEvent}
                                data={data[index]}
                                key={data.id}
                                style={style}
                                searchTerm={searchField.value}
                                primaryPropKey={primaryPropKey}
                                secondaryPropKey={secondaryPropKey}
                            />
                        )}
                    </FixedSizeList>
                ) : ( <><FixedSizeList
                    {...muotoiltuListDimensions}
                    itemCount={filteredData.length}
                    overscanCount={4}
                    onScroll={handleScroll}
                    itemData={filteredData}
                >
                    {({ data, index, style }) => (
                        <MuotoiltuListItem
                            handleOpenSnackbar={handleOpenSnackbar}
                            data={data[index]}
                            key={index}
                            style={style}
                        />
                    )}
                </FixedSizeList><Snackbar
                    open={snackbarOpen}
                    close={!snackbarOpen}
                    action={
                        <Button color="inherit" size="small" onClick={handleCloseSnackbar}>
                            Sulje
                        </Button>
                    }
                    autoHideDuration={6000} // Adjust as needed
                    onClose={() => setSnackbarOpen(false)}
                    message="Kopioitu leikepöydälle"
                /></>)}
            </>
        );

    // Function to handle page navigation
    const goToPage = (page) => {
        setCurrentPage(page);
    };

    return (
        <Grid container rowSpacing={3} mt={2} sx={{ backgroundColor: color, borderRadius: 3 }}>
            <Grid item xs={12}>
                <Typography fontFamily='"Bree Serif", serif' textAlign='center' variant='h6'>
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={12} display='flex' justifyContent='center'>
                <TextField
                    {...searchField}
                    variant='standard'
                    placeholder='Hae'
                    InputProps={{
                        startAdornment: (
                            <InputAdornment
                                sx={{
                                    color: 'taphaBlue.main',
                                    pr: 1,
                                }}
                                position='start'>
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={12} p={2} sx={{ textAlign: 'left', minHeight: listDimensions.height + 50 }}>
                {content}
                {/* Pagination buttons */}
                {noPagination !== true && <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                    {totalPages > 0 && [...Array(totalPages).keys()].map((page) => (
                        <Button
                            key={page}
                            variant={page === currentPage ? 'contained' : 'outlined'}
                            onClick={() => goToPage(page)}
                            sx={{ marginX: '4px' }}>
                            {page + 1}
                        </Button>
                    ))}
                </div>
                }
            </Grid>
        </Grid>
    );
};

SearchList.propTypes = {
    data: PropTypes.array,
    title: PropTypes.string,
    color: PropTypes.string,
    listDimensions: PropTypes.object,
    primaryPropKey: PropTypes.string,
    secondaryPropKey: PropTypes.string,
};

export default SearchList;
