import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const HelpIconWithTooltip = ({ text, iconSize, tooltipFontSize }) => {
    const iconStyle = {
        color: 'rgb(62,189,246)', // Light blue with 50% transparency
        fontSize: iconSize,
    };
    return (
        <Tooltip title={<span style={{ fontSize: tooltipFontSize }}>{text}</span>} arrow>
            <HelpOutlineIcon style={iconStyle} />
        </Tooltip>
    );
};

export default HelpIconWithTooltip;