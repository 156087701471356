import { useState } from 'react';

// A hook used for creating input fields. The return values
// can simply be destructured to the TextField-element,
// as the return values are named the same as the element's properties.

const useField = (type) => {

    const [value, setValue] = useState('');

    const onChange = (event) => setValue(event.target.value);

    // It's important that the return value is an object with property keys
    // matching TextField's properties.

    return {
        type,
        value,
        setValue,
        onChange
    };
};

export default useField;