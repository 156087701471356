import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';

const FormDatePicker = ({ fieldName }) => {

    const { control } = useFormContext();

    const datePickerStyle = {
        components: {
            OpenPickerIcon: CalendarMonthOutlinedIcon,
            LeftArrowIcon: ArrowLeftOutlinedIcon,
            RightArrowIcon: ArrowRightOutlinedIcon
        },
        InputProps: {
            sx: { '& .MuiSvgIcon-root': { color: 'taphaBlue.main' } }
        },
        PopperProps: {
            sx: {
                '& .MuiPaper-root': {
                    border: '1px solid',
                    borderColor:  'taphaBlue.main',
                    p: 2,
                    mt: 1
                }
            }
        }
    };

    return (
        <Controller
            name={fieldName}
            control={control}
            render={({ field, fieldState }) =>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fi'>
                    <DesktopDatePicker
                        { ...field }
                        { ...datePickerStyle }
                        label='Päivämäärä'
                        inputFormat='DD.MM.YYYY'
                        renderInput={(params) =>
                            <TextField
                                { ...params }
                                inputProps={{
                                    ...params.inputProps,
                                    placeholder: 'pp.kk.vvvv'
                                }}
                                error={!!fieldState.error}
                                helperText={fieldState?.error?.message ?? ''} // Default empty string, so no need to set paddings etc when there is an error message.
                                autoComplete='off'
                            />
                        }
                    />
                </LocalizationProvider>
            }
        />
    );
};

FormDatePicker.propTypes = {
    fieldName: PropTypes.string
};

export default FormDatePicker;