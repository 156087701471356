import { useState } from 'react';
import { Grid, Typography, Box, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormTextField from '../../components/FormFields/FormTextField';
import { useAddNewPersonMutation  } from '../../api/peopleApiSlice';
import UpdateButton from './adminUtils/updateButton';
import HenkiloImageUploader from '../../features/events/components/EventForm/ImageUploader/HenkiloImageUploader';
import SearchBar from './adminUtils/SearchBar';
import FormSelectMultiple from '../../features/events/components/EventForm/FormSelectMultiple';
import { useGetYhteystiedotQuery } from '../../api/yhteystiedotApiSlice';
import PaginationInfo from './PaginationInfo';

function PeopleList({ people, handleDeletePerson, handleUpdatePerson, fetchData, onSuccess, onError }) {
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [newPersonData, setNewPersonData] = useState({ nimi: '', email: '', titteli: '', puhelinnumero: '', puhelinnumero2: '', yhteystiedot: [], henkilokuva: null, tyonkuva: '' });
    const [addPersonData, setAddPersonData] = useState({ nimi: '', email: '', titteli: '', puhelinnumero: '', puhelinnumero2: '', yhteystiedot: [], henkilokuva: null, tyonkuva: '' });
    const [showAddForm, setShowAddForm] = useState(false);
    const [addNewPerson, { isLoading }] = useAddNewPersonMutation();
    const [currentPage, setCurrentPage] = useState(1);
    const { data: yhteystiedot, error, isLoading: yhteystiedotLoading, isSuccess  } = useGetYhteystiedotQuery();
    const yhteystietoArray = yhteystiedot?.entities
        ? Object.values(yhteystiedot.entities).map(item => item.yhteystieto)
        : [];
    const itemsPerPage = 10;
    const handleUpdateNewPersonData = (selectedItems) => {
        setNewPersonData((prevState) => ({
            ...prevState,
            yhteystiedot: selectedItems
        }));
    };

    const handleUpdateAddPersonData = (selectedItems) => {
        setAddPersonData((prevState) => ({
            ...prevState,
            yhteystiedot: selectedItems
        }));
    };




    // Get the slice of luonteet for the current page

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
        setCurrentPage(1); // Reset to the first page when searching
    };

    // Apply the search filter
    const filteredPeople = people.filter((Person) =>
        Person.nimi.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const totalPages = Math.ceil(filteredPeople.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedPeople = filteredPeople.slice(startIndex, endIndex);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };


    const handleUserClick = (person) => {
        setShowAddForm(false);
        setAddPersonData({ nimi: '', email: '', titteli: '', puhelinnumero: '', puhelinnumero2: '', yhteystiedot: [], henkilokuva: null, tyonkuva: '' });
        if(selectedPerson?.id === person.id) {setSelectedPerson(null);
            setNewPersonData({ nimi: '', email: '', titteli: '', puhelinnumero: '', puhelinnumero2: '', yhteystiedot: [], henkilokuva: null, tyonkuva: '' });
            return;}
        setSelectedPerson(person);
        setNewPersonData(person);
    };

    const handleAddClick = () => {
        setShowAddForm(!showAddForm);
        setSelectedPerson(null);
        setNewPersonData({ nimi: '', email: '', titteli: '', puhelinnumero: '', puhelinnumero2: '', yhteystiedot: [], henkilokuva: null, tyonkuva: '' });
    };

    const handleImageChange = (imageData) => {
        // Update the necessary state or perform the POST call with the image data

        if(selectedPerson) {setNewPersonData((prevState) => ({ ...prevState, henkilokuva: imageData }));}
        else setAddPersonData((prevState) => ({ ...prevState, henkilokuva: imageData }));

        // Perform the POST call here
    };

    const handleLisääPerson = async(person) => {
        try {
            const result = await addNewPerson(person);
            if(result.error) onError('Henkilön luominen epäonnistui');
            if(!result.error) onSuccess('Henkilön luominen onnistui.', 'henkilot', result.data.id);
            setAddPersonData({ nimi: '', email: '', titteli: '', puhelinnumero: '', puhelinnumero2: '', yhteystiedot: [], henkilokuva: null, tyonkuva: '' });
            setShowAddForm(false);
            fetchData();
        } catch (error) {
            console.error(error); // handle error
        }};

    const firstPersonIndex = (currentPage - 1) * itemsPerPage + 1;
    const lastPersonIndex = Math.min(firstPersonIndex + itemsPerPage - 1, filteredPeople.length);


    return (
        <Grid container spacing={2}>
            <Box mt={3} ml={2}> {/* Add spacing to separate SearchBar */}
                <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
            </Box>
            {displayedPeople.map((person, index) => (
                <Grid key={person.id} item xs={12} >
                    <Box key={person.id} onClick={() => handleUserClick(person)} sx={{
                        backgroundColor: 'taphaWhite.main',
                        borderRadius: 5,

                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginTop: 0.6,
                        gap: 5,
                        '&:hover': {
                            border: '1px solid',
                            borderLeft: '30px solid',
                            transition: '0.4s',
                            borderColor: 'taphaGreen.main',
                            cursor: 'pointer'
                        }
                    }}>
                        <Typography variant="h6">
                            {person.nimi}
                        </Typography>
                        <IconButton onClick={(event) => {
                            handleDeletePerson(event, person);
                            if (selectedPerson && selectedPerson.id === person.id) {
                                setSelectedPerson(null);
                                setNewPersonData({ nimi: '', email: '', titteli: '', puhelinnumero: '', puhelinnumero2: '', yhteystiedot: [], henkilokuva: null, tyonkuva: '' });
                            }
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                    {selectedPerson && selectedPerson.id === person.id && (
                        <Grid container>

                            <Grid item xs={8} sx={{ pt: 2 }}>
                                <FormTextField

                                    fieldName='nimi'
                                    label='nimi'
                                    multiline={true}
                                    fullWidth={true}
                                    value={newPersonData.nimi}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setNewPersonData((prevState) => ({ ...prevState, [name]: value }));
                                    }}

                                />
                            </Grid>
                            <Grid item xs={8} sx={{ pt: 2 }}>
                                <FormTextField

                                    fieldName='puhelinnumero'
                                    label='puhelinnumero'
                                    multiline={true}
                                    fullWidth={true}
                                    value={newPersonData.puhelinnumero}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setNewPersonData((prevState) => ({ ...prevState, [name]: value }));
                                    }}

                                />
                            </Grid>
                            <Grid item xs={8} sx={{ pt: 2 }}>
                                <FormTextField

                                    fieldName='tyonkuva'
                                    label='työnkuva'
                                    multiline={true}
                                    fullWidth={true}
                                    value={newPersonData.tyonkuva}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setNewPersonData((prevState) => ({ ...prevState, [name]: value }));
                                    }}

                                />
                            </Grid>
                            <Grid item xs={8} sx={{ pt: 2 }}>
                                <FormTextField

                                    fieldName='puhelinnumero2'
                                    label='2. puhelinnumero'
                                    multiline={true}
                                    fullWidth={true}
                                    value={newPersonData.puhelinnumero2}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setNewPersonData((prevState) => ({ ...prevState, [name]: value }));
                                    }}

                                />
                            </Grid>
                            <Grid item xs={8} sx={{ pt: 2 }}>
                                <FormSelectMultiple

                                    fieldName='yhteystiedot'
                                    label='yhteystiedot'
                                    existingYhteystiedot={newPersonData.yhteystiedot}
                                    onUpdate={handleUpdateNewPersonData}
                                    options={yhteystietoArray}
                                    multiline={true}
                                    fullWidth={true}

                                />
                            </Grid>
                            <Grid item xs={8}>
                                <FormTextField
                                    error={false}

                                    fieldName='email'
                                    label='email'
                                    multiline={true}
                                    fullWidth={true}
                                    value={newPersonData.email}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setNewPersonData((prevState) => ({ ...prevState, [name]: value }));
                                    }}
                                />
                                <FormTextField
                                    error={false}

                                    fieldName='titteli'
                                    label='titteli'
                                    multiline={true}
                                    fullWidth={true}
                                    value={newPersonData.titteli}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setNewPersonData((prevState) => ({ ...prevState, [name]: value }));
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant='h6'> Henkilökuva</Typography>
                                <HenkiloImageUploader fieldName='henkilokuva' onImageChange={handleImageChange} image={newPersonData.henkilokuva} />
                            </Grid>

                            <UpdateButton handleUpdate={handleUpdatePerson} data={newPersonData}/>
                        </Grid>
                    )}
                </Grid>
            ))}
            {showAddForm && (
                <Box sx={{
                    flexGrow: 1, // Add flexGrow to make the box grow
                }}>
                    <Typography variant="h6" sx={{ pt: 2 }}>Henkilön lisääminen</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <FormTextField
                                key="addPersonNimi"
                                fieldName='nimi'
                                label='nimi'
                                multiline={true}
                                fullWidth={true}
                                value={addPersonData.nimi}
                                onChange={(event) => setAddPersonData({ ...addPersonData, nimi: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormTextField
                                key="addPersonPuhelinnumero"
                                fieldName='puhelinnumero'
                                label='puhelinnumero'
                                multiline={true}
                                fullWidth={true}
                                value={addPersonData.puhelinnumero}
                                onChange={(event) => setAddPersonData({ ...addPersonData, puhelinnumero: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormTextField
                                key="addPersonPuhelinnumero2"
                                fieldName='puhelinnumero2'
                                label='2. puhelinnumero'
                                multiline={true}
                                fullWidth={true}
                                value={addPersonData.puhelinnumero2}
                                onChange={(event) => setAddPersonData({ ...addPersonData, puhelinnumero2: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormTextField
                                key="addPersonTyonkuva"
                                fieldName='työnkuva'
                                label='työnkuva'
                                multiline={true}
                                fullWidth={true}
                                value={addPersonData.tyonkuva}
                                onChange={(event) => setAddPersonData({ ...addPersonData, tyonkuva: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormSelectMultiple
                                key="addPersonYhteystiedot"
                                fieldName='addyhteystiedot'
                                existingYhteystiedot={addPersonData.yhteystiedot}
                                label='Yhteystiedot'
                                onUpdate={handleUpdateAddPersonData}
                                options={yhteystietoArray}
                                multiline={true}
                                fullWidth={true}
                                sx={{ width: '100%' }}
                            />
                        </Grid>


                        <Grid item xs={8}>
                            <Typography variant='h6'> Henkilökuva</Typography>
                            <HenkiloImageUploader fieldName='henkilokuva' onImageChange={handleImageChange}/>
                        </Grid>
                        <Grid item xs={8}>
                            <FormTextField
                                error={false}
                                key="addPersonEmail"
                                fieldName='email'
                                label='email'
                                multiline={true}
                                fullWidth={true}
                                value={addPersonData.email}
                                onChange={(event) => setAddPersonData({ ...addPersonData, email: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                            <FormTextField
                                error={false}
                                key="addPersonTitteli"
                                fieldName='titteli'
                                label='titteli'
                                multiline={true}
                                fullWidth={true}
                                value={addPersonData.titteli}
                                onChange={(event) => setAddPersonData({ ...addPersonData, titteli: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Box display="flex" justifyContent="flex-end" width="100%" pt={1}>

                            <Button
                                onClick={() => handleLisääPerson(addPersonData)}
                                size="small"
                                variant="contained"
                                startIcon={<AddIcon />}
                                sx={{ backgroundColor: 'taphaGreen.main' }}
                            >
          Lisää henkilö
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            )}
            {showAddForm ? (
                <Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={handleAddClick}
                        size='small'
                        variant='contained'
                        startIcon={<CloseIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Sulje
                    </Button>
                </Box>
            ) :
                (<Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={handleAddClick}
                        size='small'
                        variant='contained'
                        startIcon={<AddIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Lisää
                    </Button>
                </Box>)}
            {/* Pagination arrows */}
            <Box mt={2} ml={2} display="flex" flexDirection="column" alignItems="center">
                <PaginationInfo
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    filteredArray={filteredPeople}
                    array={people}
                />
                <Box display="flex" justifyContent="center" width="100%">
                    <Box width="740px" display="flex" justifyContent="center"> {/* Centered box with a specified width */}
                        <IconButton
                            size="small"
                            onClick={handlePreviousPage}
                            disabled={currentPage <= 1}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={handleNextPage}
                            disabled={currentPage >= totalPages}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>

        </Grid>
    );
}

export default PeopleList;