import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

const FormCheckboxGroup = ({ fieldName, options, defaultValue }) => {
    const { control } = useFormContext();

    return (
        <FormControl sx={{ width: '100%' }}>
            <FormLabel>{fieldName}</FormLabel>
            <Controller
                name={fieldName}
                control={control}
                defaultValue={defaultValue || []}
                render={({ field }) => (
                    <FormGroup>
                        {options?.map(option => (
                            <FormControlLabel
                                key={option}
                                label={option.charAt(0).toUpperCase() + option.slice(1)}
                                control={
                                    <Checkbox
                                        name={fieldName}
                                        value={option}
                                        checked={field.value.includes(option)} // Check if option is in field.value array
                                        onChange={(e) => {
                                            const { checked, value } = e.target;
                                            const updatedValues = checked
                                                ? [...field.value, value] // Add option if checked
                                                : field.value.filter(val => val !== value); // Remove option if unchecked
                                            field.onChange(updatedValues);
                                        }}
                                    />
                                }
                            />
                        ))}
                    </FormGroup>
                )}
            />
        </FormControl>
    );
};

export default FormCheckboxGroup;