import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
    Box,
    Button,
    Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import AddressCard from './AddressCard';
import AddressFields from './AddressFields';
import RegionalPlaces from './RegionalPlaces';

const RegionalSelection = ({ centerName, fieldName, showDropdowns }) => {

    const {
        setValue,
        defaultValues,
        formState: { errors },
        clearErrors
    } = useFormContext();


    let content = !showDropdowns
        && <RegionalPlaces centerName={centerName} fieldName={fieldName} />;

    return (
        <>
            {content}
            {!showDropdowns && <AddressCard fieldName={fieldName} errorState={!!errors[fieldName]} />}
        </>
    );
};

export default RegionalSelection;