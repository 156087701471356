import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';

// Component only used for adding the description to events, where the selected
// center is 'Ystävämatkat'. This center is different from the others, as it
// doesn't require any address details, but only a description field.

const DescriptionField = ({ centerName, fieldName, descriptionField }) => {

    const {
        register,
        unregister,
        setValue,
        defaultValues,
        getFieldState
    } = useFormContext();

    // Full path to the description field.
    const descFieldPath = `${fieldName}.${descriptionField}`;

    // Only ran on initial render.

    useEffect(() => {

        // Save the center type to the form state.
        setValue(fieldName, ({
            ...defaultValues.paikka,
            keskus: centerName
        }));

        // Unregister in clean up.
        return () => unregister(descFieldPath);
    }, []);

    const error = getFieldState(descFieldPath)?.error;

    // Return a regular TextField here instead of our FormTextField wrapper.
    // The reason for this is that the FormTextField uses RHF's Controller,
    // which expects the default value to be found in the defaultValues
    // provided to useForm in NewEventForm.js. We don't want to add the
    // kuvaus-field there, as it is only used for Ystävämatkat.

    return (
        <TextField
            { ...register(descFieldPath) }
            sx={{ pt: 3 }}
            multiline
            placeholder='Tämä keskus ei tarvitse osoitetietoja. Kirjoita tähän kuvaus suunnittelemastasi matkasta.'
            minRows={7}
            fullWidth
            spellCheck={false}
            autoComplete='off'
            error={!!error}
            helperText={error?.message ?? ' '} // Empty string as default, so need to adjust padding etc for the error message.
            FormHelperTextProps={{ variant: 'standard' }}
        />
    );
};

export default DescriptionField;