import { useFormContext, useWatch, useFieldArray } from 'react-hook-form';
import AutocompleteDropdown from './EventForm/Places/AutocompleteDropdown';
import { Button, Box, IconButton, ListItem } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const Vastuuhenkilöt = ({ fieldName, people }) => {
    const {
        clearErrors,
        setValue,
        getValues,
        trigger,
        formState: { errors },
    } = useFormContext();

    const {
        fields,
        append,
        remove,
    } = useFieldArray({ name: fieldName });

    const formValues = useWatch({
        name: fieldName,
        defaultValue: [],
    });

    const newElement = (name) => {
        return { henkilo: name };
    };

    const handlePersonChange = async (newPerson) => {
        clearErrors(fieldName);
        const currentValues = getValues(fieldName) || [];
        const newValues = [...currentValues, newElement(newPerson)];
        setValue(fieldName, newValues);
        await trigger(fieldName); // Trigger validation (if needed)
    };

    const handleRemovePerson = (index) => {
        const currentValues = getValues(fieldName) || [];
        const newValues = [...currentValues];
        newValues.splice(index, 1);
        setValue(fieldName, newValues);
    };

    return (
        <div style={{ marginBottom: '20px' }}>
            <AutocompleteDropdown
                options={people}
                onChange={handlePersonChange}
                label='Hae vastuuhenkilöä'
                noOptionsText='Vastuuhenkilöä ei löytynyt'
            />
            {errors[fieldName] && (
                <div style={{ color: 'red' }}>{errors[fieldName]?.message}</div>
            )}
            <div>
                {/* Display the selected people */}
                {formValues.map((entry, index) => (
                    <ListItem
                        key={index}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            p: 1,
                            mt: 2.5,
                            height: '40px',
                            width: '100%', // Make the ListItem full width
                            borderRadius: '5px',
                            justifyContent: 'space-between', // Align items and the clear icon
                            backgroundColor: 'taphaWhite.main'
                        }}
                    >
                        <div>{entry.henkilo}</div>
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => { remove(index); }}
                        >
                            <ClearIcon sx={{ color: 'taphaBlue.dark' }} />
                        </IconButton>
                    </ListItem>
                ))}
            </div>
        </div>
    );
};

export default Vastuuhenkilöt;