import { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Alert,
    Button,
    Divider,
    Grid,
    Typography,
    useMediaQuery
} from '@mui/material';
import dayjs from 'dayjs';

import useEventsData from '../../hooks/useEventsData';
import { selectAllEventTypes } from '../../../../api/eventTypesApiSlice';
import { selectAllCenterNames } from '../../../../api/centersApiSlice';
import { selectAllRegionsObject } from '../../../../api/regionsApiSlice';
import { selectAllAudiences } from '../../../../api/audiencesApiSlice';
import DropdownFilter from '../../../../components/DropdownFilter';
import SearchList from '../../../../components/SearchList';
import ProgressLoader from '../../../../components/ProgressLoader';
import DatePicker from './DatePicker';
import { selectCurrentUser } from '../../../authentication/authSlice';
import { selectAllPeopleNames } from '../../../../api/peopleApiSlice';
import DropdownFilterVastuuhenkilo from '../../../../components/DropDownFilterVastuuhenkilo';
import { useDeleteEventMutation } from '../../../../api/eventsApiSlice';

const ModalContent = () => {

    // Fetch all events from the previous 10 years, as well as future events.
    const { events, finished, setDeletedEventId, refetch } = useEventsData({ pageSize: 200, years: 10 });
    const { role, name } = useSelector(selectCurrentUser);
    const [refreshFlag, setRefreshFlag] = useState(false);




    // Attributes to filter for, received from the backend through cache.
    const eventTypes = useSelector(selectAllEventTypes);
    const centers = useSelector(selectAllCenterNames);
    const regions = useSelector(selectAllRegionsObject);
    const audiences = useSelector(selectAllAudiences);
    const people = useSelector(selectAllPeopleNames);

    const dateFormat = 'DD.MM.YYYY';

    const isScreenGreaterThan600px = useMediaQuery('(min-width: 600px)');

    const defaultFilterState = {
        luonne: '',
        maakunta: '',
        kunta: '',
        keskus: '',
        vastuuhenkilo: '',
        aloitus: null,
        kohdeyleiso: '',
    };
    const [filterState, setFilterState] = useState(defaultFilterState);

    const [deleteEvent, { isLoading }] = useDeleteEventMutation();

    // Handle filter change for all filters by using the event targets name as
    // the key in filterState.

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilterState(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const deleteEventById = async(event) => {
        try {

            deleteEvent(event);
            setDeletedEventId(event.id);
            setRefreshFlag(prevFlag => !prevFlag);
        } catch(error){
            console.log(error);
        }
    };

    // Create test conditions for Array.filter and apply all of them at once.
    // Immediately return false once any of the conditions are not met.

    const applyFilters = (event) => {
        if (event.status === 'luonnos') {
            return false; // Exclude events with status 'luonnos'
        }

        if (filterState.luonne && !event?.luonteet?.includes(filterState.luonne))
            return false;

        if (filterState.vastuuhenkilo && !event?.vastuuhenkilot?.some(person => person.henkilo === filterState.vastuuhenkilo))
            return false;

        if (filterState.keskus && event?.paikka?.keskus !== filterState.keskus)
            return false;

        if (filterState.maakunta && event?.paikka?.maakunta !== filterState.maakunta)
            return false;

        if (filterState.kunta && event?.paikka?.kunta !== filterState.kunta)
            return false;


        // Check if the formatted date of an event is the same as the date
        // inside the filterState.
        if (filterState.aloitus && filterState.aloitus.isValid() &&
            dayjs(event?.aloitus, dateFormat).format(dateFormat) !== filterState.aloitus.format(dateFormat))
            return false;

        if (filterState.kohdeyleiso && !event?.kohdeyleiso.includes(filterState.kohdeyleiso))
            return false;

        return true;
    };



    const filteredEvents = events?.filter(applyFilters);

    useEffect(() => {
        refetch();
    }, [refreshFlag, refetch]);

    let content;

    if (!finished)
        content = <ProgressLoader msg='Ladataan tapahtumia...' />;

    else
        content = (
            <SearchList
                deleteEvent={deleteEventById}
                noPagination={true}
                muotoiltu={true}
                filterTime={true}
                data={filteredEvents}
                title={'Hae tapahtumia'}
                color='taphaBlue.light'
                listDimensions={{ height: 350, itemSize: 55 }}
                primaryPropKey='nimi'
                secondaryPropKey='date.dateString'
            />
        );

    return (
        <Grid
            container
            maxWidth='lg'
            margin='auto'
            sx={!isScreenGreaterThan600px ? {
                position: 'fixed',
                top: '10%',
                bottom: '10%',
                overflowY: 'auto',
            } : {}}
            columnSpacing={2}
            p={2}>
            <Grid item xs={12} sm={7} sx={{
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                {content}
            </Grid>
            <Grid item xs={12} sm={5} mt={2} sx={{}}>
                <Grid container columnSpacing={3} rowSpacing={3} px={2}>
                    <Grid item xs={12} mt={2}>
                        <Typography fontFamily='"Bree Serif", serif' textAlign='center' variant='h6'>
                            Suodattimet
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <Divider>
                            <Typography fontFamily='"Bree Serif", serif' textAlign='center' variant='h7'>
                            Paikka
                            </Typography>
                        </Divider>
                    </Grid>
                    <Grid item xs={12}>
                        <DropdownFilter
                            label='Keskus'
                            name='keskus'
                            variant='standard'
                            options={centers}
                            onChange={handleFilterChange}
                            value={filterState.keskus}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DropdownFilter
                            label='Maakunta'
                            name='maakunta'
                            variant='standard'
                            onChange={handleFilterChange}
                            options={Object.keys(regions)}
                            value={filterState.maakunta}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DropdownFilter
                            label='Kunta'
                            name='kunta'
                            variant='standard'
                            onChange={handleFilterChange}
                            options={regions[filterState.maakunta] ?? []} // Have to give a fallback here incase the region isn't selected yet.
                            value={filterState.kunta}
                            fullWidth />
                    </Grid>

                    <Grid item xs={12} mt={2}>
                        <Divider>
                            <Typography fontFamily='"Bree Serif", serif' textAlign='center' variant='h7'>
                                Aika
                            </Typography>
                        </Divider>
                    </Grid>
                    <Grid item xs={12}>
                        <DatePicker
                            value={filterState.aloitus}
                            onChange={(newDate) => setFilterState(prev => ({ // DatePicker requires it's own onChange handler,
                                ...prev,                                     // as it doesn't fire an event on change. Also convert
                                aloitus: dayjs(newDate, dateFormat)          // the newDate to a Dayjs object before setting it to filter state.
                            }))}
                            fullWidth
                            format={dateFormat}
                            variant='standard'
                        />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <Divider>
                            <Typography fontFamily='"Bree Serif", serif' textAlign='center' variant='h7'>
                                Muut
                            </Typography>
                        </Divider>
                    </Grid>
                    <Grid item xs={4}>
                        <DropdownFilter
                            label='Luonne'
                            name='luonne'
                            variant='standard'
                            options={eventTypes}
                            onChange={handleFilterChange}
                            value={filterState.luonne}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DropdownFilter
                            label='Kohdeyleisö'
                            name='kohdeyleiso'
                            variant='standard'
                            options={audiences}
                            onChange={handleFilterChange}
                            value={filterState.kohdeyleiso}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DropdownFilterVastuuhenkilo
                            label='Vastuuhenkilö'
                            name='vastuuhenkilo'
                            placeholdervastuuhenkilo={name}
                            variant='standard'
                            options={people}
                            onChange={handleFilterChange}
                            value={filterState.vastuuhenkilo}
                            fullWidth
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        display='flex'
                        justifyContent='center'
                    >
                        <Button
                            variant='contained'
                            sx={{ backgroundColor: 'taphaBlue.main' }}
                            onClick={() => setFilterState(defaultFilterState)}
                        >
                            Tyhjennä valinnat
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ModalContent;