import { useSelector } from 'react-redux';
import { useWatch } from 'react-hook-form';
import { Grid, MenuItem, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import FormTextField from '../../../../../components/FormFields/FormTextField';
import { selectAllRegionsObject } from '../../../../../api/regionsApiSlice';
import AutocompleteDropdown from './AutocompleteDropdown';

// Controlled address fields for adding a new place.

const AddressFields = ({ fieldName, centers, selectedCenter, handleCenterChange }) => {

    // Here we can just operate on strings, as we don't need to select any
    // places.

    const formValues = useWatch({ name: fieldName });

    const regions = useSelector(selectAllRegionsObject);

    const regionOptions = Object?.keys(regions) || [];
    regionOptions.sort((a, b) => a.localeCompare(b, 'fi'));

    const selectedRegion = formValues?.maakunta || '';

    const municipalityOptions = regions?.[selectedRegion] || [];

    const selectedMunicipality = formValues?.kunta || '';

    const textFieldStyle = {
        variant: 'outlined',
        size: 'medium',
        fullWidth: true
    };

    return (
        selectedCenter?.nimi !== 'Ystävämatkat' ? (<Grid container columnSpacing={2} pt={3}>
            <Typography sx={{ pl: 2, pb: 2 }} variant="h6">
      Paikan lisääminen
            </Typography>
            <Grid item xs={12}>
                <FormTextField
                    fieldName={`${fieldName}.nimi`}
                    label='Paikan nimi'
                    { ...textFieldStyle }
                />
            </Grid>
            <Grid item xs={12} mb={2}>
                <AutocompleteDropdown
                    // No error displays on this dropdown, as it might be confusing.
                    options={centers}
                    value={selectedCenter}
                    onChange={handleCenterChange}
                    label='Keskus'
                    noOptionsText='Keskuksia ei löytynyt'
                />
            </Grid>
            <Grid item xs={6}>
                <FormTextField
                    select
                    fieldName={`${fieldName}.maakunta`}
                    label='Maakunta'
                    { ...textFieldStyle }
                    value={selectedRegion}
                    helperText='Valitse maakunta nähdäksesi sen kunnat'
                >
                    {regionOptions?.map(region =>
                        <MenuItem key={region} value={region}>
                            {region}
                        </MenuItem>
                    )}
                </FormTextField>
            </Grid>
            <Grid item xs={6}>
                <FormTextField
                    fieldName={`${fieldName}.kunta`}
                    label='Kunta'
                    { ...textFieldStyle }
                    value={selectedMunicipality}
                    select
                >
                    {municipalityOptions?.map(municipality =>
                        <MenuItem key={municipality} value={municipality}>
                            {municipality}
                        </MenuItem>
                    )}
                </FormTextField>
            </Grid>
            <Grid item xs={12}>
                <FormTextField
                    fieldName={`${fieldName}.osoite`}
                    label='Osoite'
                    { ...textFieldStyle }
                />
            </Grid>
            <Grid item xs={6}>
                <FormTextField
                    fieldName={`${fieldName}.postinumero`}
                    label='Postinumero'
                    { ...textFieldStyle }
                />
            </Grid>
            <Grid item xs={6}>
                <FormTextField
                    fieldName={`${fieldName}.postitoimipaikka`}
                    label='Postitoimipaikka'
                    { ...textFieldStyle }
                />
            </Grid>
            <Typography variant='caption' mr={2} pl={2}>
                    Lisäämäsi paikka tallennetaan kun tallennat tapahtuman
            </Typography>
        </Grid>) : <></>
    );
};

AddressFields.propTypes = {
    fieldName: PropTypes.string,
    details: PropTypes.object
};

export default AddressFields;