import FormTextField from '../../../components/FormFields/FormTextField';
import { Grid } from '@mui/material';
const renderedItem = ({ nameVal, valFirst, settingFunction }) => {
    return (
        <Grid item xs={8}>
            <FormTextField

                fieldName={nameVal}
                label={nameVal}
                multiline={true}
                fullWidth={true}

                value={valFirst[nameVal]}
                onChange={(event) => {
                    const { name, value } = event.target;
                    settingFunction((prevState) => ({ ...prevState, [name]: value }));
                }}

            />
        </Grid>
    );
};

export default renderedItem;