import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext, useWatch } from 'react-hook-form';
import { Stack } from '@mui/material';

import AutocompleteDropdown from './AutocompleteDropdown';
import { selectMunicipalitiesByRegion } from '../../../../../api/regionsApiSlice';
import { selectPlaceEntities } from '../../../../../api/placesApiSlice';

/*
    This component operates entirely on fieldName's form values. It should always
    point to the field with a Paikka-object from the backend within it. The
    reason we use the form values instead of for example React State is that we
    want to be able to read in data easily as well and preselect the dropdowns
    for the user.

    The ENTIRE component operates based on the Paikka-objects maakunta-, kunta-,
    and id-fields.
    - the maakunta-field value decides the selectedRegion
    - the kunta-field value decides the selectedMunicipality
    - the id-field tells us if a valid place is selected.
*/

const RegionalPlaces = ({ centerName, fieldName }) => {

    const places = useSelector(selectPlaceEntities);
    const regions = useSelector(selectMunicipalitiesByRegion);


    regions.sort((a, b) => {
        const nameA = a.nimi.toLowerCase();
        const nameB = b.nimi.toLowerCase();
        return nameA.localeCompare(nameB, 'fi');
    });



    const {
        defaultValues,
        setValue,
        getFieldState,
        trigger
    } = useFormContext();

    const error = getFieldState(fieldName)?.error;
    let placesArray;
    if(places) placesArray = Object.values(places);

    // Start by retrieving the form Values for the field.

    const placeInForm = useWatch({
        name: fieldName,
        default: null // Return null on initial render.
    });

    // If we have a valid place selected, find the correct region to prefill the
    // regions-AutocompleteDropdown with value and options. The selected region
    // is stored within the maakunta-field.

    const selectedRegion = placeInForm && regions.find(r =>
        r.nimi === placeInForm.maakunta
    );

    // Find the correct municipality to do same for the municipalities. The
    // selected municipality is stored within the kunta-field.

    const municipalities = selectedRegion && selectedRegion.kunnat;
    const sortedMunicipalities = selectedRegion && selectedRegion.kunnat
        ? [...selectedRegion.kunnat].sort((a, b) => {
            const nameA = a.nimi.toLowerCase();
            const nameB = b.nimi.toLowerCase();
            return nameA.localeCompare(nameB, 'fi');
        })
        : [];

    const selectedMunicipality = municipalities && municipalities.find(m =>
        m.nimi === placeInForm.kunta
    );

    // Find all the places within the selectedMunicipality to fill the
    // places-AutocompleteDropdown. SelectedPlace is found based on the "id"-
    // field, which only valid places have.

    const placesInMunicipality = selectedMunicipality && selectedMunicipality.paikat
        .filter(placeId => placesArray.some(place => place.id === placeId))
        .map(placeId => placesArray.find(place => place.id === placeId));

    // Sort placesInMunicipality by the 'nimi' property if there are more than one place
    if (placesInMunicipality?.length > 1) {
        placesInMunicipality?.sort((a, b) => {
            const nameA = a.nimi.trim().toLowerCase();
            const nameB = b.nimi.trim().toLowerCase();
            return nameA.localeCompare(nameB, 'fi');
        });
    }



    // Check if the selected place for this event has been deleted from the
    // backend.

    const placeInFormIsDeleted = placesInMunicipality
        && placeInForm.id
        && !placesInMunicipality.find(p => p.id === placeInForm.id);

    // If it has been deleted, concat it into the places array so it will show
    // up as selected to let the user know a place has been selected and they
    // don't need to select another one.

    // If the user selects another place, the deleted place will be lost. It
    // should be ok though, as only duplicate places should be removed.

    const placesToShow = placeInFormIsDeleted
        ? placesInMunicipality.concat(placeInForm)
        : placesInMunicipality;

    const selectedPlace = placesToShow && placesToShow
        .find(p => p.id === placeInForm.id);

    // On change handler. Always keep track of the selected Center, as it
    // decides which child component is rendered in Places.js. Spread
    // defaultValues along with the piece of form state to update.

    const handleChange = (updatedState) => {
        setValue(fieldName, ({
            ...defaultValues.paikka,
            ...updatedState,
            keskus: centerName
        }));
    };

    // In the handlers we always have to take clear into account. if new value
    // is null, clear below the selected level in the cascading dropdowns.
    // So for clearing regions, we also have to clear municipality and place.
    // For clearing municipalities, we have to clear place selection, but
    // we can keep the selectedRegion in the form.

    const handleRegionChange = (newRegion) => {
        if (!newRegion)
            handleChange(); // Clear the entire formField to defaultValues, apart from centerName.
        else
            handleChange({ maakunta: newRegion.nimi });
    };

    const handleMunicipalityChange = (newMunicipality) => {
        if (!newMunicipality)
            handleChange({ maakunta: selectedRegion.nimi }); // Keep the selectedRegion within the form.
        else
            handleChange({ maakunta: selectedRegion.nimi, kunta: newMunicipality.nimi });
    };

    const handlePlaceChange = (newPlace) => {
        if (!newPlace)
            handleChange({
                kunta: selectedMunicipality.nimi, // on place clear, we can keep both municipality and region selected.
                maakunta: selectedRegion.nimi
            });
        else {
            handleChange({ ...newPlace });
            trigger(fieldName); // Trigger validation on place selection
        }
    };

    return (
        <Stack pt={2} rowGap={2}>
            <AutocompleteDropdown
                options={regions}
                value={selectedRegion}
                onChange={handleRegionChange}
                errorState={!!error}
                label='Maakunta'
            />
            <AutocompleteDropdown
                options={sortedMunicipalities}
                value={selectedMunicipality}
                onChange={handleMunicipalityChange}
                errorState={!!error}
                label='Kunta'
                noOptionsText='Valitse maakunta nähdäksesi sen kunnat'
            />
            <AutocompleteDropdown
                options={placesToShow}
                value={selectedPlace}
                onChange={handlePlaceChange}
                errorState={!!error}
                label='Paikka'
                noOptionsText={
                    !placesInMunicipality?.length
                        ? 'Ei näytettäviä tapahtumapaikkoja'
                        : 'Valitse kunta nähdäksesi sen tallennetut paikat'
                }
            />
        </Stack>
    );
};

export default RegionalPlaces;