import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Box, TextField } from '@mui/material';

// Requires the passed options to be an array of objects that contain both
// 'nimi' and 'id' fields.
// This not a part of React-hook-form's values. They're only used for selecting
// the data for it's values.

const AutocompleteDropdown = ({ options, value, onChange, errorState, label, ...otherProps }) => {



    return (
        <Autocomplete
            options={options || []}
            value={value || null} // Provide null as a fallback to keep the component controlled, even when theres no value.
            onChange={(event, value) => onChange(value)}
            {...otherProps}
            getOptionLabel={(option) => option?.nimi ? option?.nimi : option}
            isOptionEqualToValue={(option, value) => option?.id ? option.id === value.id : option === value}
            includeInputInList
            renderOption={(props, option) => (
                <Box component="li" {...props} key={option?.id ? option.id : option}>
                    {option.nimi ? option.nimi : option}
                </Box>
            )}
            renderInput={(params, props) =>  (
                <TextField
                    { ...params }
                    label={label}
                    error={errorState} // The error is used only to color the fields red.
                />
            )}
        />
    );
};

AutocompleteDropdown.propTypes = {
    options: PropTypes.array,
    selectedValue: PropTypes.object,
    onChange: PropTypes.func,
    label: PropTypes.string
};

export default AutocompleteDropdown;