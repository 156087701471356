import dayjs from 'dayjs';
import { useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
    Alert,
    Avatar,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    TextField,
    Typography
} from '@mui/material';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DeleteIcon from '@mui/icons-material/Delete';

import { selectCurrentUser } from '../../../authentication/authSlice';

const FormNotes = ({ fieldName }) => {

    const currentUser = useSelector(selectCurrentUser);

    const notes = useWatch({
        name: fieldName,
        defaultValue: []
    });

    const { setValue } = useFormContext();
    const inputRef = useRef(null);

    const setNotesValue = (newNotes) => setValue(fieldName, newNotes);

    const handleSubmit = () => {
        const newNote = {
            id: new Date(), // Use the current timestamp as an id
            user: {
                name: currentUser.name,
                id: currentUser.userId
            },
            content: inputRef.current.value,
            time: dayjs().format('DD.MM.YYYY HH:mm')
        };
        setNotesValue(notes.concat(newNote));
        inputRef.current.value = '';
    };

    // Filter based on our timestamp id.

    const handleDelete = (noteToDelete) => setNotesValue(
        notes.filter(note => note.id !== noteToDelete.id)
    );

    const listContent = !notes.length
        ? <Alert severity='info'>Tällä tapahtumalla ei vielä ole muistiinpanoja</Alert>
        : notes.map((note, i) => (
            <div key={`${note.user.id}-${i}`}>
                <ListItem
                    sx={{ display: 'flex', justifyContent: i % 2 === 0 ? 'flex-start' : 'flex-end' }}
                >
                    <ListItemAvatar>
                        <Avatar alt={note.user.name} src={note.user.image || ''}/>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Box display='flex' justifyContent='space-between'>
                                <Typography>{note.user.name}</Typography>
                                <Typography variant='caption'>{note.time}</Typography>
                            </Box>
                        }
                        secondary={note.content}
                    />
                    {(note.user.id === currentUser.id || currentUser.role === 'Admin') && (
                        <ListItemSecondaryAction sx={{ top: '25%', mr: -3 }}>
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleDelete(note)}>
                                <DeleteIcon sx={{ color: 'taphaBlue.dark' }} />
                            </IconButton>
                        </ListItemSecondaryAction>
                    )}
                </ListItem>
                {/* If the note isn't the last note, add a divider */}
                {i !== notes.length - 1 && <Divider variant='inset' component='li'/>}
            </div>
        ));

    return (
        <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid xs={12} item>
                <List sx={{ width: '100%' }}>
                    {listContent}
                </List>
            </Grid>
            <Grid xs={8} item>
                <TextField
                    fullWidth
                    inputRef={inputRef}
                    placeholder='Vapaamuotoinen teksti/chat'
                    onKeyDown={(e => {
                        if (e.key === 'Enter' && inputRef.current.value.length)
                            handleSubmit();
                    })}
                />
            </Grid>
            <Grid xs={4} item sx={{ alignItems: 'center', display: 'flex' }}>
                <Button
                    onClick={handleSubmit}
                    variant='contained'
                    startIcon={<PostAddIcon />}
                    sx={{ backgroundColor: 'taphaBlue.main' }}>
                    Lisää
                </Button>
            </Grid>
        </Grid>
    );
};

export default FormNotes;