/*
  Api slice used to fetch regions and their municipalities from Maakunnat-collection.

  Not inside any feature's directory, as it will likely be used in at least two
  different features (AdminPanel, Events).
*/

import { createSelector } from '@reduxjs/toolkit';
import { createEntityAdapter } from '@reduxjs/toolkit';

import { apiSlice } from './apiSlice';

const regionsAdapter = createEntityAdapter({
    selectId: (region) => region.id
});
const initialState = regionsAdapter.getInitialState();

export const regionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getRegions: builder.query({
            query: () => ({
                url: '/maakunnat',
                validateStatus: (response, result) =>
                    response.status === 200 & !result.isError,
            }),
            transformResponse: responseData =>
                regionsAdapter.setAll(initialState, responseData),
            providesTags: ['Regions']
        })
    })
});

export const {
    useGetRegionsQuery
} = regionsApiSlice;

export const selectRegionsResult = regionsApiSlice.endpoints.getRegions.select('RegionsList');

const selectRegionsData = createSelector(
    selectRegionsResult,
    regionsResult => regionsResult.data
);

export const {
    selectEntities: selectRegionEntities,
    selectIds: selectRegionIds
} = regionsAdapter.getSelectors(state => selectRegionsData(state) ?? initialState);


// Returns the region names in an array, e.g. ['Ahvenanmaa', 'Uusimaa', ...]
export const selectAllRegionNames = createSelector(
    selectRegionEntities,
    selectRegionIds,
    (regions, ids) => ids.map(id => regions[id].nimi)
);

/*
    Returns an array of region objects, containing the region's name and it's
    municipalities in an array.

    [
        { nimi: 'Uusimaa', kunnat: ['Kauniainen', 'Espoo', ...] },
        { nimi: 'Pirkanmaa', kunnat: ['Tampere', 'Nokia' ...] }
    ]
*/
export const selectMunicipalitiesByRegion= createSelector(
    selectRegionEntities,
    selectRegionIds,
    (regions, ids) => ids.map(id => regions[id])
);

// Returns all the regions and municipalities in a dictionary for easy state
// management in filters.

export const selectAllRegionsObject = createSelector(
    selectRegionEntities,
    selectRegionIds,
    (regions, ids) => {
        const allRegions = ids.map(id => regions[id]);
        const regionsObj = {};

        allRegions.forEach((region) => {
            regionsObj[region.nimi] = region.kunnat.map(kunta => kunta.nimi);
        });
        return regionsObj;
    }
);