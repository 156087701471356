import { useState } from 'react';
import { Grid, Typography, Box, IconButton, Button, Alert } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormTextField from '../../components/FormFields/FormTextField';
import { useAddNewRoleMutation  } from '../../api/rolesApiSlice';
import UpdateButton from './adminUtils/updateButton';
import theme from '../../assets/muiTheme';
import SearchBar from './adminUtils/SearchBar';
import PaginationInfo from './PaginationInfo';

function RoleList({ roles, handleDeleteRooli, handleUpdateRooli, fetchData, onSuccess, onError }) {
    const [selectedRole, setSelectedRole] = useState(null);
    const [newRoleData, setNewRoleData] = useState({ rooli: '', kuvaus: '' });
    const [addRoleData, setAddRoleData] = useState({ rooli: '', kuvaus: '' });
    const [showAddForm, setShowAddForm] = useState(false);
    const [addNewRole, { isLoading }] = useAddNewRoleMutation();
    const [currentPage, setCurrentPage] = useState(1);
    /*const [showSuccessAlert, setShowSuccessAlert] = useState(false);*/
    const itemsPerPage = 10;



    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
        setCurrentPage(1); // Reset to the first page when searching
    };

    // Apply the search filter
    const filteredRoles = roles.filter((Role) =>
        Role.rooli.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalPages = Math.ceil(filteredRoles.length / itemsPerPage);

    // Get the slice of luonteet for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedRoles = filteredRoles.slice(startIndex, endIndex);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };



    const handleUserClick = (role) => {
        if(selectedRole?.id === role.id) {setSelectedRole(null);
            setNewRoleData({ rooli: '', kuvaus: '' });
            return;}
        setSelectedRole(role);
        setNewRoleData(role);
    };

    const handleAddClick = () => {
        setShowAddForm(!showAddForm);
    };

    const handleLisääRooli = async(role) => {
        try {
            const result = await addNewRole(role);

            /*if (result.data) {
                setShowSuccessAlert(true); // Show the success alert
            }*/
            if(result.error) onError('Tehtävän luominen epäonnistui');
            if(!result.error) onSuccess('Tehtävän luominen onnistui.', 'roolit', result.data.id);
            setAddRoleData({ rooli: '', kuvaus: '' });
            setShowAddForm(false);
            fetchData();
        } catch (error) {
            console.error(error); // handle error
        }};
    /*{showSuccessAlert && (
                <Alert severity="success" onClose={() => setShowSuccessAlert(false)}>
          Role created successfully!
                </Alert>
            )}*/

    return (
        <Grid container spacing={2}>
            <Box mt={3} ml={2}> {/* Add spacing to separate SearchBar */}
                <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
            </Box>
            {displayedRoles.map((role, index) => (
                <Grid key={role.id} item xs={12} >
                    <Box key={role.id} onClick={() => handleUserClick(role)} sx={{
                        backgroundColor: 'taphaWhite.main',
                        borderRadius: 5,

                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginTop: 0.6,
                        gap: 5,
                        '&:hover': {
                            border: '1px solid',
                            borderLeft: '30px solid',
                            transition: '0.4s',
                            borderColor: 'taphaGreen.main',
                            cursor: 'pointer'
                        }
                    }}>
                        <Typography variant="h6">
                            {role.rooli}
                        </Typography>
                        <IconButton onClick={(event) => {
                            handleDeleteRooli(event, role);
                            if (selectedRole && selectedRole.id === role.id) {
                                setSelectedRole(null);
                                setNewRoleData({ rooli: '', kuvaus: '' });
                            }
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                    {selectedRole && selectedRole.id === role.id && (
                        <Grid container>

                            <Grid item xs={8} sx={{ pt: 2 }}>
                                <FormTextField

                                    fieldName='rooli'
                                    label='rooli'
                                    multiline={true}
                                    fullWidth={true}

                                    value={newRoleData.rooli}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setNewRoleData((prevState) => ({ ...prevState, [name]: value }));
                                    }}

                                />
                            </Grid>
                            <Grid item xs={8}>
                                <FormTextField
                                    error={true}
                                    helperText='kuvaus pittää olla'

                                    fieldName='kuvaus'
                                    label='kuvaus'
                                    multiline={true}
                                    fullWidth={true}

                                    value={newRoleData.kuvaus}
                                    onChange={(event) => {
                                        const { name, value } = event.target;

                                        setNewRoleData((prevState) => ({ ...prevState, [name]: value }));
                                    }}
                                />
                            </Grid>

                            <UpdateButton handleUpdate={handleUpdateRooli} data={newRoleData}/>
                        </Grid>
                    )}
                </Grid>
            ))}
            {showAddForm && (
                <Box sx={{
                    flexGrow: 1, // Add flexGrow to make the box grow
                }}>
                    <Typography variant="h6" sx={{ pt: 2 }}>Tehtävän lisääminen</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <FormTextField
                                key="addRoleRooli"
                                fieldName='rooli'
                                label='rooli'
                                multiline={true}
                                fullWidth={true}

                                value={addRoleData.rooli}
                                onChange={(event) => setAddRoleData({ ...addRoleData, rooli: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormTextField
                                error={false}
                                key="addRoleKuvaus"
                                fieldName='kuvaus'
                                label='kuvaus'
                                multiline={true}
                                fullWidth={true}

                                value={addRoleData.kuvaus}
                                onChange={(event) => setAddRoleData({ ...addRoleData, kuvaus: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Box display="flex" justifyContent="flex-end" width="100%" pt={1}>

                            <Button
                                onClick={() => handleLisääRooli(addRoleData)}
                                size="small"
                                variant="contained"
                                startIcon={<AddIcon />}
                                sx={{ backgroundColor: 'taphaGreen.main' }}
                            >
          Lisää tehtävä
                            </Button>
                        </Box>
                    </Grid>
                </Box>

            )}
            {showAddForm ? (
                <Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={handleAddClick}
                        size='small'
                        variant='contained'
                        startIcon={<CloseIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Sulje
                    </Button>
                </Box>
            ) :
                (<Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={handleAddClick}
                        size='small'
                        variant='contained'
                        startIcon={<AddIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Lisää
                    </Button>
                </Box>)}
            {/* Pagination arrows */}
            <Box mt={2} ml={2} display="flex" flexDirection="column" alignItems="center">
                <PaginationInfo
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    filteredArray={filteredRoles}
                    array={roles}
                />
                <Box display="flex" justifyContent="center" width="100%">
                    <Box width="740px" display="flex" justifyContent="center"> {/* Centered box with a specified width */}
                        <IconButton
                            size="small"
                            onClick={handlePreviousPage}
                            disabled={currentPage <= 1}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={handleNextPage}
                            disabled={currentPage >= totalPages}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
}

export default RoleList;