/*
    API slice used to fetch the event types (luonteet) from the backend.
    It's not inside any feature's directory, as it will likely be used in at
    least two different features (AdminPanel, Events).
*/

import { createSelector } from '@reduxjs/toolkit';
import { createEntityAdapter } from '@reduxjs/toolkit';

import { apiSlice } from './apiSlice';

const eventTypesAdapter = createEntityAdapter({
    selectId: (eventType) => eventType.id
});
const initialState = eventTypesAdapter.getInitialState();

export const eventTypesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getEventTypes: builder.query({
            query: () => ({
                url: '/luonteet',
                validateStatus: (response, result) =>
                    response.status === 200 && !result.isError
            }),
            transformResponse: responseData =>
                eventTypesAdapter.setAll(initialState, responseData),
            providesTags: ['EventTypes']
        }),
        addNewEventType: builder.mutation({
            query: newEventType => ({
                url: '/luonteet',
                method: 'POST',
                body: { ...newEventType }
            }),
            invalidatesTags: ['EventTypes']
        }),
        deleteEventType: builder.mutation({
            query: ({ id }) => ({
                url: '/luonteet',
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: ['EventTypes']
        })
    })
});

export const {
    useGetEventTypesQuery,
    useAddNewEventTypeMutation,
    useDeleteEventTypeMutation
} = eventTypesApiSlice;

export const selectEventTypesResult = eventTypesApiSlice.endpoints.getEventTypes.select('EventTypesList');

const selectEventTypesData = createSelector(
    selectEventTypesResult,
    eventTypesResult => eventTypesResult.data
);

export const {
    selectIds: selectEventTypeIds,
    selectEntities: selectEventTypeEntities
} = eventTypesAdapter.getSelectors(state => selectEventTypesData(state) ?? initialState);

// Selector for only the event types themselves without ids.
export const selectAllEventTypes = createSelector(
    selectEventTypeIds,
    selectEventTypeEntities,
    (ids, eventTypes) => ids.map(id => eventTypes[id].luonne)
);