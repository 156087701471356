

const handleDelete = async(event, endpoint, accessToken, toBeDeleted, filterAfterDeletion, successFunction, errorFunction, handleDeleteConfirmation, deleteConfirmed, setDeleteConfirmed, sendLog, name) => {
    event.stopPropagation();
    if(handleDeleteConfirmation) handleDeleteConfirmation(true);

    if (deleteConfirmed || deleteConfirmed === undefined) {
        try {
            // Perform the deletion
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({
                    id: toBeDeleted.id
                })
            });

            if (!response.ok) {
                errorFunction('Poistaminen epäonnistui');
                throw new Error('Failed to delete');
            }

            if (filterAfterDeletion) filterAfterDeletion();

            /*await sendLog({
                name: name,
                action: 'poisti',
                document: {
                    type: endpoint,
                    documentId: toBeDeleted.id
                }
            });*/

            successFunction('Poistettu onnistuneesti!');

            if (handleDeleteConfirmation) handleDeleteConfirmation(false);
            if (setDeleteConfirmed) setDeleteConfirmed(false);
        } catch (error) {
            console.error(error);
        }
    }




};

export default handleDelete;