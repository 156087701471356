import PropTypes from 'prop-types';
import { Grid, MenuItem, Paper } from '@mui/material';

import FormTextField from '../../../../../components/FormFields/FormTextField';
import reportingOptions from '../reportingOptions.json';

const BillFields = ({ fieldName, fieldIndex, kustannuspaikatArray }) => {
    const textFieldStyle = {
        variant: 'outlined',
        fullWidth: true,
        size: 'small'
    };

    return (
        <Paper
            elevation={3}
            sx={{ px: 2, py: 3, borderRadius: 3 }}
        >
            <Grid container columnSpacing={4}>
                <Grid item xs={6}>
                    <FormTextField
                        label='Maksava taho'
                        fieldName={`${fieldName}.[${fieldIndex}].maksava_taho`}
                        {...textFieldStyle}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField
                        label='Tilaajan viite'
                        fieldName={`${fieldName}.[${fieldIndex}].tilaajan_viite`}
                        {...textFieldStyle}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField
                        label='Saa laskuttaa vasta'
                        fieldName={`${fieldName}.[${fieldIndex}].saa_laskuttaa_vasta`}
                        {...textFieldStyle}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField
                        label='Laskutusosoite'
                        fieldName={`${fieldName}.[${fieldIndex}].laskutusosoite`}
                        {...textFieldStyle}
                        multiline={true}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField
                        label='Valitse laskutyyppi'
                        fieldName={`${fieldName}.[${fieldIndex}].laskutyyppi`}
                        {...textFieldStyle}
                        select={true}
                    >
                        {reportingOptions.laskutyypit.map(laskutyyppi =>
                            <MenuItem key={laskutyyppi} value={laskutyyppi}>
                                {laskutyyppi}
                            </MenuItem>
                        )}
                    </FormTextField>
                </Grid>
                <Grid item xs={6}>
                    <FormTextField
                        label='Laskun summa (€)'
                        fieldName={`${fieldName}.[${fieldIndex}].summa`}
                        {...textFieldStyle}
                        type='number'
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField
                        label='Lisätiedot'
                        fieldName={`${fieldName}.[${fieldIndex}].lisatiedot`}
                        {...textFieldStyle}
                        multiline={true}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField
                        label='Kustannuspaikka'
                        fieldName={`${fieldName}.[${fieldIndex}].kustannuspaikka`}
                        {...textFieldStyle}
                        select={true}
                    >
                        {kustannuspaikatArray.map(kp => (
                            <MenuItem key={kp.id} value={kp.kustannuspaikka}>
                                {kp.kustannuspaikka}
                            </MenuItem>
                        ))}
                    </FormTextField>
                </Grid>
            </Grid>
        </Paper>
    );
};

BillFields.propTypes = {
    fieldName: PropTypes.string.isRequired,
    fieldIndex: PropTypes.number.isRequired,
    kustannuspaikatArray: PropTypes.array.isRequired
};

export default BillFields;