import { useSelector, useDispatch } from 'react-redux';

import { useState, useEffect, useRef } from 'react';
import {
    Button,
    Box,
    CircularProgress,
    Container,
    Grid,
    Paper,
    Typography,
    FormControl, InputLabel, Select, MenuItem, Alert
} from '@mui/material';

import fetchData from '../AdminPage/adminUtils/fetchData';
import handleDelete from './adminUtils/handleDelete';
import handleUpdate from './adminUtils/handleUpdate';

import FormTextField from '../../components/FormFields/FormTextField';

import UserList from './UserList';
import PeopleList from './PeopleList';

import AddIcon from '@mui/icons-material/Add';
import FormListSelect from '../../features/events/components/EventForm/FormListSelect';

import FormSelectMultiple from '../../features/events/components/EventForm/FormSelectMultiple';
import ProgressLoader from '../../components/ProgressLoader';
import RaportitList from './RaportitList';


import { useForm, FormProvider } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import {
    validationSchema,
    defaultValues,
    convertDataToAPISchema,
    convertDataToFormSchema
} from '../../features/events/components/EventForm/eventFormSchemas';

import { selectAllRoles, useAddNewRoleMutation, useDeleteRoleMutation, useGetRolesQuery } from '../../api/rolesApiSlice';
import { selectAllEventTypes } from '../../api/eventTypesApiSlice';
import { selectAllPeople } from '../../api/peopleApiSlice';
import { selectCurrentAccessToken } from '../../features/authentication/authSlice';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import { roles } from '../../config/roles';
import { selectCurrentUser } from '../../features/authentication/authSlice';
import RoleList from './RoleList';
import LuonteetList from './LuonteetList';
import PlacesList from './PlacesList';
import AudiencesList from './AudiencesList';
import YhteistyotahotList from './YhteistyotahotList';
import TiedoteList from './tiedoteList';
import YhteystiedotList from './YhteystiedotList';
import BugiraportitList from './BugiraportitList';
import { useAddNewLokiMutation } from '../../api/lokiSlice';
import LogList from './LogPage';
import KustannuspaikkaList from './kustannuspaikatList';

const AdminPage = ({ documentTitle, isEditing }) => {

    useDocumentTitle(documentTitle);
    const [selectedOption, setSelectedOption] = useState('roolit');
    const [allRoles, setAllRoles] = useState([]);
    const [allTiedotteet, setAllTiedotteet] = useState([]);
    const [bugiraportit, setBugiraportit] = useState([]);
    const [yleisöt, setYleisöt] = useState([]);
    const [luonteet, setLuonteet] = useState([]);
    const [kustannuspaikat, setKustannuspaikat] = useState([]);
    const [yhteystiedot, setYhteystiedot] = useState([]);
    const [places, setPlaces] = useState([]);
    const [raportit, setRaportit] = useState([]);
    const [people, setPeople] = useState([]);
    const [yhttahot, setYhttahot] = useState([]);
    const { data, isLoading } = useGetRolesQuery();
    const accessToken = useSelector(selectCurrentAccessToken);
    const user = useSelector(selectCurrentUser);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [initialFormValues, setInitialFormValues] = useState(defaultValues);
    const [ sendLog ] = useAddNewLokiMutation();
    const name = user.name;
    //general functionality
    const  handleDropdownChange = (event) => {
        setSelectedOption(event.target.value);
    };
    //handling state management etc. for RoleList
    const fetchRoleData = () => {
        fetchData('roolit', accessToken, setAllRoles);
    };
    useEffect(() => {
        fetchRoleData();
    }, [accessToken]);
    const fetchRaportitData = () => {
        fetchData('raportit', accessToken, setRaportit);
    };
    useEffect(() => {
        fetchRaportitData();
    }, [accessToken]);
    const fetchBugiraportitData = () => {
        fetchData('bugiraportit', accessToken, setBugiraportit);
    };
    useEffect(() => {
        fetchBugiraportitData();
    }, [accessToken]);

    const deleteBugiraporttiFilter = (bugiraportti) => {
        setBugiraportit(bugiraportit?.filter(r => r.id !== bugiraportti.id));
    };
    const handleDeleteBugiraportti = (event, bugiraportti) => {
        handleDelete(event, 'bugiraportit', accessToken, bugiraportti, () => deleteBugiraporttiFilter(bugiraportti),  setSuccessMessage, setErrorMessage, undefined, undefined, undefined, sendLog, name);
    };
    const deleteRoleFilter = (rooli) => {
        setAllRoles(allRoles.filter(r => r.id !== rooli.id));
    };
    const deleteRaporttiFilter = (raportti) => {
        setRaportit(raportit.filter(r => r.id !== raportti.id));
    };
    const handleDeleteRaportti = (event, raportti) => {
        handleDelete(event, 'raportit', accessToken, raportti, () => deleteRaporttiFilter(raportti),  setSuccessMessage, setErrorMessage, undefined, undefined, undefined, sendLog, name);
    };
    const updateRolesFilter = (rooli, responseData) => {
        setAllRoles((prevRoles) =>
            prevRoles.map((role) => (role.id === rooli.id ? responseData : role))
        );
    };
    const handleDeleteRooli = (event, rooli) => {
        handleDelete(event, 'roolit', accessToken, rooli, () => deleteRoleFilter(rooli),  setSuccessMessage, setErrorMessage, undefined, undefined, undefined, sendLog, name);
    };

    const handleUpdateRooli = (rooli) => {
        handleUpdate('roolit', accessToken, rooli, updateRolesFilter, setSuccessMessage, setErrorMessage, sendLog, name );
    };

    const fetchTiedoteData = () => {
        fetchData('tiedotteet', accessToken, setAllTiedotteet);
    };
    useEffect(() => {
        fetchTiedoteData();
    }, [accessToken]);
    const deleteTiedoteFilter = (tiedote) => {
        setAllTiedotteet(allTiedotteet.filter(t => t.id !== tiedote.id));
    };
    const updateTiedotteetFilter = (tiedote, responseData) => {
        setAllTiedotteet((prevTiedotteet) =>
            prevTiedotteet.map((t) => (t.id === tiedote.id ? responseData : t))
        );
    };
    const handleDeleteTiedote = (event, tiedote) => {
        handleDelete(event, 'tiedotteet', accessToken, tiedote, () => deleteTiedoteFilter(tiedote),  setSuccessMessage, setErrorMessage, undefined, undefined, undefined, sendLog, name);
    };
    const handleUpdateTiedote = (tiedote) => {
        handleUpdate('tiedotteet', accessToken, tiedote, updateTiedotteetFilter, setSuccessMessage, setErrorMessage,sendLog, name );
    };

    //handling state etc. for PeopleList
    const fetchPeopleData = () => {
        fetchData('henkilot', accessToken, setPeople);
    };
    useEffect(() => {
        fetchPeopleData();
    }, [accessToken]);

    const deletePersonFilter = (person) => {
        setPeople(people.filter(p => p.id !== person.id));
    };
    const handleDeletePerson = (event, person) => {
        handleDelete(event, 'henkilot', accessToken, person, () => deletePersonFilter(person), setSuccessMessage, setErrorMessage, undefined, undefined, undefined, sendLog, name);
    };

    const updatePeopleFilter = (henkilo, responseData) => {
        setPeople((prevPeople) =>
            prevPeople.map((person) => (person.id === henkilo.id ? responseData : person))
        );
    };
    const handleUpdatePerson = (person) => {
        handleUpdate('henkilot', accessToken, person, updatePeopleFilter, setSuccessMessage, setErrorMessage, sendLog, name );
    };

    const fetchLuonteetData = () => {
        fetchData('luonteet', accessToken, setLuonteet);
    };
    useEffect(() => {
        fetchLuonteetData();
    }, [accessToken]);

    const deleteLuonneFilter = (luonne) => {
        setLuonteet(luonteet.filter(l => l.id !== luonne.id));
    };
    const handleDeleteLuonne = (event, luonne) => {
        handleDelete(event, 'luonteet', accessToken, luonne, () => deleteLuonneFilter(luonne), setSuccessMessage, setErrorMessage, undefined, undefined, undefined, sendLog, name);
    };

    const updateLuonteetFilter = (luonne, responseData) => {
        setLuonteet((prevLuonteet) =>
            prevLuonteet.map((l) => (l.id === luonne.id ? responseData : l))
        );
    };
    const handleUpdateLuonne = (luonne) => {
        handleUpdate('luonteet', accessToken, luonne, updateLuonteetFilter, setSuccessMessage, setErrorMessage, sendLog, name );
    };

    const fetchKustannuspaikatData = () => {
        fetchData('kustannuspaikat', accessToken, setKustannuspaikat);
    };
    useEffect(() => {
        fetchKustannuspaikatData();
    }, [accessToken]);

    const deleteKustannuspaikkaFilter = (kustannuspaikka) => {
        setKustannuspaikat(kustannuspaikat.filter(k => k.id !== kustannuspaikka.id));
    };
    const handleDeleteKustannuspaikka = (event, kustannuspaikka) => {
        handleDelete(event, 'kustannuspaikat', accessToken, kustannuspaikka, () => deleteKustannuspaikkaFilter(kustannuspaikka), setSuccessMessage, setErrorMessage, undefined, undefined, undefined, sendLog, name);
    };

    const updateKustannuspaikatFilter = (kustannuspaikka, responseData) => {
        setKustannuspaikat((prevKustannuspaikat) =>
            prevKustannuspaikat.map((k) => (k.id === kustannuspaikka.id ? responseData : k))
        );
    };
    const handleUpdateKustannuspaikka = (kustannuspaikka) => {
        handleUpdate('kustannuspaikat', accessToken, kustannuspaikka, updateKustannuspaikatFilter, setSuccessMessage, setErrorMessage, sendLog, name );
    };

    const fetchPlacesData = () => {
        fetchData('paikat', accessToken, setPlaces);
    };
    useEffect(() => {
        fetchPlacesData();
    }, [accessToken]);

    const updatePlacesFilter = (paikka, responseData) => {
        setPlaces((prevPlaces) =>
            prevPlaces.map((p) => (p.id === paikka.id ? responseData : p))
        );
    };
    const handleUpdatePlace = (place) => {
        handleUpdate('paikat', accessToken, place, updatePlacesFilter, setSuccessMessage, setErrorMessage, sendLog, name );
    };

    const deletePlaceFilter = (place) => {
        setPlaces(places.filter(p => p.id !== place.id));
    };
    const handleDeletePlace = (event, place) => {
        handleDelete(event, 'paikat', accessToken, place, () => deletePlaceFilter(place), setSuccessMessage, setErrorMessage, undefined, undefined, undefined, sendLog, name);
    };

    const fetchYleisöData = () => {
        fetchData('kohdeyleisot', accessToken, setYleisöt);
    };
    useEffect(() => {
        fetchYleisöData();
    }, [accessToken]);

    const deleteYleisöFilter = (yleisö) => {
        setYleisöt(yleisöt.filter(y => y.id !== yleisö.id));
    };
    const handleDeleteYleisö = (event, yleisö) => {
        handleDelete(event, 'kohdeyleisot', accessToken, yleisö, () => deleteYleisöFilter(yleisö), setSuccessMessage, setErrorMessage,undefined, undefined, undefined, sendLog, name);
    };

    const updateYleisöFilter = (yleisö, responseData) => {
        setYleisöt((prevYleisöt) =>
            prevYleisöt.map((y) => (y.id === yleisö.id ? responseData : y))
        );
    };
    const handleUpdateYleisö = (yleisö) => {
        handleUpdate('kohdeyleisot', accessToken, yleisö, updateYleisöFilter, setSuccessMessage, setErrorMessage, sendLog, name );
    };

    const fetchYhttahoData = () => {
        fetchData('yhteistyotahot', accessToken, setYhttahot);
    };
    useEffect(() => {
        fetchYhttahoData();
    }, [accessToken]);

    const updateYhttahoFilter = (yhttaho, responseData) => {
        setYhttahot((prevYhttahot) =>
            prevYhttahot.map((y) => (y.id === yhttaho.id ? responseData : y))
        );
    };
    const handleUpdateYhttaho = (yhttaho) => {
        handleUpdate('yhteistyotahot', accessToken, yhttaho, updateYhttahoFilter, setSuccessMessage, setErrorMessage, sendLog, name );
    };


    const deleteYhttahoFilter = (yhttaho) => {
        setYhttahot(yhttahot.filter(y => y.id !== yhttaho.id));
    };
    const handleDeleteYhttaho = (event, yhttaho) => {
        handleDelete(event, 'yhteistyotahot', accessToken, yhttaho, () => deleteYhttahoFilter(yhttaho), setSuccessMessage, setErrorMessage, undefined, undefined, undefined, sendLog, name);
    };
    const handleSuccessfulCreation = async(message, type, documentId) => {
        setSuccessMessage(message);
        /*await sendLog({
            name: name,
            action: 'loi',
            document: {
                type: type,
                documentId: documentId
            }
        });*/
    };
    const handleError = (message) => {
        setErrorMessage(message);
    };

    const fetchYhteystiedotData = () => {
        fetchData('yhteystiedot', accessToken, setYhteystiedot);
    };
    useEffect(() => {
        fetchYhteystiedotData();
    }, [accessToken]);

    const deleteYhteystietoFilter = (yhteystieto) => {
        setYhteystiedot(yhteystiedot.filter(y => y.id !== yhteystieto.id));
    };
    const handleDeleteYhteystieto = (event, yhteystieto) => {
        handleDelete(event, 'yhteystiedot', accessToken, yhteystieto, () => deleteYhteystietoFilter(yhteystieto), setSuccessMessage, setErrorMessage, undefined, undefined, undefined,  sendLog, name);
    };

    const updateYhteystiedotFilter = (yhteystieto, responseData) => {
        setYhteystiedot((prevYhteystiedot) =>
            prevYhteystiedot.map((y) => (y.id === yhteystieto.id ? responseData : y))
        );
    };
    const handleUpdateYhteystieto = (yhteystieto) => {
        handleUpdate('yhteystiedot', accessToken, yhteystieto, updateYhteystiedotFilter, setSuccessMessage, setErrorMessage, sendLog, name );
    };




    const { formState, ...methods } = useForm({
        resolver: (data, context, options) => // Spread our most recent data to context, so it's always available in tests.
            yupResolver(validationSchema)(data, { ...data, isEditing }, options),
        mode: 'onBlur', // Validate on input state change.
        reValidateMode: 'onChange', // After a failed submit validate onChange.
        defaultValues: defaultValues,
        values: initialFormValues ?? defaultValues,
        resetOptions: { keepDefaultValues: true }
    });

    if (user.role !== roles.admin)
        return <div>Ei pääsyä tälle sivulle.</div>;

    if(isLoading) {
        return <ProgressLoader msg="Ladataan tietoja.." />;
    }

    return (
        <Container
            maxWidth='md'
            display='flex'
            sx={{ justifyContent: 'center', mt: 2 }}>
            <FormProvider
                { ...methods }
                formState={formState}
                defaultValues={defaultValues}
            >
                <Paper
                    id='event-form'
                    component='form'
                    onKeyDown={(e) => { // Prevent default enter submit.
                        if (e.key === 'Enter')
                            e.preventDefault();
                    }}
                    elevation={3}
                    sx={{
                        borderRadius: 8,
                        my: 3,
                        px: 8,
                        py: 4
                    }}
                >
                    {successMessage && (
                        <Box my={2}>
                            <Alert severity="success" onClose={() => setSuccessMessage('')}>
                                {successMessage}
                            </Alert>
                        </Box>
                    )}
                    {errorMessage && (
                        <Box my={2}>
                            <Alert severity="error" onClose={() => setErrorMessage('')}>
                                {errorMessage}
                            </Alert>
                        </Box>
                    )}
                    <Typography variant="body1" style={{ fontFamily: 'Typography', fontSize: '16px' }}>Version: {process.env.REACT_APP_VERSION_NUMBER}</Typography>
                    <Grid container rowSpacing={7} width='100%'>

                        <Grid item xs={12} sx={{ pb: 5 }}>
                            <Typography align='center' variant='h4'>{initialFormValues?.nimi || 'Muokkaa'}   </Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel id="select-label">Valitse</InputLabel>
                                <Select
                                    labelId="select-label"
                                    value={selectedOption}
                                    onChange={handleDropdownChange}
                                >
                                    <MenuItem value="roolit">Tehtävä tapahtumassa</MenuItem>
                                    <MenuItem value="käyttäjät">Käyttäjät</MenuItem>
                                    <MenuItem value="henkilöt">Henkilöt</MenuItem>
                                    <MenuItem value="kohdeyleisöt">Kohdeyleisöt</MenuItem>
                                    <MenuItem value="yhteistyötahot">Yhteistyötahot</MenuItem>
                                    <MenuItem value="paikat">Paikat</MenuItem>
                                    <MenuItem value="luonteet">Luonteet</MenuItem>
                                    <MenuItem value="tiedotteet">Tiedotteet</MenuItem>
                                    <MenuItem value="yhteystiedot">Yhteystiedot</MenuItem>
                                    <MenuItem value="raportit">Raportit</MenuItem>
                                    <MenuItem value="kustannuspaikat">Kustannuspaikat</MenuItem>
                                    <MenuItem value="bugiraportit">Bugiraportit</MenuItem>
                                    <MenuItem value="loki">Loki</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>


                    <Grid>
                        {selectedOption === 'käyttäjät' ? (
                            (<UserList setSuccessMessage={setSuccessMessage} setErrorMessage={setErrorMessage}  fetchPeopleData={fetchPeopleData} onError={handleError} onSuccess={handleSuccessfulCreation}></UserList>)
                        ) : selectedOption === 'roolit' ? (<RoleList onError={handleError} roles={allRoles} onSuccess={handleSuccessfulCreation} handleDeleteRooli={handleDeleteRooli} handleUpdateRooli={handleUpdateRooli} fetchData={fetchRoleData}></RoleList>) : selectedOption === 'henkilöt' ? (<PeopleList onError={handleError} people={people} onSuccess={handleSuccessfulCreation} fetchData={fetchPeopleData} handleDeletePerson={handleDeletePerson} handleUpdatePerson={handleUpdatePerson}></PeopleList>) : selectedOption === 'luonteet' ? (<LuonteetList onError={handleError}
                            luonteet={luonteet} onSuccess={handleSuccessfulCreation} fetchData={fetchLuonteetData} handleDeleteLuonne={handleDeleteLuonne} handleUpdateLuonne={handleUpdateLuonne}/>) : selectedOption === 'paikat' ? (<PlacesList places={places} onError={handleError} onSuccess={handleSuccessfulCreation} handleDeletePlace={handleDeletePlace} handleUpdatePlace={handleUpdatePlace} fetchData={fetchPlacesData}/>) : selectedOption === 'kohdeyleisöt' ?(<AudiencesList onError={handleError} fetchData={fetchYleisöData} onSuccess={handleSuccessfulCreation} handleUpdateYleiso={handleUpdateYleisö} handleDeleteYleiso={handleDeleteYleisö} yleisöt={yleisöt}/>) : selectedOption === 'yhteistyötahot' ? (<YhteistyotahotList onError={handleError} onSuccess={handleSuccessfulCreation} fetchData={fetchYhttahoData} yhttahot={yhttahot} handleUpdateYhttaho={handleUpdateYhttaho} handleDeleteYhttaho={handleDeleteYhttaho}/>) : selectedOption === 'tiedotteet' ? (<TiedoteList onError={handleError} tiedotteet={allTiedotteet} onSuccess={handleSuccessfulCreation} handleDeleteTiedote={handleDeleteTiedote} handleUpdateTiedote={handleUpdateTiedote} fetchData={fetchTiedoteData}></TiedoteList>) : selectedOption === 'yhteystiedot' ? (<YhteystiedotList onError={handleError}
                            yhteystiedot={yhteystiedot} onSuccess={handleSuccessfulCreation} fetchData={fetchYhteystiedotData} handleDeleteYhteystieto={handleDeleteYhteystieto} handleUpdateYhteystieto={handleUpdateYhteystieto}/>) : selectedOption === 'raportit' ? (<RaportitList onError={handleError} raportit={raportit} onSuccess={handleSuccessfulCreation} handleDeleteRaportti={handleDeleteRaportti}  fetchData={fetchRaportitData}></RaportitList>) : selectedOption === 'bugiraportit' ? (<BugiraportitList onError={handleError} bugiraportit={bugiraportit}  handleDeleteBugiraportti={handleDeleteBugiraportti} ></BugiraportitList>) : selectedOption === 'loki' ? (<LogList/>) : selectedOption === 'kustannuspaikat' ? (<KustannuspaikkaList onError={handleError}
                            kustannuspaikat={kustannuspaikat} onSuccess={handleSuccessfulCreation} fetchData={fetchKustannuspaikatData} handleDeleteKustannuspaikka={handleDeleteKustannuspaikka} handleUpdateKustannuspaikka={handleUpdateKustannuspaikka}/>) : (<></>) }

                    </Grid>
                </Paper>
            </FormProvider>
        </Container>
    );
};

export default AdminPage;