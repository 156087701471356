const paikkaFields = [
    'keskus',
    'nimi',
    'osoite',
    'maakunta',
    'kunta',
    'postitoimipaikka',
    'postinumero'
];

export { paikkaFields };