import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectCurrentUser } from '../authSlice';

const RequireAuthentication = ({ restrictedTo }) => {

    const user = useSelector(selectCurrentUser);
    const location = useLocation();

    // If the user is logged in, navigate to the landing page. Else, navigate to login.
    // This should maybe just navigate to the last place from history.

    const redirectUser = () => {
        if (user)
            return <Navigate to='/' state={{ from: location }} replace />;
        else
            return <Navigate to='/login' state={{ from: location }} replace />;
    };

    // If the user's role is allowed to access the protected route, return an <Outlet />
    // so the route can be rendered. Else, redirect the user accordingly.
    return restrictedTo.includes(user?.role)
        ? <Outlet />
        : redirectUser();
};

export default RequireAuthentication;