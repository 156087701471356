import React from 'react';
import Typography from '@mui/material/Typography';
import DOMPurify from 'dompurify';

const Kuvaus = ({ eventDesc, marginLeft }) => {
    const createMarkup = (htmlString) => {
        return { __html: DOMPurify.sanitize(htmlString) };
    };

    const paragraphs = eventDesc?.split('\n').map((para, index) => {
        return (
            <Typography
                key={index}
                sx={{
                    my: 1,
                    ml: marginLeft,
                    textAlign: 'left',
                    overflowWrap: 'break-word',
                }}
                component="div" // Set the component to 'div' to allow rendering of HTML tags
            >
                <div dangerouslySetInnerHTML={createMarkup(para)} />
            </Typography>
        );
    });

    return <>{paragraphs}</>;
};

export default Kuvaus;