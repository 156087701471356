/*
    A config for the various roles users can have.
    Just way to make sure theyre always spelled correctly.
*/

export const roles = {
    kayttaja: 'Käyttäjä',
    suunnittelija: 'Suunnittelija',
    admin: 'Admin',
    muokkaaja: 'Muokkaaja',
    laskuttaja: 'Laskuttaja'
};