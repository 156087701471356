import { Button, Grid, MenuItem, Select, InputLabel } from '@mui/material';
import { useSelector } from 'react-redux';
import { memo, useState } from 'react';

import { selectAllPeopleNames } from '../../../../../api/peopleApiSlice';
import FormTextField from '../../../../../components/FormFields/FormTextField';
import FormListSelect from '../FormListSelect';
import FormTimePicker from '../FormTimePicker';
import MukanaOlevat from '../MukanaOlevat';
import RichTextEditor from '../../../../../components/RichTextEditor';

const ScheduleInputs = ({ fieldName, onClose, kansanopisto, setHours }) => {


    const people = useSelector(selectAllPeopleNames);


    return (
        <Grid container spacing={2} sx={{ p: 3 }}>
            <Grid item xs={12}>
                <FormTextField
                    fieldName={fieldName + '.nimi'}
                    label='Tilaisuuden nimi'
                    variant='outlined'
                    placeholder='Tilaisuuden nimi'
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <FormTimePicker
                    fieldName={fieldName + '.aloitus'}
                    label='Tilaisuuden alkamisaika'
                />
            </Grid>
            <Grid item xs={6}>
                <FormTimePicker
                    fieldName={fieldName + '.lopetus'}
                    label='Tilaisuuden päättymisaika'
                />
            </Grid>
            <Grid item xs={12} sx={{ maxHeight: '200px', overflowY: 'auto' }}>
                <RichTextEditor
                    fieldName={fieldName + '.kuvaus'}
                />
            </Grid>
            <Grid item xs={12}>
                <MukanaOlevat
                    fieldName={fieldName + '.henkilot'}
                    subfields={[ // Expects the name of the subfield inside the form state, along with the possible options.
                        { name: 'henkilo', options: people, xs: 9 },
                        { name: 'titteli', options: ['Kyllä', 'Ei'], xs: 3, label: 'Näytä titteli' }
                    ]}
                    emptyMsg='Tilaisuudella ei vielä ole henkilöitä'
                />
            </Grid>
            {kansanopisto &&
            <Grid item xs={12}>
                <FormListSelect
                    setHours={setHours}
                    fieldName={fieldName + '.kansanopistotunnit'}
                    subfields={[ // Expects the name of the subfield inside the form state, along with the possible options.
                        { name: 'kansanopistotunnit', options: [...Array(11).keys()], xs: 12, label: 'Kansanopistotunnit' }
                    ]}
                    emptyMsg='Tilaisuudella ei ole kansanopistotunteja'
                >
                </FormListSelect>
            </Grid>}
            <Grid item xs={12} display='flex' justifyContent='center' mb={0}>
                <Button
                    variant='contained'
                    onClick={onClose}
                    sx={{ backgroundColor: 'taphaBlue.main' }}>
                    Tallenna ja palaa
                </Button>
            </Grid>
        </Grid>
    );

};

export default ScheduleInputs;