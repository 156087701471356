import Events from '../features/events';
import useDocumentTitle from '../hooks/useDocumentTitle';

// A page for displaying the Events feature.
// Should be routed to /tapahtumat.

const EventPage = ({ documentTitle }) => {
    useDocumentTitle(documentTitle);
    return <Events />;
};

export default EventPage;