import React, { useState } from 'react';
import {
    Box,
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
} from '@mui/material';
import FormTextField from '../../../../components/FormFields/FormTextField';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FormSelectMultiple from './FormSelectMultiple';
import { useSelector } from 'react-redux';
import HenkiloImageUploader from './ImageUploader/HenkiloImageUploader';
import { useAddNewPartnerMutation } from '../../../../api/partnersApiSlice';
import { useAddNewPersonMutation } from '../../../../api/peopleApiSlice';
import { useGetYhteystiedotQuery } from '../../../../api/yhteystiedotApiSlice';
import { selectAllRegionNames } from '../../../../api/regionsApiSlice';
const HenkilotAddForm = ({ initialValues, onSuccess, onError }) => {
    const [formData, setFormData] = useState(initialValues);
    const [showForm, setShowForm] = useState(false);
    const [addPersonData, setAddPersonData ] = useState({ nimi: '', email: '', titteli: '', puhelinnumero: '', puhelinnumero2: '', yhteystiedot: [], henkilokuva: null, tyonkuva: '' });
    const regions = useSelector(selectAllRegionNames);
    const tyypit = ['Seurakunta', 'Muu taho', 'Koulutuskeskus'];
    const [addNewPerson, { isLoading }] = useAddNewPersonMutation();
    const { data: yhteystiedot, error, isLoading: yhteystiedotLoading, isSuccess  } = useGetYhteystiedotQuery();
    const yhteystietoArray = yhteystiedot?.entities
        ? Object.values(yhteystiedot.entities).map(item => item.yhteystieto)
        : [];

    const handleLisääPerson = async(person) => {
        try {
            const result = await addNewPerson(person);
            if(result.error) onError('Henkilön luominen epäonnistui');
            if(!result.error) onSuccess('Henkilön luominen onnistui.', 'henkilot', result.data.id);
            setAddPersonData({ nimi: '', email: '', titteli: '', puhelinnumero: '', puhelinnumero2: '', yhteystiedot: [], henkilokuva: null, tyonkuva: '' });
            setShowForm(false);
        } catch (error) {
            console.error(error); // handle error
        }};

    const handleUpdateAddPersonData = (selectedItems) => {
        setAddPersonData((prevState) => ({
            ...prevState,
            yhteystiedot: selectedItems
        }));
    };

    const handleImageChange = (imageData) => {
        setAddPersonData((prevState) => ({ ...prevState, henkilokuva: imageData }));
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
            }}
        >
            {showForm && (
                <Box sx={{
                    flexGrow: 1, // Add flexGrow to make the box grow
                }}>
                    <Typography variant="h6" sx={{ pt: 2, mb: 2 }}>Henkilön lisääminen</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <FormTextField
                                key="addPersonNimi"
                                fieldName='nimi'
                                label='nimi'
                                multiline={true}
                                fullWidth={true}
                                value={addPersonData.nimi}
                                onChange={(event) => setAddPersonData({ ...addPersonData, nimi: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormTextField
                                key="addPersonPuhelinnumero"
                                fieldName='puhelinnumero'
                                label='puhelinnumero'
                                multiline={true}
                                fullWidth={true}
                                value={addPersonData.puhelinnumero}
                                onChange={(event) => setAddPersonData({ ...addPersonData, puhelinnumero: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormTextField
                                key="addPersonPuhelinnumero2"
                                fieldName='puhelinnumero2'
                                label='2. puhelinnumero'
                                multiline={true}
                                fullWidth={true}
                                value={addPersonData.puhelinnumero2}
                                onChange={(event) => setAddPersonData({ ...addPersonData, puhelinnumero2: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormTextField
                                key="addPersonTyonkuva"
                                fieldName='tyonkuva'
                                label='työnkuva'
                                multiline={true}
                                fullWidth={true}
                                value={addPersonData.tyonkuva}
                                onChange={(event) => setAddPersonData({ ...addPersonData, tyonkuva: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormSelectMultiple
                                key="addPersonYhteystiedot"
                                fieldName='addyhteystiedot'
                                existingYhteystiedot={addPersonData.yhteystiedot}
                                label='Yhteystiedot'
                                onUpdate={handleUpdateAddPersonData}
                                options={yhteystietoArray}
                                multiline={true}
                                fullWidth={true}
                                sx={{ width: '100%' }}
                            />
                        </Grid>


                        <Grid item xs={8}>
                            <Typography variant='h6'> Henkilökuva</Typography>
                            <HenkiloImageUploader fieldName='henkilokuva' onImageChange={handleImageChange}/>
                        </Grid>
                        <Grid item xs={8}>
                            <FormTextField
                                error={false}
                                key="addPersonEmail"
                                fieldName='email'
                                label='email'
                                multiline={true}
                                fullWidth={true}
                                value={addPersonData.email}
                                onChange={(event) => setAddPersonData({ ...addPersonData, email: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                            <FormTextField
                                error={false}
                                key="addPersonTitteli"
                                fieldName='titteli'
                                label='titteli'
                                multiline={true}
                                fullWidth={true}

                                value={addPersonData.titteli}
                                onChange={(event) => setAddPersonData({ ...addPersonData, titteli: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Box display="flex" justifyContent="flex-end" width="100%" pt={1}>

                            <Button
                                onClick={() => handleLisääPerson(addPersonData)}
                                size="small"
                                variant="contained"
                                startIcon={<AddIcon />}
                                sx={{ backgroundColor: 'taphaGreen.main' }}
                            >
          Tallenna
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            )}
            {showForm ? (
                <Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={() => setShowForm(!showForm)}
                        size='small'
                        variant='contained'
                        startIcon={<CloseIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Sulje
                    </Button>
                </Box>
            ) :
                (<Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={() => setShowForm(!showForm)}
                        size='small'
                        variant='contained'
                        startIcon={<AddIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Lisää henkilö
                    </Button>
                </Box>)}
        </Box>
    );
};

export default HenkilotAddForm;