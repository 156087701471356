import { useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import ErrorIcon from '@mui/icons-material/Error';
import {
    Alert,
    AlertTitle,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Typography
} from '@mui/material';

// The Props passed here are:
// place - the selected place. If the user has selected one, display its details.
// errorState - a boolean that represents whether the form's validation was a success or not.
//              If this is true, it always means that a place wasn't selected when the form
//              was submitted.

const AddressCard = ({ fieldName, errorState }) => {

    const place = useWatch({ name: fieldName });


    const cardHeaderProps = {
        titleTypographyProps: {
            variant: 'h7'
        }
    };

    const typographyProps = {
        ml: 2,
        variant: 'subtitle2',
        gutterBottom: true
    };


    if (errorState)
        return (
            <Alert sx={{ mt: 4 }} severity='error' icon={<ErrorIcon />}>
                <AlertTitle>Pakollinen</AlertTitle>
                Valitse tapahtumallesi tapahtumapaikka
            </Alert>
        );

    let content;

    const requiredProps = [
        'nimi',
        'osoite',
        'postinumero',
        'postitoimipaikka',
        'kunta',
        'maakunta'
    ];

    const placeDataIsValid = (
        place && requiredProps.every(prop => Object.keys(place).includes(prop)) && // The place has every needed prop.
        !Object.values(place).some(val => !val) // None of the needed props are falsy.
    );

    if (!place || !placeDataIsValid)
        content = (
            <CardHeader
                { ...cardHeaderProps }
                align= 'center'
                title='Valitse paikka nähdäksesi sen osoitetiedot'
            />
        );
    else
        content = (
            <CardContent>
                <CardHeader title={place?.nimi} { ...cardHeaderProps } />
                <Typography { ...typographyProps }>{place?.osoite}, {place?.postinumero} {place?.postitoimipaikka}</Typography>
                <Typography { ...typographyProps }> {place?.kunta}, {place?.maakunta}</Typography>
            </CardContent>
        );

    return (
        <>
            <Divider sx={{ fontFamily: '"Open Sans", sans-serif', py: 2 }}>
                Osoitetiedot
            </Divider>
            <Card
                variant='outlined'
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    borderRadius: 3
                }}>
                {content}
            </Card>
        </>
    );
};

AddressCard.propTypes = {
    fieldName: PropTypes.string,
    errorState: PropTypes.bool
};

export default AddressCard;