import { createEntityAdapter } from '@reduxjs/toolkit';

import { apiSlice } from './apiSlice';

const lokiAdapter = createEntityAdapter({
    selectId: (tiedote) => tiedote.id
});
const initialState = lokiAdapter.getInitialState();

export const lokiApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getLoki: builder.query({
            query: ({ page, size }) => ({
                url: '/loki',
                params: {
                    page,
                    size
                },
                validateStatus: (response, result) =>
                    response.status === 200 && !result.isError
            }),
            providesTags: ['Loki']
        }),
        addNewLoki: builder.mutation({
            query: newLoki => ({
                url: '/loki',
                method: 'POST',
                body: { ...newLoki }
            }),
            invalidatesTags: ['Loki'],
            onQueryStarted: (_, { dispatch }) => {
                dispatch(lokiApiSlice.util.invalidateTags('Loki'));
            }, onQueryCompleted: (_, { dispatch }) => {
                dispatch(lokiApiSlice.endpoints.getLoki.initiate());
            }
        }),
    })
});

export const {
    useGetLokiQuery,
    useAddNewLokiMutation,
} = lokiApiSlice;