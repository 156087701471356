import React from 'react';
import { Controller } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import { useFormContext } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';

const FormCheckbox = ({ fieldName, label, onChange, ...otherProps }) => {
    const {
        control,
        formState: { errors }
    } = useFormContext();

    return (
        <Controller
            name={fieldName}
            control={control}
            render={({ field }) => (
                <FormControlLabel
                    control={
                        <Checkbox
                            {...field}
                            {...otherProps}
                            checked={field.value || false}
                            onChange={(e) => {
                                field.onChange(e);
                                onChange && onChange(e.target.checked); // Notify parent about change
                            }}
                        />
                    }
                    label={label}
                />
            )}
        />
    );
};

export default FormCheckbox;

