import { createEntityAdapter } from '@reduxjs/toolkit';

import { apiSlice } from './apiSlice';

const tiedotteetAdapter = createEntityAdapter({
    selectId: (tiedote) => tiedote.id
});
const initialState = tiedotteetAdapter.getInitialState();

export const tiedotteetApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTiedotteet: builder.query({
            query: () => ({
                url: '/tiedotteet',
                validateStatus: (response, result) =>
                    response.status === 200 && !result.isError
            }),
            providesTags: ['Tiedotteet']
        }),
        addNewTiedote: builder.mutation({
            query: newTiedote => ({
                url: '/tiedotteet',
                method: 'POST',
                body: { ...newTiedote }
            }),
            invalidatesTags: ['Tiedotteet'],
            onQueryStarted: (_, { dispatch }) => {
                dispatch(tiedotteetApiSlice.util.invalidateTags('Tiedotteet'));
            }, onQueryCompleted: (_, { dispatch }) => {
                dispatch(tiedotteetApiSlice.endpoints.getTiedotteet.initiate());
            }
        }),
        deleteTiedote: builder.mutation({
            query: ({ id }) => ({
                url: '/tiedotteet',
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: ['Tiedotteet']
        }),
    })
});

export const {
    useGetTiedotteetQuery,
    useAddNewTiedoteMutation,
    useDeleteTiedoteMutation
} = tiedotteetApiSlice;