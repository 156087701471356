import LoginForm from './components/LoginForm';
import LoginHeader from './components/LoginHeader';

// All the authentication related components and functions should be accessed
// through here, apart from components required for persisting log in and
// requiring authentication.

// The feature is displayed on /login-route.

const Authentication = () => {
    return (
        <>
            <LoginHeader />
            <LoginForm />
        </>
    );
};

export default Authentication;