/*
    API slice used to fetch partners (Yhteistyötahot) from the backend.
    it's not inside any feature's directory, as it will likely be used in at
    least two different features (AdminPanel, Events).

*/

import { createSelector, createEntityAdapter } from '@reduxjs/toolkit';

import { apiSlice } from './apiSlice';

const partnersAdapter = createEntityAdapter({
    selectId: (partner) => partner.id
});
const initialState = partnersAdapter.getInitialState();

export const partnersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPartners: builder.query({
            query: () => ({
                url: '/yhteistyotahot',
                validateStatus: (response, result) =>
                    response.status === 200 && !result.isError
            }),
            transformResponse: responseData =>
                partnersAdapter.setAll(initialState, responseData),
            providesTags: ['Partners']
        }),
        addNewPartner: builder.mutation({
            query: newPartner => ({
                url: '/yhteistyotahot',
                method: 'POST',
                body: newPartner
            }),
            invalidatesTags: ['Partners']
        }),
        deletePartner: builder.mutation({
            query: ({ id }) => ({
                url: '/yhteistyotahot',
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: ['Partners']
        })
    })
});

export const {
    useGetPartnersQuery,
    useAddNewPartnerMutation,
    useDeletePartnerMutation
} = partnersApiSlice;

export const selectPartnersResult = partnersApiSlice.endpoints.getPartners.select('PartnersList');

const selectPartnersData = createSelector(
    selectPartnersResult,
    partnersResult => partnersResult.data
);

export const {
    selectIds: selectPartnerIds,
    selectEntities: selectPartnerEntities
} = partnersAdapter.getSelectors(state => selectPartnersData(state) ?? initialState);

// Selector for only the partners themselves without ids.
export const selectAllPartners = createSelector(
    selectPartnerIds,
    selectPartnerEntities,
    (ids, partners) => ids.map(id => partners[id].nimi)
);