import { Children, cloneElement } from 'react';
import { useGetReportQuery } from '../../../api/reportsApiSlice';
import { Alert } from '@mui/material';

import ProgressLoader from '../../../components/ProgressLoader';

// This component should be used with withEvent, so no need to check the user's
// authorization here. If the user can edit the event, they can also edit the
// report attached to the event.

const WithReport = ({ event, children }) => {

    const reportId = event?.raportti;

    const {
        data: report,
        isLoading,
        isError
    } = useGetReportQuery({ id: reportId });

    if (isLoading)
        return <ProgressLoader msg='Ladataan raporttia...' />;

    if (isError)
        return <Alert severity='error'>Raportin haussa tapahtui virhe</Alert>;

    // If we don't have a report or the response contains a message, return
    // an alert saying the report wasn't found.

    if (!report || report?.message)
        return <Alert severity='error'>Raporttia ei löytynyt</Alert>;

    const childrenWithProps = Children.map(children, child => {
        return cloneElement(child, { event, report });
    });

    return <>{childrenWithProps}</>;
};

export default WithReport;