const fetchData = async (endpoint, accessToken, settingFunction) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    const responseData = await response.json();
    settingFunction(responseData);
};

export default fetchData;