/*
    API slice used to fetch the event types (luonteet) from the backend.
    It's not inside any feature's directory, as it will likely be used in at
    least two different features (AdminPanel, Events).
*/

import { createSelector } from '@reduxjs/toolkit';
import { createEntityAdapter } from '@reduxjs/toolkit';

import { apiSlice } from './apiSlice';

const yhteystiedotAdapter = createEntityAdapter({
    selectId: (yhteystieto) => yhteystieto.id
});
const initialState = yhteystiedotAdapter.getInitialState();

export const yhteystiedotApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getYhteystiedot: builder.query({
            query: () => ({
                url: '/yhteystiedot',
                validateStatus: (response, result) =>
                    response.status === 200 && !result.isError
            }),
            transformResponse: responseData =>
                yhteystiedotAdapter.setAll(initialState, responseData),
            providesTags: ['Yhteystiedot']
        }),
        addNewYhteystieto: builder.mutation({
            query: newYhteystieto => ({
                url: '/yhteystiedot',
                method: 'POST',
                body: { ...newYhteystieto }
            }),
            invalidatesTags: ['Yhteystiedot']
        }),
        deleteYhteystieto: builder.mutation({
            query: ({ id }) => ({
                url: '/yhteystiedot',
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: ['Yhteystiedot']
        })
    })
});

export const {
    useGetYhteystiedotQuery,
    useAddNewYhteystietoMutation,
    useDeleteYhteystietoMutation
} = yhteystiedotApiSlice;

export const selectYhteystiedotResult = yhteystiedotApiSlice.endpoints.getYhteystiedot.select('YhteystiedotList');

const selectYhteystiedotData = createSelector(
    selectYhteystiedotResult,
    yhteystiedotResult => yhteystiedotResult.data
);

export const {
    selectIds: selectYhteystietoIds,
    selectEntities: selectYhteystietoEntities
} = yhteystiedotAdapter.getSelectors(state => selectYhteystiedotData(state) ?? initialState);

// Selector for only the event types themselves without ids.
export const selectAllYhteystiedot = createSelector(
    selectYhteystietoIds,
    selectYhteystietoEntities,
    (ids, yhteystiedot) => ids.map(id => yhteystiedot[id].yhteystieto)
);