import PropTypes, { instanceOf } from 'prop-types';
import { useState, useEffect } from 'react';
import React from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { Avatar, Box, Button, Chip, Divider, Grid, Typography, ListItem, IconButton, Collapse, ListItemText, List, Modal, RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import EventSchedule from '../EventForm/Schedule';
import { selectAllPeopleNamesAndTitles } from '../../../../api/peopleApiSlice';
import ImageSlider from './ImageSlider';
import ProgressLoader from '../../../../components/ProgressLoader';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import { selectCurrentUser } from '../../../authentication/authSlice';
import { roles } from '../../../../config/roles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EventDesc from './EventDesc';
import { useEditEventMutation } from '../../../../api/eventsApiSlice';
import TilaisuusHenkilotList from './TilaisuusHenkilotList';
import Kuvaus from './Kuvaus';
import ShowReport from './showReport';
import { useGetReportQuery } from '../../../../api/reportsApiSlice';




const ViewEvent = ({ event }) => {

    const currentUser = useSelector(selectCurrentUser);
    const [laskunumero, setLaskunumero] = useState(null);






    useDocumentTitle(event?.nimi);

    const isNotAuthorized =
        event.status === 'luonnos' && currentUser?.userId !== event.user.id && currentUser.role !== 'Admin' && currentUser.role !== 'Muokkaaja';


    const allPeopleNamesAndTitles = useSelector(selectAllPeopleNamesAndTitles);
    const [editEvent] = useEditEventMutation();
    const [userInvolvement, setUserInvolvement] = useState({
        henkilo: currentUser?.name || '',
        rooli: '',
        julkinen: 'Kyllä',
    });

    const [showLisatiedot, setShowLisatiedot] = useState(false);

    const toggleLisatiedot = () => {
        setShowLisatiedot(!showLisatiedot);
    };

    const [isMukanaOlevatOpen, setIsMukanaOlevatOpen] = useState(false);

    const toggleMukanaOlevat = () => {
        setIsMukanaOlevatOpen(!isMukanaOlevatOpen);
    };

    const reportId = event.raportti || null;

    const {
        data: report,
        isLoading,
        isError
    } = useGetReportQuery({ id: reportId });



    const [collapseOpen, setCollapseOpen] = useState(
        event?.ohjelma?.map(() => Array(false))
    );

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(''); // Initialize with an empty string or a default option

    const [isRaporttiModalOpen, setIsRaporttiModalOpen] = useState(false);
    const [isLaskutusModalOpen, setIsLaskutusModalOpen] = useState(false);

    // Function to open the modal
    const openModal = () => {
        setIsModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const openRaporttiModal = () => {
        setIsRaporttiModalOpen(true);
    };
    const openLaskutusModal = () => {
        setIsLaskutusModalOpen(true);
    };

    // Function to close the modal
    const closeLaskutusModal = () => {
        setIsLaskutusModalOpen(false);
    };
    const closeRaporttiModal = () => {
        setIsRaporttiModalOpen(false);
    };

    // Function to handle option selection
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    // Function to handle the "Ilmoittaudu" button click
    const handleIlmoittauduClick = () => {
        openModal();
    };
    const handleRaporttiOpenClick = () => {
        openRaporttiModal();
    };

    const handleLaskutusOpenClick = () => {
        openLaskutusModal();
    };

    const listItemStyle = {
        border: '1px solid',
        borderRadius: 2,
        borderColor: 'taphaWhite.main' ,
        backgroundColor: 'taphaWhite.main',
        mt: 1,
        gap: 1,
        display: 'flex',
        flexDirection: 'column',
        '&:hover': !collapseOpen && {
            border: '1px solid',
            borderColor: 'taphaGreen.main',
            cursor: 'pointer'
        }
    };



    const toggleCollapse = (ohjelmaIndex, tilaisuusIndex) => {
        setCollapseOpen((prevCollapseOpen) => {
            const updatedCollapseOpen = [...prevCollapseOpen];
            updatedCollapseOpen[ohjelmaIndex][tilaisuusIndex] = !updatedCollapseOpen[ohjelmaIndex][tilaisuusIndex];
            return updatedCollapseOpen;
        });
    };

    // Editing and reporting are possible if the user created the event or their
    // role allows global editing and reporting across all events.

    // Editing is possible only if the event has not ended yet.
    // Reporting is possible only if the event has ended.

    const eventHasNotEnded = dayjs(event.lopetus, 'DD.MM.YYYY HH:mm').isAfter(dayjs()); // Event ending is after the current date.
    const userAuthorized = (
        currentUser?.userId === event?.user?.id ||
        [roles.admin, roles.muokkaaja, roles.laskuttaja].includes(currentUser.role)
    );

    const canEdit = userAuthorized && (eventHasNotEnded || currentUser?.role === roles.admin);
    const canReport = userAuthorized && !eventHasNotEnded;

    // Redirect to report editing if the event already has a report id attach
    // to it. Else, redirect to creating a new report.

    const reportingLink = event?.raportti
        ? `/tapahtumat/raportit/muokkaa/${event.id}`
        : `/tapahtumat/raportit/${event.id}`;

    const images = event?.kuvat;
    const bannerImage = images?.find(img => img.bannerImage === true);



    const eventPageStyles = {
        margin: 'auto',
        width: { xs: '90%', md: '80%' },
        px: 0,
        py: 5,
    };

    const imageStyles = {
        height: 250,
        width: 250,
        objectFit: 'cover',
        marginRight: 2
    };


    const handleEditEvent = async (updatedEvent) => {
        await editEvent(updatedEvent)
            .then((response) => {
            // Handle success if needed
            })
            .catch((error) => {
            // Handle error if needed
                console.error('Error updating event:', error);
            });
    };

    const getUserRegisteredRole = () => {
        if (!event || !event.mukanaolevat || !currentUser) {
            return ''; // Return an empty string or handle accordingly.
        }

        const userRoles = ['Ehtoollisavustaja', 'Esirukouspalvelu']; // Roles to check

        // Find the user's role in the mukanaolevat array
        for (const role of userRoles) {
            const userInvolvement = event.mukanaolevat.find((involvement) => involvement.henkilo === currentUser.name && involvement.rooli === role);
            if (userInvolvement) {
                return role; // Return the role if found
            }
        }

        return ''; // Return an empty string if not found
    };

    const isUserAlreadyRegistered = () => {
        return event.mukanaolevat.some((involvement) => {
            return involvement.henkilo === currentUser.name && ['Ehtoollisavustaja', 'Esirukouspalvelu'].includes(involvement.rooli);
        });
    };

    useEffect(() => {
        if (userInvolvement.rooli) {
            // Create the updated event object
            const updatedEvent = {
                ...event,
                mukanaolevat: [...event.mukanaolevat, userInvolvement],
            };

            // Call the mutation to update the event in the database
            handleEditEvent(updatedEvent);
        }
    }, [userInvolvement, event, editEvent]);

    const handleHyvaksyClick = () => {
        // Update user involvement object with the selected option
        setUserInvolvement((prevUserInvolvement) => ({
            ...prevUserInvolvement,
            rooli: selectedOption,
        }));


        // Close the modal
        closeModal();

        // Refresh the page after 500 milliseconds
        setTimeout(() => {
            window.location.reload();
        }, 500);
    };

    const handleSendToLaskutus = () => {
        // Update user involvement object with the selected option
        const updatedEvent = {
            ...event,
            status: 'laskutuksessa',
        };

        // Call the mutation to update the event in the database
        handleEditEvent(updatedEvent);

        // Refresh the page after 500 milliseconds
        setTimeout(() => {
            window.location.reload();
        }, 500);
    };
    const handleLaskuta = (laskunumero) => {
        // Update user involvement object with the selected option
        const updatedEvent = {
            ...event,
            status: 'laskutettu',
            laskunumero: laskunumero
        };

        // Call the mutation to update the event in the database
        handleEditEvent(updatedEvent);

        // Refresh the page after 500 milliseconds
        setTimeout(() => {
            window.location.reload();
        }, 500);
    };
    const handleCancelRegistration = () => {
        if (!event || !currentUser) {
            closeModal(); // Close the modal if event or currentUser is not available
            return;
        }

        const userRoles = ['Ehtoollisavustaja', 'Esirukouspalvelu']; // Roles to check

        // Create a new mukanaolevat array without the user's registration
        const updatedMukanaolevat = event.mukanaolevat.filter((involvement) => {
            return !(involvement.henkilo === currentUser.name && userRoles.includes(involvement.rooli));
        });

        // Create the updated event object with the modified mukanaolevat array
        const updatedEvent = {
            ...event,
            mukanaolevat: updatedMukanaolevat,
        };

        // Call the mutation to update the event in the database
        handleEditEvent(updatedEvent);

        // Close the modal
        closeModal();
        setTimeout(() => {
            window.location.reload();
        }, 500);
    };

    const calculateTotalKansanopistotunnit = (event) => {
        let totalKansanopistotunnit = 0;

        // Loop through each day in 'ohjelma'
        event?.ohjelma?.forEach((day) => {
            // Loop through 'tilaisuudet' for each day
            day.tilaisuudet.forEach((tilaisuus) => {
            // Check if 'kansanopistotunnit' exists and add to the total
                if (tilaisuus?.kansanopistotunnit && tilaisuus?.kansanopistotunnit?.length > 0) {
                    totalKansanopistotunnit += tilaisuus?.kansanopistotunnit[0]?.kansanopistotunnit;
                }
            });
        });

        return totalKansanopistotunnit;
    };

    // Calculate total kansanopistotunnit
    const totalKansanopistotunnit = calculateTotalKansanopistotunnit(event);

    if (!currentUser || !event)
        return <ProgressLoader msg='Ladataan tietoja...' />;

    if(event.message === 'No events were found with this ID.'){
        return (
            <Box sx={{ textAlign: 'center', padding: '50px' }}>
                <Typography variant="h5" color="error">
                        Access Denied
                </Typography>
                <Typography variant="body1">
                        Tätä tapahtumaa ei löytynyt.
                </Typography>
            </Box>
        );
    }
    if (isNotAuthorized) {
        return (
            <Box sx={{ textAlign: 'center', padding: '50px' }}>
                <Typography variant="h5" color="error">
                        Access Denied
                </Typography>
                <Typography variant="body1">
                        Sinulla ei ole oikeutta nähdä tätä tapahtumaa.
                        Ota yhteyttä tapahtuman tekijään {event?.user?.name} saadaksesi lisätietoja.
                </Typography>
            </Box>
        );
    }



    return (
        <Box sx={eventPageStyles}>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
                <Box flex={1} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
                    {bannerImage ? (
                        <Box
                            component='img'
                            alt='event image'
                            src={bannerImage?.src}
                            sx={imageStyles}
                        />
                    ) : (
                        <Avatar
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                ...imageStyles
                            }}>
                            <img src=' /SROS-logo-vihrea.jpg' />
                        </Avatar>
                    )}
                </Box>
                <Box flex={3}>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
                        <Box>
                            <Typography component='h1' variant='h2' sx={{
                                fontSize: {
                                    xs: '2rem', // Adjust the font size for smaller screens (e.g., phones)
                                    sm: '2.5rem', // Font size for larger screens (e.g., tablets)
                                    md: '3rem', // Font size for even larger screens (e.g., desktop)
                                    lg: '3.5rem', // Font size for extra large screens
                                    // You can add more breakpoints and font sizes as needed
                                },
                            }}>{event.nimi}</Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                ml: { xs: 0, sm: 2 },
                                mt: 1
                            }}>
                            <Button
                                disabled={!canEdit}
                                onClick={sessionStorage.setItem('copiedSessionValues', null)}
                                component={Link}
                                to={`/tapahtumat/muokkaa/${event.id}`}
                                variant='contained'
                                sx={{
                                    borderRadius: 3,
                                    backgroundColor: 'taphaPurple.main',
                                    '&:hover': {
                                        backgroundColor: 'taphaPurple.dark'
                                    }
                                }}>
                                Muokkaa
                            </Button>
                            <Button
                                disabled={!canReport}
                                component={Link}
                                to={reportingLink}
                                variant='contained'
                                size='small'
                                sx={{
                                    mx: 2,
                                    py: 1,
                                    borderRadius: 3,
                                    whiteSpace: 'nowrap',
                                    backgroundColor: 'taphaPurple.main',
                                    '&:hover': {
                                        backgroundColor: 'taphaPurple.dark'
                                    }
                                }}>
                                { event?.raportti ? 'Muokkaa raporttia' : 'Raportoi' }
                            </Button>
                            {/* Add the "Ilmoittaudu" button */}
                            {event?.luonteet.includes('Jumalanpalvelus') && (
                                <Button
                                    variant="contained"
                                    onClick={handleIlmoittauduClick}
                                    sx={{
                                        mx: 2,
                                        py: 1,
                                        borderRadius: 3,
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                Avustustehtävät
                                </Button>
                            )}
                        </Box>
                    </Box>
                    <Grid container>
                        <Grid container item xs={12} sx={{ my: 2 }}>
                            <Grid item xs={3} sm={1}>
                                <Typography sx={{ fontWeight: '600', ml: 1 }}>Alkaa:</Typography>
                            </Grid>
                            <Grid item xs={9} sm={11}>
                                <Typography sx={{ fontWeight: '600', ml: 2 }}>{event.aloitus}</Typography>
                            </Grid>
                            <Grid item xs={3} sm={1}>
                                <Typography sx={{ fontWeight: '600', ml: 1 }}>Päättyy:</Typography>
                            </Grid>

                            <Grid item xs={9} sm={11}>
                                <Typography sx={{ fontWeight: '600', ml: 2.9 }}>{event.lopetus}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box>
                        <Typography component='p' variant='h6'>{event?.paikka?.nimi}</Typography>
                        <Typography>{event.paikka?.osoite}</Typography>
                        <Typography>{event.paikka?.postinumero} {event.paikka?.postitoimipaikka} </Typography>
                        <Typography>{event.paikka?.maakunta} {event.paikka?.kunta} </Typography>
                        <Typography>{event.paikka?.keskus === 'Ystävämatkat' && event.paikka?.kuvaus}</Typography>
                    </Box>
                    <Grid container>

                        <Typography>Tapahtuman tila:</Typography>
                        <Chip
                            label={event.status}
                            color={event.status === 'julkaistu' ? 'success' : 'primary'}
                            sx={{ ml: 1 }}
                        />
                    </Grid>
                    {event.raportti && (
                        <Button
                            variant="contained"
                            onClick={handleRaporttiOpenClick}
                            sx={{
                                mt: 2,
                                py: 1,
                                borderRadius: 3,
                                whiteSpace: 'nowrap',
                            }}
                        >
                                Näytä raportti
                        </Button>
                    )}
                    {event.raportti && (
                        <Button
                            variant="contained"
                            onClick={handleSendToLaskutus}
                            disabled={event.status === 'laskutuksessa' || event.status === 'laskutettu'}
                            sx={{
                                backgroundColor: 'green',
                                mt: 2,
                                ml: 2,
                                py: 1,
                                borderRadius: 3,
                                whiteSpace: 'nowrap',
                                '&:hover': {
                                    backgroundColor: 'green', // Keep the background color green on hover
                                },
                            }}
                        >
                            {event.status === 'laskutuksessa' ? 'Laskutuksessa' : event.status === 'laskutettu' ? 'Laskutettu' : 'Lähetä laskutukseen'}
                        </Button>
                    )}
                    {event.raportti && currentUser.role === 'Laskuttaja' && event.status === 'laskutuksessa' && (
                        <Button
                            variant="contained"
                            onClick={handleLaskutusOpenClick}
                            disabled={event.status !== 'laskutuksessa'}
                            sx={{
                                backgroundColor: 'green',
                                mt: 2,
                                ml: 2,
                                py: 1,
                                borderRadius: 3,
                                whiteSpace: 'nowrap',
                                '&:hover': {
                                    backgroundColor: 'green', // Keep the background color green on hover
                                },
                            }}
                        >
                            {event.status === 'laskutuksessa' ? 'Laskuta' : 'Laskutettu'}
                        </Button>
                    )}
                </Box>
            </Box>
            <Box>
                <Divider sx={{ my: 3 }} />
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
                    <Box flex={1}>
                        <div>
                            <Typography variant='h5' component='h3'>Luonteet:</Typography>
                            {event.luonteet?.map((luonne) =>
                                <Typography key={luonne}>{luonne}</Typography>
                            )}
                            <Typography variant='h5' component='h3' my={2}>Kohdeyleisöt:</Typography>
                            {event.kohdeyleiso?.map((yleiso) =>
                                <Typography key={yleiso}>{yleiso}</Typography>
                            )}
                            <div>
                                {event.vastuuhenkilot && event.vastuuhenkilot.length > 0 && (
                                    <>
                                        <>
                                            <Typography variant='h5' component='h3' my={2}>Vastuuhenkilöt:</Typography>
                                            <List>
                                                {event.vastuuhenkilot.map((involvement, index) => (
                                                    <ListItem key={index}>
                                                        <ListItemText
                                                            primary={involvement.henkilo}
                                                        />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </>

                                    </>
                                )}
                            </div>
                            {event.kansanopistokurssi === true && (<><Typography variant='h5' component='h3' my={2}>Tapahtuman tyyppi:</Typography><Typography>Tämä on kansanopistokurssi</Typography><Typography>Kokonaistunnit: {totalKansanopistotunnit}</Typography></>)}

                            {event.lyytilinkit?.ilmoittautumislinkki?.length > 0 && (
                                <>
                                    <Typography variant='h5' component='h3' my={2}>
                                        Ilmoittautumislinkki:
                                    </Typography>
                                    <Typography key={event.lyytilinkit.ilmoittautumislinkki}>
                                        <a href={event.lyytilinkit.ilmoittautumislinkki}>Ilmoittaudu</a>
                                    </Typography>
                                </>
                            )}
                            <Grid container>
                                <Typography variant='h5' component='h3' my={2}>Tapahtuman id: {event?.id}</Typography>
                            </Grid>
                            <Grid container>
                                <Typography variant='h5' component='h3' my={2}>Tapahtuman tekijä: {event?.user?.name}</Typography>
                            </Grid>
                        </div>
                        <div>
                            {event.mukanaolevat && event.mukanaolevat.length > 0 && (
                                <>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{ mt: 2 }}
                                        onClick={toggleMukanaOlevat}
                                    >
                                        {isMukanaOlevatOpen ? 'Sulje mukana olevat' : 'Näytä mukana olevat'}
                                    </Button>
                                    {isMukanaOlevatOpen && (
                                        <>
                                            <Typography variant='h5' component='h3' my={2}>Mukana tapahtumassa:</Typography>
                                            <List>
                                                {event.mukanaolevat.map((involvement, index) => (
                                                    <ListItem key={index}>
                                                        <ListItemText
                                                            primary={involvement.henkilo}
                                                            secondary={involvement.rooli}
                                                        />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                        <div>
                            {event.lisatiedot && event.lisatiedot.replace(/<[^>]*>?/gm, '').trim().length > 0 && (
                                <>
                                    {showLisatiedot && (
                                        <Grid><EventDesc eventDesc={event.lisatiedot} /></Grid>
                                    )}
                                    <Button variant="outlined" size="small" sx={{ mt: 2, color: 'text.secondary' }} onClick={toggleLisatiedot}>
                                        Lisätietoja
                                    </Button>
                                </>
                            )}
                        </div>
                    </Box>

                    <Box flex={2} sx={{ mt: { xs: 3, md: 0 }, alignItems: 'center', justifyContent: 'flex-start' }}>
                        <Typography variant='h5' component='h3' sx={{ pl: 3 }}>Tapahtuman kuvaus:</Typography>
                        <Grid><Kuvaus marginLeft={3} eventDesc={event.kuvaus} /></Grid>
                        <Typography variant='h5' component='h3' sx={{ pl: 3 }}>Tapahtuman ohjelma:</Typography>
                        <Box>
                            <List sx={{ pl: 3 }}>

                                {event?.ohjelma?.map((ohjelmanumero, ohjelmaIndex) => (
                                    <div key={ohjelmaIndex}>
                                        <ListItem disableGutters disablePadding>
                                            <Typography variant='h6'>{ohjelmanumero?.tilaisuudet?.length >0 ? ohjelmanumero.pvm : ''}</Typography>
                                        </ListItem>


                                        {ohjelmanumero?.tilaisuudet?.map((tilaisuus, tilaisuusIndex) => {

                                            const textProps = tilaisuus.nimi?.length && tilaisuus.aloitus?.length && tilaisuus.lopetus?.length && {
                                                primary: tilaisuus.nimi,
                                                secondary: (
                                                    <>
                                                        {`${tilaisuus.aloitus} - ${(tilaisuus.lopetus !== 'Invalid Date') ? tilaisuus.lopetus : ''}`}
                                                        {tilaisuus?.kansanopistotunnit?.length > 0 ? (
                                                            <>
                                                                <br /> {/* Line break here */}
                                                                {`Kansanopistotunteja: ${tilaisuus?.kansanopistotunnit[0]?.kansanopistotunnit}`}
                                                            </>
                                                        ) : ''}
                                                    </>
                                                ),
                                            };

                                            return (
                                                <ListItem key={tilaisuusIndex} disableGutters disablePadding sx={listItemStyle}>
                                                    <IconButton disableRipple onClick={() => toggleCollapse(ohjelmaIndex, tilaisuusIndex)} sx={{ ml: 1 }}>
                                                        {collapseOpen[ohjelmaIndex][tilaisuusIndex] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                    </IconButton>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'flex-start',
                                                            gap: 2,
                                                        }}
                                                    >




                                                        <ListItemText
                                                            {...textProps}
                                                            sx={{ ml: 1 }}
                                                            primaryTypographyProps={{ variant: 'h6', textAlign: 'center' }}
                                                            secondaryTypographyProps={{
                                                                variant: 'overline',
                                                                textAlign: 'center',
                                                                style: { textTransform: 'none' } // Add this line to prevent all caps
                                                            }}
                                                        />
                                                    </Box>

                                                    <Collapse sx={{ width: '100%' }} unmountOnExit in={collapseOpen[ohjelmaIndex][tilaisuusIndex]} timeout='auto'>

                                                        <EventDesc marginLeft={1} eventDesc={tilaisuus.kuvaus} />

                                                        <Divider sx={{ my: 2 }} />
                                                        {/* Display attending people when collapse is open */}
                                                        {tilaisuus.henkilot.length > 0 && (
                                                            <TilaisuusHenkilotList attendingPeople={tilaisuus.henkilot} findTitles={allPeopleNamesAndTitles} />
                                                        )}
                                                    </Collapse>
                                                </ListItem>
                                            );
                                        })}
                                    </div>
                                ))}
                            </List>
                        </Box>
                    </Box>








                </Box>
                <Divider sx={{ my: 3 }} />
                <Box sx={ { display: 'flex', mb: 3 }}>
                    <Box flex={3}>
                        <Typography align='center' variant='h4'>
                            {images?.length ?'Tapahtuman kuvat' : 'Tapahtumalla ei vielä ole kuvia'}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    {images?.length ? (<ImageSlider images={images} />) : (<></>)}
                </Box>
            </Box>

            {/* Modal dialog */}
            <Modal
                open={isModalOpen}
                onClose={closeModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 2,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    {isUserAlreadyRegistered() ? (
                        // Display a message if the user is already registered
                        <div>
                            <Typography variant="h6" id="modal-title">
                                Olet jo ilmoittautunut tehtävään: {getUserRegisteredRole()}
                            </Typography>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button variant="contained" onClick={closeModal}>
                                    Sulje
                                </Button>
                                <Button variant="contained" onClick={handleCancelRegistration}>
                                    Peru ilmoittautuminen
                                </Button>
                            </div>
                        </div>
                    ) : (
                        // Display role selection options if the user is not registered
                        <div>
                            <Typography variant="h6" id="modal-title">
                                Valitse avustustehtävä
                            </Typography>
                            <RadioGroup
                                value={selectedOption}
                                onChange={handleOptionChange}
                            >
                                <FormControlLabel
                                    value="Ehtoollisavustaja"
                                    control={<Radio />}
                                    label="Ehtoollisavustaja"
                                />
                                <FormControlLabel
                                    value="Esirukouspalvelu"
                                    control={<Radio />}
                                    label="Esirukouspalvelu"
                                />
                            </RadioGroup>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button variant="contained" onClick={closeModal}>
                                    Sulje
                                </Button>
                                <Button variant="contained" onClick={handleHyvaksyClick} sx={{ backgroundColor: 'taphaGreen.main', color: 'white' }}>
                                    Hyväksy
                                </Button>
                            </div>
                        </div>
                    )}
                </Box>
            </Modal>
            {/* Modal dialog */}
            <Modal
                open={isRaporttiModalOpen}
                onClose={closeRaporttiModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        width: 400, // Adjust the width as needed
                        maxHeight: '80vh', // Set maximum height and enable scrolling
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 2,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        overflowY: 'auto', // Enable vertical scrolling
                        '@media (min-width: 600px)': {
                            width: 600, // Adjust width for screens wider than 600px
                        },
                        '@media (min-width: 960px)': {
                            width: 800, // Adjust width for screens wider than 960px
                        },
                        '@media (min-width: 1280px)': {
                            width: 1000, // Adjust width for screens wider than 1280px
                        },
                    }}
                >
                    {<ShowReport event={event} report={report}/>}
                </Box>
            </Modal>
            <Modal
                open={isLaskutusModalOpen}
                onClose={closeLaskutusModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        width: 400, // Adjust the width as needed
                        maxHeight: '80vh', // Set maximum height and enable scrolling
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 2,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        overflowY: 'auto', // Enable vertical scrolling
                        '@media (min-width: 600px)': {
                            width: 600, // Adjust width for screens wider than 600px
                        },
                        '@media (min-width: 960px)': {
                            width: 800, // Adjust width for screens wider than 960px
                        },
                        '@media (min-width: 1280px)': {
                            width: 1000, // Adjust width for screens wider than 1280px
                        },
                    }}
                >
                    <h1 id="modal-title">Laskutus</h1>
                    <TextField
                        label="Laskunumero"
                        value={laskunumero}
                        onChange={(e) => setLaskunumero(e.target.value)}
                        fullWidth
                    />
                    <Button
                        variant="contained"
                        sx={{
                            mt:2
                        }}
                        color="primary"
                        onClick={() => handleLaskuta(laskunumero)}
                    >
                        Tallenna laskunumero
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
};

ViewEvent.propTypes = {
    event: PropTypes.object
};

export default ViewEvent;