import { useSelector } from 'react-redux';
import {
    Container,
    Grid,
    Typography,
} from '@mui/material';

import {
    selectAllEvents,
    selectEventsByUser,
    selectNonPublishedEventsByUser,
    selectPublishedEventsByUser,
    useGetAllLaskutetetutQuery,
    useGetAllLaskutettavatQuery,
    useGetAllUserEventsQuery
} from '../api/eventsApiSlice';

import useDocumentTitle from '../hooks/useDocumentTitle';
import { selectCurrentUser } from '../features/authentication/authSlice';
import { useGetTiedotteetQuery } from '../api/tiedotteetApiSlice';
import SearchList from '../components/SearchList';
import ProgressLoader from '../components/ProgressLoader';
import EventDesc from '../features/events/components/ViewEvent/EventDesc';
import { useDeleteEventMutation } from '../api/eventsApiSlice';

const Home = ({ documentTitle }) => {

    useDocumentTitle(documentTitle);

    const { data: events, isLoading, refetch, setDeletedEventId } = useGetAllUserEventsQuery();
    const { data: tiedotteet, isLoading: tiedotteetLoading } = useGetTiedotteetQuery();
    const { data: laskutettavatTapahtumat, isLoading: laskutettavatLoading } = useGetAllLaskutettavatQuery();
    const { data: laskutetutTapahtumat } = useGetAllLaskutetetutQuery();
    const [deleteEvent ] = useDeleteEventMutation();
    const { role } = useSelector(selectCurrentUser);







    if (isLoading || tiedotteetLoading || laskutettavatLoading)
        return <ProgressLoader msg='Ladataan tapahtumia..' />;

    const lastEditedUserEvents = events?.length > 0 ? [...events].sort((a, b) => new Date(b.lastEditedTimestamp) - new Date(a.lastEditedTimestamp)) : [];



    const publishedUserEvents = events?.filter(event => event.status !== 'luonnos');

    const nonPublishedUserEvents = events?.filter(event => event.status === 'luonnos');
    const laskutettavat = laskutettavatTapahtumat?.filter(event => event.status !== 'luonnos');
    const laskutetut = laskutetutTapahtumat?.filter(event => event.status !== 'luonnos');





    const showTiedoteOnFrontPage = tiedotteet?.filter(tiedote => tiedote.etusivu === true);



    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'Europe/Helsinki'
        };
        return date.toLocaleDateString('fi-FI', options);
    };
    const lastUpdatedDate = formatDate(showTiedoteOnFrontPage && showTiedoteOnFrontPage[0]?.päivitetty);

    const searchListDimensions = {
        height: 400, // A px number must be specified for virtual list height.
        itemSize: 55 // Item size in px.
    };

    const deleteEventById = async(event) => {
        try {
            deleteEvent(event);
            refetch();
        } catch(error){
            console.log(error);
        }
    };


    return (
        <Container maxWidth='lg'>
            <Grid container rowSpacing={1} columnSpacing={5} mt={3}>
                <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
                    <Typography fontFamily='"Bree Serif", serif' variant='h6'>
                        {showTiedoteOnFrontPage ? showTiedoteOnFrontPage[0]?.otsikko : ''}
                    </Typography>
                    <Grid>
                        {showTiedoteOnFrontPage ? (
                            <>
                                <EventDesc eventDesc={showTiedoteOnFrontPage[0]?.tiedote} />
                                <Typography
                                    variant="body2"
                                    style={{
                                        fontFamily: 'Typography',
                                        fontSize: '14px',
                                        color: '#333',
                                    }}
                                >
                                Päivitetty viimeksi: {lastUpdatedDate}
                                </Typography>
                            </>
                        ) : (
                            ''
                        )}
                    </Grid>
                </Grid>
                {role !== 'Laskuttaja' ? <>
                    <Grid item xs={12} sm={6}>
                        <SearchList
                            deleteEvent={deleteEventById}
                            listDimensions={searchListDimensions}
                            muotoiltu={false}
                            filterTime={false}
                            data={lastEditedUserEvents}
                            primaryPropKey='nimi'
                            secondaryPropKey='aloitus'
                            title='Viimeksi muokkaamasi'
                            color='taphaBrown.light'
                        />
                    </Grid></> : <></>}
                {role !== 'Laskuttaja' ? <>
                    <Grid item xs={12} sm={6}>
                        <SearchList
                            deleteEvent={deleteEventById}
                            listDimensions={searchListDimensions}
                            muotoiltu={false}
                            filterTime={true}
                            data={publishedUserEvents}
                            primaryPropKey='nimi'
                            secondaryPropKey='aloitus'
                            title='Julkaisemasi tapahtumat'
                            color='taphaWhite.main'
                        />
                    </Grid></> : <></>}

                {role !== 'Laskuttaja' ? <>
                    <Grid item xs={12} sm={6}>
                        <SearchList
                            deleteEvent={deleteEventById}
                            listDimensions={searchListDimensions}
                            muotoiltu={false}
                            filterTime={true}
                            data={nonPublishedUserEvents}
                            primaryPropKey='nimi'
                            secondaryPropKey='aloitus'
                            title='Luonnoksesi'
                            color='taphaBlue.light'
                        />
                    </Grid></> : <></>}
                {role === 'Laskuttaja' ?
                    <>
                        <Grid item xs={12} sm={6}>
                            <SearchList
                                showPast={true}
                                deleteEvent={deleteEventById}
                                listDimensions={searchListDimensions}
                                muotoiltu={false}
                                filterTime={true}
                                data={laskutettavat}
                                primaryPropKey='nimi'
                                secondaryPropKey='aloitus'
                                title='Laskutettavat'
                                color='taphaBlue.light'
                            />
                        </Grid>
                    </> : <></>}
                {role === 'Laskuttaja' ?
                    <>
                        <Grid item xs={12} sm={6}>
                            <SearchList
                                showPast={true}
                                deleteEvent={deleteEventById}
                                listDimensions={searchListDimensions}
                                muotoiltu={false}
                                filterTime={true}
                                data={laskutetut}
                                primaryPropKey='nimi'
                                secondaryPropKey='aloitus'
                                title='Laskutetut'
                                color='taphaBlue.light'
                            />
                        </Grid>
                    </> : <></>}
            </Grid>
        </Container>
    );
};

export default Home;