/*
    API slice used to fetch target people (henkilot) from the backend.
    it's not inside any feature's directory, as it will likely be used in at
    least two different features (AdminPanel, Events).
*/

import { createSelector, createEntityAdapter } from '@reduxjs/toolkit';

import { apiSlice } from './apiSlice';

const peopleAdapter = createEntityAdapter({
    selectId: (person) => person.id
});
const initialState = peopleAdapter.getInitialState();

export const peopleApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPeople: builder.query({
            query: () => ({
                url: '/henkilot',
                validateStatus: (response, result) =>
                    response.status === 200 && !result.isError
            }),
            transformResponse: responseData =>
                peopleAdapter.setAll(initialState, responseData),
            providesTags: ['People']
        }),
        addNewPerson: builder.mutation({
            query: newPerson => {

                const formData = new FormData();
                formData.append('nimi', newPerson.nimi);
                formData.append('email', newPerson.email);
                formData.append('puhelinnumero', newPerson.puhelinnumero);
                formData.append('titteli', newPerson.titteli);
                formData.append('puhelinnumero2', newPerson.puhelinnumero2);
                formData.append('tyonkuva', newPerson.tyonkuva);
                formData.append('yhteystiedot', newPerson.yhteystiedot);
                formData.append('henkilokuva', newPerson.henkilokuva?.file);
                return ({

                    url: '/henkilot',
                    method: 'POST',
                    body: formData

                });
            },
            invalidatesTags: ['People'],
        }),
        deletePerson: builder.mutation({
            query: ({ id }) => ({
                url: '/henkilot',
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: ['People']
        })
    })
});

export const {
    useGetPeopleQuery,
    useAddNewPersonMutation,
    useDeletePersonMutation
} = peopleApiSlice;

export const selectPeopleResult = peopleApiSlice.endpoints.getPeople.select('PeopleList');

const selectPeopleData = createSelector(
    selectPeopleResult,
    peopleResult => peopleResult.data
);

export const {
    selectIds: selectPeopleIds,
    selectEntities: selectPeopleEntities
} = peopleAdapter.getSelectors(state => selectPeopleData(state) ?? initialState);

export const selectAllPeople = createSelector(
    selectPeopleIds,
    selectPeopleEntities,
    (ids, people) => ids?.map(id => people[id])
);

export const selectAllPeopleNames = createSelector(
    selectPeopleIds,
    selectPeopleEntities,
    (ids, people) => ids?.map(id => people[id].nimi)
);

export const selectAllPeopleNamesAndTitles = createSelector(
    selectPeopleIds,
    selectPeopleEntities,
    (ids, people) => ids?.map(id => ({
        nimi: people[id].nimi,
        titteli: people[id].titteli
    }))
);
