import React, { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Button, Grid, Alert, Typography } from '@mui/material';

const HenkiloImageUploader = ({ fieldName, onImageChange, image }) => {
    const { setValue } = useFormContext();
    const validFileTypes = ['jpg', 'jpeg', 'png'];
    const [currentImage, setCurrentImage] = useState(null);


    useEffect(() => {
        setCurrentImage(image);
    }, [image]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && validFileTypes.includes(file.name.split('.').pop().toLowerCase())) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const newImage = {
                    file,
                    savedToServer: false,
                    base64Data: e.target.result, // Save the base64-encoded image data
                };
                setValue(fieldName, newImage);
                setCurrentImage(newImage);
                onImageChange(newImage);
            };
            reader.readAsDataURL(file);
        }
        event.target.value = null;
    };

    const handleRemove = () => {
        setValue(fieldName, null);
        onImageChange(null);
        setCurrentImage(null);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} justifyContent="space-between" display="flex">
                {currentImage && currentImage.base64Data ? (
                    <img src={currentImage.base64Data} alt={currentImage.originalFileName} style={{ width: '100px' }} />
                ) : currentImage && currentImage.src ? (
                    <img src={currentImage.src} alt={currentImage.originalFileName} style={{ width: '100px' }} />
                ) : (
                    <Alert severity="info" sx={{ borderRadius: 3 }}>
                    Henkilöllä ei vielä ole kuvaa
                    </Alert>
                )}
                <Button
                    component="label"
                    variant="contained"
                    size="small"
                    sx={{ backgroundColor: 'taphaBlue.main' }}
                    startIcon={<UploadFileIcon sx={{ color: 'white' }} />}
                >
                    {/* Keep the input hidden, so we only have the upload button visible. */}
                    <input
                        type="file"
                        hidden
                        accept=".png, .jpg, .jpeg"
                        name={fieldName}
                        onChange={handleFileChange}
                    />
                    Lisää kuva
                </Button>
                {currentImage && (currentImage.base64Data || currentImage.src) && (
                    <Button variant="contained" size="small" onClick={handleRemove}>
                        Poista kuva
                    </Button>
                )}
            </Grid>
        </Grid>
    );
};

export default HenkiloImageUploader;



