import React from 'react';
import TextField from '@mui/material/TextField';

const SearchBar = ({ searchTerm, onSearchChange }) => {
    return (
        <TextField
            label="Etsi"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={(event) => onSearchChange(event.target.value)}
        />
    );
};

export default SearchBar;





