import { apiSlice } from './apiSlice';

export const reportsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getReport: builder.query({
            query: ({ id }) => ({
                url: `/raportit/${id}`,
                validateStatus: (response, result) =>
                    response.status === 200 && !result.isError
            }),
            keepUnusedDataFor: 0 // Dont cache any reports.
        }),
        addNewReport: builder.mutation({
            query: newReport => ({
                url: '/raportit',
                method: 'POST',
                body: newReport
            })
        }),
        getAllReports: builder.query({
            query: () => ({
                url: '/raportit',
            })
        }),
        updateReport: builder.mutation({
            query: updatedReport => ({
                url: '/raportit',
                method: 'PUT',
                body: updatedReport
            })
        })
    })
});

export const {
    useGetReportQuery,
    useAddNewReportMutation,
    useUpdateReportMutation,
    useGetAllReportsQuery
} = reportsApiSlice;