import '@fontsource/bree-serif';
import '@fontsource/open-sans';
import { alpha, createTheme } from '@mui/material/styles';

//////////////////////////////////////////////
// Theme Colors: Override the default MUI
// palette to add our theme colors.
//////////////////////////////////////////////

const theme = createTheme({
    palette: {
        taphaBlue: {
            light: '#edf1f7',
            main: '#225C8F',
            dark: '#163B5C'
        },
        taphaGreen: {
            light: '#97ED62',
            main: '#5B701F'
        },
        taphaWhite: {
            main: '#F1F7ED'
        },
        taphaGray: {
            main: '#EDF1F7'
        },
        taphaErrorRed: {
            main: '#FDEDED',
            dark: '#FFC8C8'
        },
        taphaBrown: {
            light: '#F6F7ED'
        },
        taphaPurple: {
            main: '#701F5B',
            dark: '#591848'
        }
    },
    typography: {
        h3: {
            fontFamily: '"Open Sans", sans-serif',
            fontWeight: 'bold'
        },
        h4: {
            fontFamily: '"Bree Serif", serif',
        }
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    ...(ownerState.variant === 'standard' && {
                        '&&& .MuiInput-underline': {
                            '&:before': { // Before hover or focus
                                borderBottomColor: theme.palette.taphaBlue.main
                            },
                            '&:hover::before': { // On hover
                                borderBottomColor: theme.palette.taphaBlue.main
                            },
                            '&:after': { // On focus
                                borderBottomColor: theme.palette.taphaBlue.dark
                            },
                        },
                        '& .Mui-focused': { // On focus, also recolor the input adornment.
                            '& .MuiInputAdornment-root': {
                                color: theme.palette.taphaBlue.dark
                            }
                        }
                    })
                })
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '&.MuiListItem-root': {
                        '&.MuiListItem-button': {
                            '&.Mui-selected': {
                                backgroundColor: 'transparent',
                                border: '1px solid white',
                            },
                            '&:hover': {
                                backgroundColor: alpha(theme.palette.taphaWhite.main, 0.5)
                            }
                        }
                    }
                })
            },
        },
    }
});

// Export our theme for the ThemeProvider in src/index.js,
// so they can be used throughout our App.

export default theme;