import { useWatch } from 'react-hook-form';
import {
    Card,
    CardContent,
    CardHeader,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';

const ScheduleCard = ({ fieldName, hasErrors }) => {

    const schedule = useWatch({ name: fieldName });

    const cardStyle = {
        borderColor: !hasErrors ? 'taphaWhite.main' : 'taphaErrorRed.dark',
        backgroundColor: !hasErrors ? 'taphaWhite.main' : 'taphaErrorRed.main',
        borderRadius: 2
    };

    // | 'body1'
    // | 'body2'
    // | 'button'
    // | 'caption'
    // | 'h1'
    // | 'h2'
    // | 'h3'
    // | 'h4'
    // | 'h5'
    // | 'h6'
    // | 'inherit'
    // | 'overline'
    // | 'subtitle1'
    // | 'subtitle2'
    // | string

    return (
        <Card sx={cardStyle} elevation={0}>
            <CardContent gap={3}>
                {schedule.kuvaus ? (
                    <Typography variant='body2' dangerouslySetInnerHTML={{ __html: schedule.kuvaus }} />
                ) : (
                    <Typography variant='body2'>Tilaisuudella ei vielä ole kuvausta</Typography>
                )}
            </CardContent>
        </Card>
    );
};

ScheduleCard.propTypes = {
    fieldName: PropTypes.string
};

export default ScheduleCard;