import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../authentication/authSlice';
import { useDeleteEventMutation } from '../../../../api/eventsApiSlice';
import handleDelete from '../../../../pages/AdminPage/adminUtils/handleDelete';
import { selectCurrentAccessToken } from '../../../authentication/authSlice';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemText,
    IconButton, Alert, Button, Grid, Box, useMediaQuery
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { eventTypesApiSlice } from '../../../../api/eventTypesApiSlice';
import { useAddNewLokiMutation } from '../../../../api/lokiSlice';


const EventListItem = ({ event, events, deleteEvent }) => {

    // Maybe disable all images here. If theres 30 events for a month, it's
    // probably a bad idea to get all the images.

    const navigate = useNavigate();

    const handleCopy = () => {

        const copiedEvent = { ...event, raportti: null, status: 'luonnos' };


        sessionStorage.setItem('copiedSessionValues', JSON.stringify(copiedEvent));
        navigate('/tapahtumat/uusi');


    };

    const screenIsBiggerThan600px = useMediaQuery('(min-width: 600px)');



    const bannerImage = event?.kuvat?.find(img => img.bannerImage === true);
    const user = useSelector(selectCurrentUser);
    const name = user.name;
    const [ sendLog ] = useAddNewLokiMutation();

    const accessToken = useSelector(selectCurrentAccessToken);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [deleteConfirmed, setDeleteConfirmed] = useState(false);

    const handleSuccess = async(message) => {
        setSuccessMessage(message);
        /*await sendLog({
            name: name,
            action: 'poisti',
            document: {
                type: 'tapahtumat',
                documentId: event.id
            }
        });*/
    };

    const handleError = (message) => {
        setErrorMessage(message);
    };

    const handleDeleteConfirmation = (confirm) => {
        setShowConfirmation(confirm);

    };

    const handleDeleteItem = () => {
        setDeleteConfirmed(true);



    };

    return (
        <>
            {screenIsBiggerThan600px ? <Link
                to={`/tapahtumat/${event.id}`}
                style={{
                    textDecoration: 'none',
                    color: 'black'
                }}>
                <ListItem
                    divider
                    key={event.id}
                    sx={{
                        backgroundColor: 'taphaWhite.main',
                        borderRadius: 5,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginTop: 1,
                        gap: 5,
                        '&:hover': {
                            border: '1px solid',
                            borderLeft: '7px solid',
                            transition: '0.4s',
                            borderColor: 'taphaGreen.main',
                            cursor: 'pointer'
                        }
                    }}>
                    <ListItemAvatar sx={{ flex: 1 }}>
                        <Avatar
                            src={bannerImage?.src || '/SROS-logo-vihrea.jpg'}
                            sx={{ width: 70, height: 70 }} />
                    </ListItemAvatar>
                    <ListItemText
                        sx={{ flex: 1 }}
                        primary={event.date.day}
                        secondary={event.date.monthName}
                        primaryTypographyProps={{ variant: 'h6' }}
                        secondaryTypographyProps={{ variant: 'overline', fontSize: '0.8rem' }}
                    />
                    <ListItemText
                        sx={{ flex: 7 }}
                        primary={
                            <React.Fragment>
                                <span style={{ display: 'block', marginBottom: '5px' }}>{event.nimi}</span>
                                <span style={{ fontSize: '0.8rem', color: 'rgba(0, 0, 0, 0.5)' }}>
                                    {(event?.paikka?.nimi || '').trim()}, {event?.paikka?.kunta}
                                </span>
                            </React.Fragment>
                        }
                        primaryTypographyProps={{ variant: 'h5', fontSize: '1rem', letterSpacing: '1px' }}
                    />
                    <div style={{ flex: 1 }}>
                        {user.role === 'Admin' || user.role === 'Suunnittelija' || user.role === 'Muokkaaja' ? <IconButton  onClick={(e) => { e.preventDefault();
                            handleCopy(); }  }>
                            <ContentCopyIcon/>
                        </IconButton> : (<></>)}
                        {(user.role === 'Admin' || user.role === 'Muokkaaja' || (user.role === 'Suunnittelija' && user.userId === event.user)) && <IconButton
                            onClick={(e) => {
                                e.preventDefault();
                                handleDeleteItem();
                                handleDeleteConfirmation(true);
                            }}
                            size="small" // Adjust the size as needed

                        >
                            <DeleteIcon />
                        </IconButton>}
                    </div>


                </ListItem>

            </Link> :  <Link to={`/tapahtumat/${event.id}`} style={{ textDecoration: 'none', color: 'black' }}>
                <ListItem
                    divider
                    key={event.id}
                    sx={{
                        backgroundColor: 'taphaWhite.main',
                        borderRadius: 5,
                        textTransform: 'capitalize',
                        display: 'flex',
                        flexDirection: 'column', // Stack elements vertically
                        gap: 3,
                        '&:hover': {
                            border: '1px solid',
                            borderLeft: '7px solid',
                            transition: '0.4s',
                            borderColor: 'taphaGreen.main',
                            cursor: 'pointer',
                        },
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                        <ListItemAvatar>
                            <Avatar src={event?.kuvat?.[0]?.src} sx={{ width: 70, height: 70 }} />
                        </ListItemAvatar>
                        <Box sx={{ flex: 1 }}>
                            <ListItemText
                                primary={event.date.day}
                                secondary={event.date.monthName}
                                primaryTypographyProps={{ variant: 'h6' }}
                                secondaryTypographyProps={{ variant: 'overline', fontSize: '0.8rem' }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ marginTop: '1rem' }}>
                        <ListItemText
                            primary={event.nimi}
                            primaryTypographyProps={{ variant: 'h5', fontSize: '1rem', letterSpacing: '1px' }}
                        />
                        {event?.paikka?.nimi && (
                            <ListItemText
                                primary={event?.paikka?.nimi}
                                primaryTypographyProps={{ variant: 'body2', fontSize: '0.8rem', color: 'rgba(0, 0, 0, 0.5)' }}
                            />
                        )}
                        <Box sx={{ marginTop: '1rem' }}>
                            {(user.role === 'Admin' || user.role === 'Suunnittelija' || user.role === 'Muokkaaja') && (
                                <IconButton onClick={handleCopy}>
                                    <ContentCopyIcon />
                                </IconButton>
                            )}
                            {(user.role === 'Admin' || (user.role === 'Suunnittelija' || user.role === 'Muokkaaja' && user.userId === event.user)) && (
                                <IconButton onClick={() => {
                                    handleDeleteItem();
                                    handleDeleteConfirmation(true);
                                }}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </Box>
                    </Box>
                </ListItem>
            </Link>}

            <Grid item xs={12} display='flex' justifyContent='center'>
                {successMessage && (
                    <Box my={2}>
                        <Alert severity="success" onClose={() => setSuccessMessage('')}>
                            {successMessage}
                        </Alert>
                    </Box>
                )}

                {errorMessage && (
                    <Box my={2}>
                        <Alert severity="error" onClose={() => setErrorMessage('')}>
                            {errorMessage}
                        </Alert>
                    </Box>
                )}
                {showConfirmation && (
                    <Box my={2}>
                        <Alert severity="warning">
      Oletko varma että haluat poistaa tapahtuman?
                            <Button color="inherit"  onClick={async(e) => {
                                e.preventDefault();
                                await deleteEvent(event);
                                handleSuccess('Tapahtuma poistettu onnistuneesti!');
                                setShowConfirmation(false);
                            }}>
        Kyllä
                            </Button>
                            <Button color="inherit" onClick={() => setShowConfirmation(false)}>
        Ei
                            </Button>
                        </Alert>
                    </Box>
                )}
            </Grid>
        </>
    );
};

EventListItem.propTypes = {
    event: PropTypes.object
};

export default EventListItem;