import { useFormContext, useWatch } from 'react-hook-form';
import AddressCard from './AddressCard';
import AutocompleteDropdown from './AutocompleteDropdown';
import DescriptionField from './DescriptionField';
import RegionalSelection from './RegionalSelection';
import { Button, Box, Typography } from '@mui/material';
import AddressFields from './AddressFields';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Places = ({ fieldName, centers, places }) => {

    // The errors are only used to color the inputs red, incase the validation
    // throws an error.
    const {
        clearErrors,
        formState: { errors },
        setValue,
    } = useFormContext();

    const formValues = useWatch({
        name: fieldName,
        defaultValue: null
    });


    let sortedPlaces;
    if (places && typeof places[Symbol.iterator] === 'function') {
        sortedPlaces = [...places].sort((a, b) => {
            const nameA = a.nimi.trim();
            const nameB = b.nimi.trim();

            // Check if either location is one of the special ones
            const isSpecialA = ['Vuokatinranta', 'Suomen Raamattuopisto', 'Kellonkartano'].includes(nameA);
            const isSpecialB = ['Vuokatinranta', 'Suomen Raamattuopisto', 'Kellonkartano'].includes(nameB);

            // If both are special or both are not special, sort alphabetically
            if ((isSpecialA && isSpecialB) || (!isSpecialA && !isSpecialB)) {
                return nameA.localeCompare(nameB);
            } else {
                // Place special locations above the non-special ones
                return isSpecialA ? -1 : 1;
            }
        });
        // Further processing with sortedPlaces...
    }


    const [showDropdowns, setShowDropdowns] = useState(false);

    const selectedCenter = formValues && centers?.find(center =>
        center?.nimi === formValues?.keskus
    );
    const selectedPlaceName = formValues && places?.find(place =>
        place?.nimi === formValues?.nimi
    );

    const handleCenterChange = (newCenter) => {
        clearErrors(fieldName);
        if (newCenter && newCenter.paikka) {
            // If 'paikka' property exists on newCenter
            setValue(fieldName, {
                keskus: newCenter.nimi,
                ...newCenter.paikka
            });
        } else {
            // If 'paikka' property does not exist on newCenter
            setValue(fieldName, {
                keskus: newCenter?.nimi,
                nimi: '',
                osoite: '',
                maakunta: '',
                kunta: '',
                postitoimipaikka: '',
                postinumero: '',
            });
        }
    };
    const handlePlaceNameChange = (newPlace) => {
        clearErrors(fieldName);
        setValue(fieldName, ({
            ...newPlace?.paikka,
            nimi: newPlace?.nimi || '',
            keskus: newPlace?.keskus || '',
            osoite: newPlace?.osoite || '',
            postitoimipaikka: newPlace?.postitoimipaikka || '',
            postinumero: newPlace?.postinumero || '',
            kunta: newPlace?.kunta || '',
            maakunta: newPlace?.maakunta || '',
            id: newPlace?.id
        }));
    };

    const toggleDropdowns = () => {
        setShowDropdowns(prev => !prev);
    };
    let additionalFields;

    const centerHasNoAddressDetails = selectedCenter && !selectedCenter?.sisaltaa_osoitetiedot;

    if (centerHasNoAddressDetails)
        additionalFields = selectedCenter?.vain_kuvaus
            ? (
                <DescriptionField
                    centerName={selectedCenter?.nimi}
                    fieldName={fieldName}
                    descriptionField='kuvaus'
                />
            )
            : <RegionalSelection showDropdowns={showDropdowns} centerName={selectedCenter?.nimi} fieldName={fieldName} />;

    return (
        <>
            <div style={{ marginBottom: '20px' }}>
                <AutocompleteDropdown
                    options={sortedPlaces}
                    value={selectedPlaceName}
                    onChange={handlePlaceNameChange}
                    label='Hae paikkaa'
                    noOptionsText='Paikkoja ei löytynyt'
                />
            </div>

            <AutocompleteDropdown
                // No error displays on this dropdown, as it might be confusing.
                options={centers}
                value={selectedCenter}
                onChange={handleCenterChange}
                label='Keskus'
                noOptionsText='Keskuksia ei löytynyt'
            />
            {showDropdowns && <AddressFields fieldName={fieldName} centers={centers} selectedCenter={selectedCenter} handleCenterChange={handleCenterChange}/>}
            {additionalFields}
            <Box display='flex' justifyContent='space-between' pt={3}>
                <Typography variant='caption' mr={2}>
                    Voit lisätä uuden paikan, jos et löydä etsimääsi listanäkymästä
                </Typography>
                <Button
                    size='small'
                    onClick={() => toggleDropdowns()}
                    variant='contained'
                    startIcon={ showDropdowns ? <ArrowBackIcon /> : <AddIcon />}
                    sx={{ backgroundColor: 'taphaBlue.main', whiteSpace: 'nowrap', px: 3 }}
                >
                    { showDropdowns ? 'Palaa tallentamatta' : 'Lisää uusi paikka' }
                </Button>
            </Box>
            {
                !(selectedCenter?.vain_kuvaus || centerHasNoAddressDetails) &&
                <AddressCard place={formValues} fieldName={fieldName} errorState={!!errors[fieldName]} />
            }
        </>
    );
};

export default Places;