import { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
    Button,
    Grid,
    List,
    Alert,
    RadioGroup,
    Typography,
} from '@mui/material';

import ImageListItem from './ImageListItem';

const ImageUploader = ({ fieldName }) => {

    const { setValue } = useFormContext();

    const validFileTypes = ['jpg', 'jpeg', 'png'];

    const images = useWatch({
        name: fieldName,
        defaultValue: []
    });

    // Basically only used to update the RadioGroup
    const [bannerIndex, setBannerIndex] = useState(-1);

    const updateFilesWithBanner = (newBannerIndex) => {
        const updatedFiles = images.map((img, i) => ({
            ...img,
            bannerImage: newBannerIndex === i
        }));
        setValue(fieldName, updatedFiles);
    };

    // Run whenever the number of files changes. Using images.length
    // should be fine here.

    useEffect(() => {
        const oldBannerIndex = images.findIndex(img => img.bannerImage === true); // Returns -1 if not found.
        const newBannerIndex = oldBannerIndex < 0 ? 0 : oldBannerIndex; // If theres no banner image, default to the first image.

        setBannerIndex(newBannerIndex);
        updateFilesWithBanner(newBannerIndex);
    }, [images.length, setValue]);


    const handleFileChange = (event) => {

        const newFiles = Array.from(event.target.files);
        // Make sure that we only accept jpegs/pngs on the input.

        const validFiles = newFiles
            .filter(file => validFileTypes.includes(file.name.split('.').pop().toLowerCase()))
            .map(file => ({
                file,
                savedToServer: false
            }));

        const fileList = images.concat(validFiles);
        setValue(fieldName, fileList);
        event.target.value = null;
    };

    const handleBannerChange = (event) => {
        const newBannerIndex = parseInt(event.target.value);
        setBannerIndex(newBannerIndex);
        updateFilesWithBanner(newBannerIndex);
    };

    // Always triggers the useEffect.
    const handleRemove = (indexToRemove) => {
        const updatedFiles = images.filter((file, index) => index !== indexToRemove);
        setValue(fieldName, updatedFiles);
    };

    let content;

    if (!images?.length)
        content = (
            <Alert severity='info' sx={{ borderRadius: 3 }}>
                Tapahtumalla ei vielä ole kuvia
            </Alert>
        );
    else
        content = images?.map((image, i) => (
            <ImageListItem
                key={`${image?.file?.name ?? image.originalFileName}-${i}`}
                image={image}
                value={i}
                isBanner={i === bannerIndex}
                handleChange={handleBannerChange}
                handleRemove={() => handleRemove(i)} // Index removal is safe here.
            />
        ));

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <RadioGroup value={bannerIndex} onChange={handleBannerChange}>
                    <List disablePadding>
                        {content}
                    </List>
                </RadioGroup>
            </Grid>
            <Grid item xs={12} justifyContent='space-between' display='flex'>
                <Typography align='center' variant='caption' sx={{ pr: 3, display: 'flex', alignItems: 'flex-end' }}>
                    Kuvien tulee olla jpg- tai png-tiedostoja.
                </Typography>
                <Button
                    component='label'
                    variant='contained'
                    size='small'
                    sx={{ backgroundColor: 'taphaBlue.main' }}
                    startIcon={ <UploadFileIcon sx={{ color: 'white' }} /> }>

                    {/* Keep the input hidden, so we only have the upload button visible. */}
                    <input
                        type='file'
                        multiple
                        hidden
                        accept='.png, .jpg, .jpeg'
                        name={fieldName}
                        onChange={handleFileChange}
                    />
                    Lisää kuva
                </Button>
            </Grid>
        </Grid>
    );
};

export default ImageUploader;
