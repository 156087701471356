import React, { useState } from 'react';
import { ListItem, Grid, Typography, Button, Snackbar } from '@mui/material';
import dayjs from 'dayjs';

const MuotoiltuListItem = ({ data, style, handleOpenSnackbar }) => {
    const { aloitus, lopetus, nimi, paikka, mukanaolevat } = data;

    const aloitusDate = dayjs(aloitus, 'DD.MM.YYYY HH:mm');
    const lopetusDate = dayjs(lopetus, 'DD.MM.YYYY HH:mm');

    let formattedDate, formattedTime, formattedEndTime, eventDuration;

    // Check if the parsed date is valid
    if (aloitusDate.isValid() && lopetusDate.isValid()) {
        // Date is valid, proceed with formatting
        formattedDate = aloitusDate.format('DD.MM.');
        formattedTime = aloitusDate.format('HH:mm');

        // Check if the start and end dates are on the same day
        const sameDay = aloitusDate.isSame(lopetusDate, 'day');

        // Format end time based on whether it's the same day or different days
        if (sameDay) {
            formattedEndTime = '';
        } else {
            formattedEndTime = lopetusDate.format('DD.MM.');
        }
    } else {
        // Log an error if the date is invalid
        console.error('Invalid date format');
    }

    const mukanaolevatList = mukanaolevat?.map((henkilo) => henkilo?.henkilo);


    const handleCopyToClipboard = () => {
        handleOpenSnackbar();
        const textToCopy = `${formattedDate}${formattedEndTime !== '' ? '-' : ''}${formattedEndTime}\nklo ${formattedTime}\n${paikka.kunta},\n${paikka.nimi}\n${nimi}\n${mukanaolevatList.join(', ')}`;

        // Copy text to clipboard
        navigator.clipboard.writeText(textToCopy);
    };


    return (
        <>
            <ListItem
                sx={{
                    ...style,
                    backgroundColor: 'background.paper',
                    borderRadius: 3,
                    '&:hover': {
                        cursor: 'pointer',
                    },
                    whiteSpace: 'nowrap', // Prevent line breaks
                    minHeight: '150px', // Increase the height of list items
                    minWidth: '350px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between' // Align content vertically
                }}
                onClick={handleCopyToClipboard}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="body1">
                            {formattedDate}
                            {formattedEndTime !== formattedDate && formattedEndTime !== '' ? `-${formattedEndTime}` : ''}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">klo {formattedTime}</Typography>
                        <Typography variant="body2" color="textSecondary">{paikka.nimi}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography variant="h6">{nimi}</Typography>
                        <Typography variant="body2">{paikka.kunta}</Typography>
                        {mukanaolevatList && (
                            <Typography variant="body2" color="textSecondary" sx={{ whiteSpace: 'normal' }}>
                                {mukanaolevatList.join(', ')}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </ListItem>
        </>
    );
};

export default MuotoiltuListItem;