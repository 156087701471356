import { useGetAllReportsQuery } from '../api/reportsApiSlice';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { useState, useEffect } from 'react';
import { Typography, Grid, Paper, Box, IconButton, TextField, Modal } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ProgressLoader from '../components/ProgressLoader';
import ReportsModal from '../utils/ReportsModal';

const StatisticsPage = ({ documentTitle }) => {
    useDocumentTitle(documentTitle);
    const { data: reports, isLoading } = useGetAllReportsQuery();
    const itemsPerPage = 10;
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedYhteistyotaho, setSelectedYhteistyotaho] = useState('');

    const yhteistyotahotData = reports?.reduce((acc, item) => {
        const yhteistyotahot = item.yhteistyotahot;
        if (!yhteistyotahot || yhteistyotahot.length === 0) return acc;

        yhteistyotahot.forEach(({ nimi, suhtautuminen }) => {
            if (!nimi) return;

            if (!acc[nimi]) {
                acc[nimi] = {
                    suhtautuminenSum: 0,
                    reportCount: 0,
                };
            }

            if (suhtautuminen !== undefined) {
                acc[nimi].suhtautuminenSum += suhtautuminen;
                acc[nimi].reportCount++;
            }
        });

        return acc;
    }, {});

    const yhteistyotahot = yhteistyotahotData && Object.keys(yhteistyotahotData);

    const filteredYhteistyotahot = searchTerm
        ? yhteistyotahot.filter(yhteistyotaho =>
            yhteistyotaho.toLowerCase().includes(searchTerm.toLowerCase())
        )
        : yhteistyotahot;

    const totalPages = Math.max(1, Math.ceil(filteredYhteistyotahot?.length / itemsPerPage));

    useEffect(() => {
        if (currentPage >= totalPages) {
            setCurrentPage(totalPages - 1);
        }
    }, [searchTerm, totalPages]);

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedYhteistyotahot = filteredYhteistyotahot?.slice(startIndex, endIndex);

    const handleNextPage = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleModalOpen = (yhteistyotaho) => {
        setSelectedYhteistyotaho(yhteistyotaho);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setSelectedYhteistyotaho('');
    };

    if (isLoading) {
        return <ProgressLoader msg="Ladataan tilastoja.." />;
    }

    return (
        <Paper sx={{ p: 2 }}>
            <TextField
                label="Etsi yhteistyötaho"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                variant="outlined"
                margin="normal"
                fullWidth
            />
            <Typography variant="h5" sx={{ fontFamily: 'Arial', fontWeight: 600, marginBottom: '1rem' }}>
                Yhteistyötahojen tilastot:
            </Typography>
            <Grid container spacing={2}>
                {displayedYhteistyotahot?.map((yhteistyotaho) => {
                    const { suhtautuminenSum, reportCount } = yhteistyotahotData[yhteistyotaho];
                    const averageSuhtautuminen = suhtautuminenSum / reportCount;

                    let lightColor;
                    if (averageSuhtautuminen >= 0.5) {
                        lightColor = 'green';
                    } else if (averageSuhtautuminen >= 0) {
                        lightColor = 'orange';
                    } else {
                        lightColor = 'red';
                    }

                    return (
                        <Grid
                            key={yhteistyotaho}
                            item
                            xs={12}
                            md={6}
                            sx={{
                                transition: 'background-color 0.3s ease',
                                '&:hover': {
                                    backgroundColor: '#f5f5f5',
                                },
                            }}
                        >
                            <Box display="flex" alignItems="center">
                                <Typography
                                    sx={{
                                        flexGrow: 1,
                                        fontFamily: 'Arial',
                                        fontWeight: 500,
                                        fontSize: '1.2rem',
                                    }}
                                >
                                    {yhteistyotaho}
                                </Typography>
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50%',
                                        background: lightColor,
                                        marginRight: '10px',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontFamily: 'Arial',
                                        fontSize: '0.9rem',
                                    }}
                                    onClick={() => handleModalOpen(yhteistyotaho)}
                                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                >
                                    ({reportCount} raporttia)
                                </Typography>
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                marginTop={2}
            >
                <Box marginBottom={1}>
                    <Typography variant="body2">
                        Sivu {currentPage + 1}
                    </Typography>
                </Box>
                <Typography variant="body2" color="textSecondary">
                    Sivuja yhteensä: {totalPages}
                </Typography>
                <Box marginTop={1}>
                    <IconButton onClick={handlePreviousPage} disabled={currentPage === 0}>
                        <ArrowBackIcon />
                    </IconButton>
                    <IconButton
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages - 1}
                    >
                        <ArrowForwardIcon />
                    </IconButton>
                </Box>
            </Box>
            <ReportsModal
                open={modalOpen}
                onClose={handleModalClose}
                reports={reports}
                yhteistyotaho={selectedYhteistyotaho}
            />
        </Paper>
    );
};

export default StatisticsPage;
