import { Controller, useFormContext } from 'react-hook-form';
import {
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel
} from '@mui/material';

const FormRadioGroup = ({ fieldName, options, defaultValue }) => {

    const { control } = useFormContext();

    return (
        <FormControl sx={{ width: '100%' }}>
            <Controller
                name={fieldName}
                control={control}
                defaultValue={defaultValue}
                render={({ field }) =>
                    <RadioGroup
                        { ...field }
                        sx={{
                            width: '100%',
                            flexDirection: 'row',
                            justifyContent: 'space-evenly'
                        }}>
                        {options?.map(option =>
                            <FormControlLabel
                                key={option}
                                label={option.charAt(0).toUpperCase() + option.slice(1)}
                                value={option}
                                control={<Radio />}
                            />
                        )}
                    </RadioGroup>
                }
            />
        </FormControl>
    );
};

export default FormRadioGroup;