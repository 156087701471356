import { Children, cloneElement } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect } from 'react';



import { roles } from '../../../config/roles';
import { useGetEventQuery, useEditEventMutation } from '../../../api/eventsApiSlice';
import { selectCurrentUser } from '../../authentication/authSlice';

import ProgressLoader from '../../../components/ProgressLoader';

const WithEvent = ({ restrictToSameUser, disableEditingPast, children }) => {

    const params = useParams();
    const editedEventId = params.id;
    const currentUser = useSelector(selectCurrentUser);

    const { data: event, refetch } = useGetEventQuery({ id: editedEventId });
    const [editEvent] = useEditEventMutation();

    useEffect(() => {
        if (editedEventId) {
            // Event ID is available, fetch the updated event data
            refetch();
        }
    }, [editedEventId, refetch]);

    const handleEditEvent = async (editedEventData) => {
        const { data } = await editEvent(editedEventData);
        const editedEventId = data.id; // Update the edited event ID
        // Redirect to the page showing the event's information

    };


    // Information here if event wasn't found. Will be better with actual
    // loading/error flags when we do pagination in the backend.

    if (!event)
        return <ProgressLoader msg='Ladataan tapahtumaa...' />;

    // Editing and reporting should be restricted to the user who has created the
    // event, admins, global editors and users responsible for billing.

    const userCanEdit = (
        event?.user?.id === currentUser?.userId ||
        [roles.admin, roles.muokkaaja, roles.laskuttaja].includes(currentUser.role)
    );

    if (restrictToSameUser && !userCanEdit)
        return (
            <Alert severity='error'>
                Käyttäjätasosi ei riitä tämän sivun tietojen muokkaamiseen.
            </Alert>
        );

    // If past editing is disabled, restrict editing to only events that are not
    // over yet.

    const eventHasEnded = !dayjs(event.lopetus, 'DD.MM.YYYY HH:mm').isAfter(dayjs());

    if (disableEditingPast && eventHasEnded && currentUser.role !== roles.admin)
        return (
            <Alert severity='error'>
                Tämä tapahtuma on jo mennyt, eikä sen tietoja voi enää muokata.
            </Alert>
        );

    // Allows passing props to children.

    const childrenWithProps = Children.map(children, child => {
        return cloneElement(child, { event, handleEditEvent });
    });

    return <>{childrenWithProps}</>;
};

WithEvent.propTypes = {
    children: PropTypes.element
};

export default WithEvent;