import { ListItem, ListItemText, IconButton, Grid, Alert, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDeleteEventMutation } from '../../api/eventsApiSlice';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../features/authentication/authSlice';
import { useState } from 'react';

// Helper function for bolding the given substring of the string.

const boldSubstring = (string, substring) => {

    // An empty string '' is always a child of any string, so if the string is
    // falsy, e.g. === '', just return the original string as nothing needs to
    // be bolded.

    if (!substring)
        return string;

    // '\\s*' matches any sequence of whitespaces between the characters of the
    // substring. This is necessary, as the comparison in SearchList is done
    // without any whitespaces in the word.

    const regex = new RegExp(`(${substring.split('').join('\\s*')})`, 'gi');
    const textArray = string.split(regex);
    return textArray.map((str, i) => {
        if (regex.test(str)) {
            return <b key={i}>{str}</b>;
        }
        return str;
    });
};

// Helper function for accessing object properties. Helps the component display
// nested properties.

const getPropValue = (obj, propKey) => {
    return !propKey.includes('.')
        ? obj[propKey]
        : propKey.split('.')
            .reduce((val, key) => val?.[key], obj);
};

const SearchListItem = ({ data, style, searchTerm, primaryPropKey, secondaryPropKey, deleteEvent }) => {

    // Add some bottom and top padding to the ListItems.
    // Not doable properly through MUI's Sx in this case, as we use the
    // virtualized list from react-window.
    // Using the style-prop from react-window is required here, as it contains
    // absolute positioning that's required to make the list work.


    const [showConfirmation, setShowConfirmation] = useState(false);
    const [deleteConfirmed, setDeleteConfirmed] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const user = useSelector(selectCurrentUser);

    const navigate = useNavigate();


    const GUTTER_SIZE = 5;

    style = {
        ...style,
        top: style.top + GUTTER_SIZE,
        height: style.height - GUTTER_SIZE
    };

    const primaryValue = getPropValue(data, primaryPropKey);
    const secondaryValueBeforeSubstring = getPropValue(data, secondaryPropKey);
    const secondaryValue = secondaryValueBeforeSubstring?.substring(0, 10);


    const handleCopy = () => {

        const copiedEvent = { ...data, raportti: null, status: 'luonnos' };


        sessionStorage.setItem('copiedSessionValues', JSON.stringify(copiedEvent));
        navigate('/tapahtumat/uusi');


    };

    const handleDeleteConfirmation = (confirm) => {
        setShowConfirmation(confirm);

    };

    const handleDeleteItem = () => {
        setDeleteConfirmed(true);



    };

    const handleSuccess = async(message) => {
        setSuccessMessage(message);
        /*await sendLog({
            name: name,
            action: 'poisti',
            document: {
                type: 'tapahtumat',
                documentId: event.id
            }
        });*/
    };



    return (
        <>
            <Link
                to={`/tapahtumat/${data.id}`}
                style={{
                    textDecoration: 'none',
                    color: 'black'
                }}
            >
                <ListItem
                    sx={{
                        ...style,
                        backgroundColor: 'background.paper',
                        borderRadius: 3,
                        '&:hover': {
                            cursor: 'pointer',
                            border: '1px solid',
                            borderLeft: '10px solid',
                            transition: '0.2s',
                            borderColor: 'taphaGreen.main'
                        },
                        whiteSpace: 'nowrap', // Prevent line breaks
                        minWidth: '350px',
                        display: 'flex', // Use flexbox for layout
                        justifyContent: 'space-between', // Space between items
                        alignItems: 'center', // Align items vertically
                    }}
                >
                    {/* Date on the left */}
                    <ListItemText
                        sx={{
                            py: '4px',
                            fontSize: '0.8rem', // Smaller font size
                            whiteSpace: 'nowrap',
                            textAlign: 'left',
                            marginRight: '8px', // Adjust spacing between date and event name
                        }}
                        primary={secondaryValue} // Use secondaryValue as the date
                    />

                    {/* Event name */}
                    <ListItemText
                        sx={{
                            py: '4px',
                            fontSize: '1rem',
                            whiteSpace: 'nowrap',
                            textAlign: 'left',
                        }}
                        primary={boldSubstring(primaryValue, searchTerm)}
                    />
                    <div>
                        {user.role === 'Admin' || user.role === 'Suunnittelija' || user.role === 'Muokkaaja' ? <IconButton  onClick={(e) => { e.preventDefault();
                            handleCopy(); }  }>
                            <ContentCopyIcon/>
                        </IconButton> : (<></>)}
                        {(user.role === 'Admin' || user.role === 'Muokkaaja' || (user.role === 'Suunnittelija' && user?.userId === data?.user)) && <IconButton
                            onClick={(e) => {
                                e.preventDefault();
                                handleDeleteItem();
                                handleDeleteConfirmation(true);
                            }}
                            size="small" // Adjust the size as needed

                        >
                            <DeleteIcon />
                        </IconButton>}
                    </div>


                </ListItem>
            </Link>
            <Dialog open={showConfirmation} onClose={() => setShowConfirmation(false)}>
                <DialogTitle>Varmista</DialogTitle>
                <DialogContent>
                    <p>Oletko varma että haluat poistaa tapahtuman?</p>
                </DialogContent>
                <DialogActions>
                    <Button color="inherit"  onClick={async(e) => {
                        e.preventDefault();
                        await deleteEvent(data);
                        handleSuccess('Tapahtuma poistettu onnistuneesti!');
                        setShowConfirmation(false);
                    }}>
                        Kyllä
                    </Button>
                    <Button color="inherit" onClick={() => setShowConfirmation(false)}>
                        Ei
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SearchListItem;