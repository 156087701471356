import { useFieldArray } from 'react-hook-form';
import {
    Alert,
    Box,
    Button,
    Grid,
    IconButton,
    List,
    ListItem,
    MenuItem,
    ListItemSecondaryAction
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import FormTextField from '../../../../components/FormFields/FormTextField';

const FormListSelect = ({ fieldName, subfields, emptyMsg, setHours }) => {

    const {
        fields,
        append,
        remove
    } = useFieldArray({ name: fieldName });

    const newElement = () => {
        if (fieldName.endsWith('.kansanopistotunnit')) {
            return { kansanopistotunnit: 0 };
        } else {
            return Object.fromEntries(subfields.map(subfield => [subfield.name, '']));
        }
    };

    const isKansanopistotunnitField = fieldName.endsWith('.kansanopistotunnit');
    const isHenkilosField = fieldName.endsWith('.henkilot');
    const [selectedOption, setSelectedOption] = useState(() => {
        const firstField = fields[0];
        if (firstField && typeof firstField.kansanopistotunnit === 'number') {
            return firstField.kansanopistotunnit;
        }
        return 0;
    });


    const handleOptionClick = (option) => {
        if (typeof option === 'number') {
            if (selectedOption !== null) {
                // Subtract the previously selected option from the total hours
                setHours((prevTotal) => prevTotal - selectedOption);
            }

            // Add the new option to the total hours
            setHours((prevTotal) => prevTotal + option);

            // Update the selected option
            setSelectedOption(option);
        }
    };

    // The FormTextFields inside the grid arent vertically centered, because of
    // The FormTextFields empty helperText prop. The reason it has an empty string
    // is to stop the fields from moving up/down if they have a validation error.

    const listFields = fields.map((field, index) =>
        <ListItem
            key={field.id}
            disableGutters
            sx={{ p: 1, mt: 2.5 , height: '50px' }}>
            <Grid container columnSpacing={1}>
                {subfields.map(({ name, xs, options, ...otherProps }) =>
                    <Grid item key={name} xs={xs}>
                        <FormTextField
                            fieldName={`${fieldName}.[${index}].${name}`}
                            label={name.charAt(0).toUpperCase() + name.slice(1)}
                            fullWidth
                            { ...otherProps }
                            size='small'
                            select
                        >
                            {options.map(option =>

                                <MenuItem key={option ?? 'undefined'} onClick={() => handleOptionClick(option)} value={option}>
                                    {option}
                                </MenuItem>
                            )}
                        </FormTextField>
                    </Grid>
                )}
            </Grid>
            <ListItemSecondaryAction sx={{ top: '25%', mr: -3 }}>
                <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => { remove(index); }}>
                    <ClearIcon sx={{ color: 'taphaBlue.dark' }} />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );

    return (
        <>
            <List sx={{ width: '100%' }} disablePadding>
                {listFields}
                {!listFields.length && <Alert severity='info' sx={{ borderRadius: 3 }}>{emptyMsg}</Alert>}
            </List>
            {isKansanopistotunnitField && fields.length === 0 && (
                <Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={() => append(newElement())}
                        size='small'
                        variant='contained'
                        startIcon={<AddIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                        Lisää
                    </Button>
                </Box>
            )}
            {!isKansanopistotunnitField && (
                <Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={() => append(newElement())}
                        size='small'
                        variant='contained'
                        startIcon={<AddIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                        Lisää
                    </Button>
                </Box>
            )}
        </>
    );
};

FormListSelect.propTypes = {
    fieldName: PropTypes.string,
    options: PropTypes.array,
    emptyMsg: PropTypes.string
};

export default FormListSelect;