import { useState } from 'react';
import { Grid, Typography, Box, IconButton, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormTextField from '../../components/FormFields/FormTextField';
import { useAddNewPartnerMutation  } from '../../api/partnersApiSlice';
import { selectAllRegionNames } from '../../api/regionsApiSlice';
import UpdateButton from './adminUtils/updateButton';
import SearchBar from './adminUtils/SearchBar';
import PaginationInfo from './PaginationInfo';

function YhteistyotahotList({ yhttahot, handleDeleteYhttaho, handleUpdateYhttaho, fetchData, onSuccess, onError }) {
    const [selectedYhttaho, setSelectedYhttaho] = useState(null);
    const [newYhttahoData, setNewYhttahoData] = useState({ tyyppi: '', nimi: '', maakunta: '' });
    const [addYhttahoData, setAddYhttahoData] = useState({ tyyppi: '', nimi: '', maakunta: '' });
    const [showAddForm, setShowAddForm] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [addNewYhttaho, { isLoading }] = useAddNewPartnerMutation();
    const regions = useSelector(selectAllRegionNames);
    const tyypit = ['Seurakunta', 'Muu taho', 'Koulutuskeskus'];




    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
        setCurrentPage(1); // Reset to the first page when searching
    };

    // Apply the search filter
    const filteredYhteistyotahot = yhttahot.filter((Yhttaho) =>
        Yhttaho.nimi.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalPages = Math.ceil(filteredYhteistyotahot.length / itemsPerPage);

    // Get the slice of luonteet for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedYhttahot = filteredYhteistyotahot.slice(startIndex, endIndex);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleUserClick = (Yhttaho) => {


        if(selectedYhttaho?.id === Yhttaho.id) {setSelectedYhttaho(null);
            setNewYhttahoData({ nimi: '', tyyppi: '', maakunta: '' });
            return;}
        setSelectedYhttaho(Yhttaho);
        setNewYhttahoData(Yhttaho);

    };

    const handleAddClick = () => {
        setShowAddForm(!showAddForm);
    };

    const handleLisääYhttaho = async(Yhttaho) => {
        try {
            const result = await addNewYhttaho(Yhttaho);
            if(result.error) onError('Yhteistyötahon luominen epäonnistui');
            if(!result.error) onSuccess('Yhteistyötahon luominen onnistui.', 'yhteistyotahot', result.data.id);
            setAddYhttahoData({ tyyppi: '', nimi: '', maakunta: '' });
            setShowAddForm(false);
            fetchData();
        } catch (error) {
            console.error(error); // handle error
        }};


    return (
        <Grid container spacing={2}>
            <Box mt={3} ml={2}> {/* Add spacing to separate SearchBar */}
                <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
            </Box>
            {displayedYhttahot.map((Yhttaho) => (
                <Grid key={Yhttaho.id} item xs={12} >
                    <Box key={Yhttaho.id} onClick={() => handleUserClick(Yhttaho)} sx={{
                        backgroundColor: 'taphaWhite.main',
                        borderRadius: 5,

                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginTop: 0.6,
                        gap: 5,
                        '&:hover': {
                            border: '1px solid',
                            borderLeft: '30px solid',
                            transition: '0.4s',
                            borderColor: 'taphaGreen.main',
                            cursor: 'pointer'
                        }
                    }}>
                        <Typography variant="h6">
                            {Yhttaho.nimi}
                        </Typography>
                        <IconButton onClick={(event) => {
                            handleDeleteYhttaho(event, Yhttaho);
                            if (selectedYhttaho && selectedYhttaho.id === Yhttaho.id) {
                                setSelectedYhttaho(null);
                                setNewYhttahoData({ tyyppi: '', nimi: '', maakunta: '' });
                            }
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                    {selectedYhttaho && selectedYhttaho.id === Yhttaho.id && (
                        <Grid container>

                            <Grid item xs={8} sx={{ pt: 2 }}>
                                <FormTextField

                                    fieldName='nimi'
                                    label='nimi'
                                    multiline={true}
                                    fullWidth={true}

                                    value={newYhttahoData.nimi}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setNewYhttahoData((prevState) => ({ ...prevState, [name]: value }));
                                    }}

                                />
                            </Grid>
                            <Grid item xs={8} sx={{ pt: 2 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="tyyppi-label">tyyppi</InputLabel>
                                    <Select
                                        labelId="tyyppiId"
                                        id="tyyppi-select"
                                        value={newYhttahoData.tyyppi}
                                        label="tyyppi"
                                        onChange={(event) => {
                                            const { value } = event.target;


                                            setNewYhttahoData((prevState) => ({ ...prevState, 'tyyppi': value }));

                                        }}
                                    >
                                        {tyypit.map((tyyppi) => (
                                            <MenuItem key={tyyppi} value={tyyppi}>
                                                {tyyppi}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={8} sx={{ pt: 2 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="maakunta-label">maakunta</InputLabel>
                                    <Select
                                        labelId="maakuntaId"
                                        id="maakunta-select"
                                        value={newYhttahoData.maakunta}
                                        label="maakunta"
                                        onChange={(event) => {
                                            const { value } = event.target;


                                            setNewYhttahoData((prevState) => ({ ...prevState, 'maakunta': value }));

                                        }}
                                    >
                                        {regions.map((region) => (
                                            <MenuItem key={region} value={region}>
                                                {region}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>



                            <UpdateButton handleUpdate={handleUpdateYhttaho} data={newYhttahoData}/>
                        </Grid>
                    )}
                </Grid>
            ))}
            {showAddForm && (
                <Box sx={{
                    flexGrow: 1, // Add flexGrow to make the box grow
                }}>
                    <Typography variant="h6" sx={{ pt: 2 }}>Yhteistyötahon lisääminen</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <FormTextField
                                key="addYhttahoNimi"
                                fieldName='YhttahoNimi'
                                label='nimi'
                                multiline={true}
                                fullWidth={true}

                                value={addYhttahoData.nimi}
                                onChange={(event) => setAddYhttahoData({ ...addYhttahoData, nimi: event.target.value })}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={8} sx={{ pt: 2 }}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="tyyppiAdd-label">tyyppi</InputLabel>
                                <Select
                                    labelId="tyyppiAddId"
                                    id="tyyppiAdd-select"
                                    value={addYhttahoData.tyyppi}
                                    label="tyyppi"
                                    onChange={(event) => {
                                        const { value } = event.target;


                                        setAddYhttahoData((prevState) => ({ ...prevState, 'tyyppi': value }));

                                    }}
                                >
                                    {tyypit.map((tyyppi) => (
                                        <MenuItem key={tyyppi} value={tyyppi}>
                                            {tyyppi}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={8} sx={{ pt: 2 }}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="maakuntaAdd-label">maakunta</InputLabel>
                                <Select
                                    labelId="maakuntaAddId"
                                    id="maakuntaAdd-select"
                                    value={addYhttahoData.maakunta}
                                    label="maakunta"
                                    onChange={(event) => {
                                        const { value } = event.target;


                                        setAddYhttahoData((prevState) => ({ ...prevState, 'maakunta': value }));

                                    }}
                                >
                                    {regions.map((region) => (
                                        <MenuItem key={region} value={region}>
                                            {region}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Box display="flex" justifyContent="flex-end" width="100%" pt={1}>

                            <Button
                                onClick={() => handleLisääYhttaho(addYhttahoData)}
                                size="small"
                                variant="contained"
                                startIcon={<AddIcon />}
                                sx={{ backgroundColor: 'taphaGreen.main' }}
                            >
          Lisää yhteistyötaho
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            )}
            {showAddForm ? (
                <Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={handleAddClick}
                        size='small'
                        variant='contained'
                        startIcon={<CloseIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Sulje
                    </Button>
                </Box>
            ) :
                (<Box display='flex' justifyContent='flex-end' width='100%' pt={1}>
                    <Button
                        onClick={handleAddClick}
                        size='small'
                        variant='contained'
                        startIcon={<AddIcon />}
                        sx={{ backgroundColor: 'taphaBlue.main' }}
                    >
                    Lisää
                    </Button>
                </Box>)}
            {/* Pagination arrows */}
            <Box mt={2} ml={2} display="flex" flexDirection="column" alignItems="center">
                <PaginationInfo
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    filteredArray={filteredYhteistyotahot}
                    array={yhttahot}
                />
                <Box display="flex" justifyContent="center" width="100%">
                    <Box width="740px" display="flex" justifyContent="center"> {/* Centered box with a specified width */}
                        <IconButton
                            size="small"
                            onClick={handlePreviousPage}
                            disabled={currentPage <= 1}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={handleNextPage}
                            disabled={currentPage >= totalPages}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
}

export default YhteistyotahotList;