import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    AppBar,
    CircularProgress,
    Box,
    Drawer,
    List,
    Toolbar,
    IconButton,
    Tooltip,
    Typography,
    ListItemButton,
    useMediaQuery
} from '@mui/material';

import EventNoteIcon from '@mui/icons-material/EventNote';
import BarChartIcon from '@mui/icons-material/BarChart';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import NavLinkItem from './NavLinkItem';
import { useSendLogoutMutation } from '../features/authentication/authApiSlice';
import { selectCurrentUser } from '../features/authentication/authSlice';
import { useAddNewLokiMutation } from '../api/lokiSlice';
import { roles } from '../config/roles';
import Add from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import BugReportIcon from '@mui/icons-material/BugReport';

const Navbar = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = () => setDrawerOpen((prev) => !prev);

    const { role, name } = useSelector(selectCurrentUser);
    const navigate = useNavigate();


    const isScreenGreaterThan900px = useMediaQuery('(min-width: 900px)');

    const [sendLogout, { isLoading }] = useSendLogoutMutation();
    const [ sendLog ] = useAddNewLokiMutation();

    const handleLogout = async () => {
        await sendLog({
            name: name,
            action: 'kirjautui ulos'
        });
        await sendLogout();
        navigate('/login');
    };


    const navLinks = [
        {
            page: '',
            icon: <HomeIcon/>
        },
        {
            page: 'tapahtumat',
            icon: <EventNoteIcon />,
        },
        {
            page: 'bugiraportointi',
            icon: <BugReportIcon/>
        }
    ];

    if (role !== roles.kayttaja)
        navLinks.push({
            page: 'tilastot',
            icon: <BarChartIcon />,
        });
        // If the user is logged in as an admin, display the link to admin panel.
    if (role !== roles.kayttaja && role !== roles.laskuttaja)
        navLinks.push({
            page: 'tapahtumat/uusi',
            icon: <Add />,
        });
    if (role === roles.admin)
        navLinks.push({
            page: 'admin',
            icon: <SupervisorAccountIcon />,
        });
    return (
        <>
            <AppBar
                position='static'
                color='taphaGreen'
                component='nav'
                sx={{ flexDirection: 'column' }} // Set flex direction to column for mobile alignment
            >
                <Toolbar
                    display='flex'
                    sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center', // Center items vertically
                        px: 2, // Add some padding for spacing
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Link to='/'>
                            <IconButton size='large' edge='start' aria-label='logo'>
                                <Box component='img' maxWidth={200} src='/SROS-logo-valkoinen.png' />
                            </IconButton>
                        </Link>
                    </Box>
                    {isScreenGreaterThan900px ? (
                        <List
                            sx={{
                                display: 'flex',
                                ml: 'auto',

                            }}
                        >
                            {navLinks.map((link, index) => (
                                <NavLinkItem
                                    key={link.page}
                                    to={`/${link.page}`}
                                    text={link.page}
                                    icon={link.icon}
                                />
                            ))}
                        </List>
                    ) : (
                        <IconButton
                            sx={{
                                ml: 'auto',
                            }}
                            edge='end'
                            onClick={toggleDrawer}
                        >
                            <MenuIcon sx={{ color: 'white', height: '2rem', width: '2rem' }} />
                        </IconButton>
                    )}
                    <Box>
                        <IconButton
                            onClick={handleLogout}
                            size='small'
                            sx={{
                                color: 'white',
                                px: 3,
                                textDecoration: 'underline',
                                '&:hover': {
                                    backgroundColor: 'inherit',
                                },
                            }}
                        >
                            {isLoading ? <CircularProgress /> : <LogoutIcon />}
                            {isScreenGreaterThan900px && <Typography fontSize='1rem' variant='caption' sx={{ pl: 1, fontFamily: '"Bree Serif", serif' }}>
                                Kirjaudu ulos
                            </Typography>}
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>

            {/* Drawer menu for screen sizes below 900px width in width*/}
            <Drawer
                open={drawerOpen}
                onClose={toggleDrawer}
                variant='temporary'
                sx={{
                    display: 'block',
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: { xs: '75%', sm: '50%' },
                    },
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    backgroundColor: 'taphaGreen.main',
                }}
                >
                    <Box sx={{ display: 'flex', flex: 1, p: 1, pt: 1 }}>
                        <IconButton
                            color='inherit'
                            aria-label='close drawer'
                            edge='start'
                            onClick={toggleDrawer}
                            sx={{
                                display: { md: 'none' },
                                alignSelf: 'flex-start',
                                ml: 'auto',
                            }}
                        >
                            <CloseIcon sx={{ color: 'white' }} />
                        </IconButton>
                    </Box>
                    <Box sx={{ flex: 5 }}>
                        <List
                            sx={{
                                display: { sm: 'flex', md: 'none' },
                                flexDirection: 'column',
                                ml: 'auto',
                                pr: 6,
                                gap: 3,
                            }}
                        >
                            {navLinks.map((link) => (
                                <NavLinkItem
                                    key={link.page}
                                    to={`/${link.page}`}
                                    text={link.page}
                                    icon={link.icon}
                                />
                            ))}
                        </List>
                    </Box>
                </Box>
            </Drawer>
        </>
    );
};

export default Navbar;
